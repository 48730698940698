.chalkBoard {
  position: fixed;
  left: 40px;
  top: 40px;
  right: 40px;
  bottom: 40px;
  z-index: 99999;
  background: #333;
}
.chalkBoard #react-mathjax-preview {
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
  background: inherit;
  position: relative;
  z-index: 1;
}
.chalkBoard #react-mathjax-preview-result {
  height: 100%;
  color: white;
}
.chalkBoard .tox {
  height: 100% !important;
  width: calc(100% - 82px);
}
.chalkBoard:before {
  content: "";
  background: #333;
  position: absolute;
  left: -40px;
  top: -40px;
  right: -40px;
  bottom: -40px;
  opacity: 0.75;
}
.chalkBoard ~ [class=__users],
.chalkBoard ~ [class=__chatContainer] {
  display: none;
}
.chalkBoard__graphContainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 80px;
  bottom: 0;
  background: #333;
  cursor: none;
  user-select: none;
  border: 2px solid #fff;
}
.chalkBoard .preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.chalkBoard__actions {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #fff;
  border-left: 0;
  flex-direction: column;
}
.chalkBoard__actionsItem {
  text-align: center;
  margin: 10px 0;
}
.chalkBoard__notVisible {
  display: none;
}
.chalkBoard .textTooltip {
  color: #fff;
}
.chalkBoard .textTooltip__trigger {
  position: relative;
  z-index: 2;
  display: block;
}

.student .chalkBoard__graphContainer,
.guest .chalkBoard__graphContainer {
  right: 0;
}
.student .chalkBoard__actions,
.guest .chalkBoard__actions {
  display: none;
}