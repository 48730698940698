.section {
  margin-bottom: $pageIndent;
  &:last-of-type {
    margin-bottom: 0;
  }
  &__title-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $baseColor;
    padding: $pageIndent / 2 $pageIndent;
    min-height: 85px;
    box-sizing: border-box;
    position: fixed;
    left: $navWidth;
    top: $headerHeight;
    right: 0;
    z-index: 10;
    [class$="__title"] {
      margin-bottom: 0;
    }
    .preloader {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: '';
        background: $baseColor;
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: .75;
      }
    }
  }
  &__title {
    text-transform: uppercase;
    margin-bottom: $pageIndent / 2;
    font-family: $RobotoCondensed;
    font-size: 20px;
    font-weight: normal;
    color: $white;
    &-actions {
      white-space: nowrap;
      .btn {
        margin-left: 20px;
      }
    }
    &-separator {
      color: $greyColor;
      &:after {
        content: '/';
        margin: 0 5px;
      }
    }
  }
  &__actions {
    margin-top: $pageIndent;
    display: flex;
    justify-content: center;
  }
  &__data {
    display: flex;
    justify-content: space-between;
    align-self: center;
  }
}