.main--nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 200px;
  background: #fff;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.main--nav_list {
  padding: 20px;
}
.main--nav_logo-item {
  margin-top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 112px;
}
.main--nav_logo {
  max-width: 100%;
}
.main--nav__collapse {
  border-top: 1px solid #f2f2f2;
  margin: 0 -20px;
  padding: 20px;
}
.main--nav__collapse-inner {
  cursor: pointer;
  color: #333;
  transition: color 0.15s ease-in-out;
}
.main--nav__collapse-inner:hover {
  color: #ea5330;
}
.main--nav_item {
  margin: 20px 0;
}
.main--nav_link {
  color: #333;
}
.main--nav_link.active {
  color: #ea5330;
}
.main--nav_icon {
  margin-right: 10px;
  width: 20px;
  text-align: center;
}
.main--nav + .pageNotFound {
  position: static;
  opacity: 1;
  visibility: visible;
}