.alphabetIndex {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin-left: 20px;
  width: 200px;
  user-select: none;
  &__list {
    position: sticky;
    top: calc(#{$studentHeaderHeight} + #{$studentHeaderIndent * 2} + #{$pageIndent / 2});
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }
  &__item {
    margin: 10px 0;
    cursor: pointer;
    font-family: $RobotoCondensed;
    font-size: 14px;
    text-transform: uppercase;
    &:hover {
      color: $purpleColor;
    }
  }
  &__btn {
    display: none;
  }
  @media screen and (max-width: $maxSmallDesktop) {
    width: auto;
    left: auto;
    margin-left: 0;
    right: 0;
    text-align: right;
    z-index: 3;
    &.show {
      [class$="__btn"] {
        background: $purpleColor;
      }
      [class$="__list"] {
        display: block;
        opacity: 1;
      }
    }
    &__list {
      display: none;
      padding: 40px 20px 10px;
      top: 190px;
    }
    &__btn {
      position: sticky;
      top: calc(#{$studentHeaderHeight} + #{$studentHeaderIndent * 2} + #{$pageIndent / 2});
      margin: 20px 20px 0 0;
      background: $baseColor;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      cursor: pointer;
      font-size: 12px;
      z-index: 1;
      float: right;
      &:hover {
        background: $purpleColor;
      }
    }
  }
  @media screen and (max-width: $maxTablet) {
    display: none;
  }
}