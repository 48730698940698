.studentNav {
  position: sticky;
  top: calc(60px + 40px + 40px);
  z-index: 1;
  width: 200px;
  background: #fff;
  padding: 20px 20px 40px;
  box-sizing: border-box;
  left: 0;
}
.studentNav__list {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: 120px;
  margin: 0 auto;
}
.studentNav__item {
  margin-top: 20px;
  white-space: nowrap;
}
.studentNav__item:first-child {
  margin-top: 0;
}
.studentNav__link {
  color: #333;
}
.studentNav__link:hover, .studentNav__link.active {
  color: #7f00a3;
}
.studentNav__icon {
  width: 20px;
  text-align: center;
  margin-right: 10px;
  font-size: 18px;
}
.studentNav__user {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.studentNav__user-avatar {
  width: 120px;
  height: 120px;
  margin: 0 auto 10px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #ccc;
  border-radius: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
}
.studentNav__user-avatar:after {
  content: "";
  position: absolute;
  border-radius: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, 0.25);
}
.studentNav__user-name {
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.studentNav__user-role {
  display: flex;
  justify-content: center;
  color: #999;
  text-transform: uppercase;
  font-size: 14px;
}
.studentNav__user-role * {
  width: 50%;
}
.studentNav__user-class {
  color: #333;
  font-weight: bold;
  display: flex;
}
.studentNav__user-class:before {
  font-weight: normal;
  content: "/";
  margin: 0 5px;
  color: #999;
}
@media screen and (max-width: 1025px) {
  .studentNav {
    position: fixed;
    left: -200px;
    top: calc(40px + 40px);
    bottom: 0;
    transition: all 0.25s ease-in-out;
    z-index: 2;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@media screen and (max-width: 769px) {
  .studentNav {
    top: calc(20px + 40px);
  }
}