.userPicker {
  border: 1px solid transparent;
}
.userPicker.hasErrors {
  border-color: #e32929;
}
.userPicker.hasErrors [class$=__add-btn] {
  color: #e32929;
}
.userPicker__add-btn {
  color: #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: #fafafa;
  padding: 10px;
  transition: background 0.25s ease-in-out;
}
.userPicker__add-btn:hover {
  background: #d7f4fc;
  color: #4ec1e2;
}
.userPicker__placeholder {
  margin-left: 10px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.userPicker__list {
  margin: -5px -20px 20px;
  max-height: 500px;
  overflow: auto;
}
.userPicker__selectedList {
  margin: -5px -10px 0;
  overflow: hidden;
}
.userPicker__selectedList + [class$=__add] {
  margin-top: 20px;
}
.userPicker__list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.userPicker__list-item:nth-child(2n) {
  background: #fafafa;
}
.userPicker__list-item:hover {
  background: #d7f4fc;
}
.userPicker__list-item.selected {
  background: #a1d042;
}
.userPicker__list-item.selectedUserItem {
  background: transparent;
  cursor: initial;
  padding: 10px;
}
.userPicker__list-item.selectedUserItem:nth-child(2n) {
  background: #fafafa;
}
.userPicker__list-item.teacher:not(.multiple) {
  flex-direction: column;
  padding-bottom: 0;
}
.userPicker__list-item.teacher:not(.multiple) [class$=__list-item-avatar] {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, 0.25);
}
.userPicker__list-item.teacher:not(.multiple) [class$=__list-item-avatar] i {
  font-size: 24px;
}
.userPicker__list-item.teacher:not(.multiple) [class$=__list-item-name] {
  font-size: 20px;
  text-align: center;
}
.userPicker__list-item.teacher:not(.multiple) [class$=__list-item-remove] {
  position: absolute;
  right: 0;
  top: 0;
}
.userPicker__list-item.selectAll {
  background: #333;
  color: #fff;
  height: 40px;
}
.userPicker__list-item.selectAll.selected {
  color: #a1d042;
}
.userPicker__list-item.selectAll.selected i {
  color: #a1d042;
}
.userPicker__list-item-avatar {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-size: cover;
  background-position: 50% 50%;
  flex: 0 0 auto;
}
.userPicker__list-item-avatar i {
  font-size: 14px;
  color: #ccc;
}
.userPicker__list-item-name {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  width: 100%;
}
.userPicker__list-item-class {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  white-space: nowrap;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.userPicker__list-item-class i {
  font-size: 12px;
}
.userPicker__list-item-check {
  margin-right: 10px;
}
.userPicker__list-item-check.selected {
  color: #fff;
}
.userPicker__list-btn {
  display: flex;
  justify-content: center;
}
.userPicker__list-btn .btn {
  cursor: pointer;
}
.userPicker__list-btn .btn[disabled] {
  cursor: not-allowed;
}
.userPicker__list-item-remove {
  cursor: pointer;
}
.userPicker__list-item-remove:hover {
  color: #e32929;
}
.userPicker .modal__box {
  max-width: 500px;
}
.userPicker .filters {
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  background: none;
  padding: 0 20px 10px;
  margin: 0 -30px 25px;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
}
.userPicker .filters__item {
  padding: 0 5px;
  box-sizing: border-box;
  flex: 0 0 auto;
}
.userPicker .filters__searchQuery {
  width: 50%;
}
.userPicker .filters__filterBy {
  width: 25%;
}
.userPicker .filters__selectClass {
  width: 25%;
}
.userPicker .filters__heading {
  display: none;
}
.userPicker .filters__inner {
  margin: 0;
}
.userPicker .filters__searchQuery {
  max-width: 100%;
}
.userPicker .filters .form {
  margin: 0;
  min-height: 0;
}
.userPicker .filters .form__row {
  margin: 0;
  padding: 0;
}
.userPicker .filters .form__field-placeholder {
  background: #fff !important;
}
.userPicker .nothingFound:not(:first-child:last-child) {
  padding: 22px 0;
  margin-bottom: -20px;
  overflow: hidden;
}
.userPicker__list > div > .scrollbar__track {
  width: 6px !important;
  border-radius: 0;
  background: transparent;
}
.userPicker__list > div > .scrollbar__track > div {
  background: #333 !important;
  border-radius: 0;
}