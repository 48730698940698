// === Elements
$linkColor: #333;
$linkHoverColor: #ea5330;
$btnPrimary: #4ec1e2;
$anchorColor: #4ec1e2;

// === Statuses
$statusActiveColor: #00c020;
$statusSuspendedColor: #bfbfbf;

// === Misc
$baseColor: #333;
$lightBlueColor: #d7f4fc;
$white: #fff;
$lightColor: #fafafa;
$greyColor: #999;
$lightGreyColor: #f2f2f2;
$pageColor: #eee;
$mediumGreyColor: #ccc;
$greenColor: #a1d042;
$lightGreenColor: #dcf2ae;
$redColor: #f2c2c2;
$lightRedColor: #ffe5e5;
$errorColor: #e32929;
$successColor: #00c020;
$warningColor: #ffbb33;
$warningTextColor: #ffebc4;
$purpleColor: #7f00a3;
$orangeColor: #ff8a00;