@import '../utils/colors';
@import '../utils/fonts';
@import '../utils/sizes';

%-s-message {
  display: flex;
  padding-top: 56.25%;
  justify-content: center;
  align-items: center;
  position: relative;
  background: $white;
  > span {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $mediumGreyColor;
    i {
      font-size: 80px;
      margin-bottom: 40px;
      color: $mediumGreyColor;
    }
  }
}

hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid $white;
  opacity: .25;
}

.chatroom {
  &__title {
    text-align: center;
    font-family: $RobotoCondensed;
    max-width: 800px;
    padding: 0 45px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
  }
  &__title-text {
    text-align: left;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__title-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__connection {
    margin-right: 10px;
    margin-left: -25px;
    position: relative;
    &.good {
      color: $successColor;
    }
    &.medium {
      color: $warningColor;
    }
    &.bad {
      color: $errorColor;
    }
    &.offline {
      color: $mediumGreyColor;
      .offlineAnimation {
        position: absolute;
        left: 0;
        top: 0;
        color: $greyColor;
        width: 0;
        overflow: hidden;
        animation: connectionLost 1s linear infinite;
      }
    }
  }
  &__timer {
    font-size: 14px;
    margin-top: 5px;
    font-weight: 300;
  }
  &__interactions-holder {
    position: relative;
    padding-top: 10px;
    transform: translateY(-5px);
    &:hover {
      [class$="__interactions"] {
        display: block;
      }
    }
  }
  &__interactions {
    position: absolute;
    bottom: 100%;
    background: $white;
    padding: 10px;
    transform: translateX(-50%);
    left: 50%;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.25);
    border-radius: 4px;
    display: none;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -7px;
      background: $white;
      width: 10px;
      height: 10px;
      transform: rotate(45deg) translateX(-50%);
    }
  }
  &__interactions-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 10px;
    font-family: $RobotoCondensed;
    cursor: pointer;
    &:first-child {
      margin-top: 0;
    }
    .btn {
      margin-right: 10px;
      opacity: 1 !important;
      width: 30px;
      height: 30px;
      i {
        font-size: 14px;
      }
    }
    &:hover {
      background: $lightBlueColor;
    }
  }
  &__message-holder {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
  }
  &__box {
    position: relative;
    [class$="_chatContainer"] {
      background: $baseColor;
      border: 1px solid $linkHoverColor;
      box-sizing: border-box;
    }
    &.fixed {
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 999999;
      width: 460px;
      max-width: 100%;
      padding: 20px;
      box-shadow: 0 0 20px 0 rgba(0,0,0,.25);
      background: $baseColor;
      opacity: .75;
      box-sizing: border-box;
      transition: all .15s ease-in-out;
      .btn {
        width: 40px;
        height: 40px;
        font-size: 16px;
      }
      &:hover {
        opacity: 1;
      }
      [class$="__btnsHolder"] {
        height: auto;
        padding-top: 20px;
      }
      [class$="__users"] {
        color: $white;
      }
      [class$="__user-avatar-holder"] {
        background-color: $greyColor;
      }
      &.noOpacity {
        opacity: 1;
      }
      [class$="__title"] {
        color: $white;
        top: -45px;
      }
    }
  }
  &__users {
    display: flex;
    min-height: 150px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    flex-direction: column;
    height: calc(100% - 120px);
    overflow: auto;
    box-sizing: border-box;
    > * {
      display: flex;
      flex-direction: column;
    }
  }
  &__user {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 10px;
    width: 60px;
    text-align: center;
    line-height: 1.25;
    font-size: 12px;
    color: $white;
    opacity: .25;
    &.isPresent {
      opacity: 1;
      color: $successColor;
    }
  }
  &__user-avatar-holder {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $lightGreyColor;
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 10px;
  }
  &__user-avatar {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50% 50%;
  }
  &__user-avatar-placeholder {
    color: $baseColor;
  }
  &__user-name {
    font-family: $RobotoCondensed;
    letter-spacing: .5px;
  }
  &__error {
    color: $errorColor;
    > span {
      color: $errorColor;
      i {
        color: $errorColor;
      }
    }
    @extend %-s-message;
  }
  &__info {
    position: relative;
    @extend %-s-message;
    [class$="__users"] {
      background: $baseColor;
      height: 100%;
    }
  }
  &__shareScreen:not(:empty) {
    background: $white;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    ~ * {
      display: none;
    }
    .video {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100% !important;
      height: 100% !important;
    }
    video {
      object-fit: contain;
      width: 100% !important;
      height: 100% !important;
    }
  }
  &__btnsHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    z-index: 1;
    max-width: 600px;
    margin: 0 auto;
    > * {
      margin: 0 10px;
    }
    .btn {
      cursor: pointer;
      font-size: 20px;
    }
  }
  &__chatHolder {
    margin: 120px auto 0;
    position: relative;
    &.isFullscreen {
      max-width: 100%;
      height: 100%;
      .jitsi-container {
        height: 100%;
        padding-top: 0;
      }
      .textTooltip__text {
        color: $white;
      }
      [class$="__lesson"] {
        padding: 0;
        bottom: 1px;
      }
      [class$="__title"] {
        position: absolute;
        z-index: 1;
        color: $white;
        max-width: 100%;
        top: 0;
        left: 80px;
        padding: 20px 100px 20px 20px;
        background: rgba(248,80,50,1);
        background: linear-gradient(rgba(0,0,0,.75), transparent);
      }
      [class$="__users"] {
        height: 100%;
        z-index: 1;
      }
      [class$="__chatContainer"] {
        padding-top: 0;
        height: 100%;
        iframe {
          position: static;
          height: 100%;
        }
      }
      [class$="__user-avatar-holder"] {
        background-color: $greyColor;
      }
      [class$="__user-avatar-placeholder fa fa-user"] {
        color: $white;
      }
      [class$="__btnsHolder"] {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 120px;
      }
      [class$="__remoteTracks"] {
        left: 80px;
      }
    }
  }
  &__chatContainer {
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    transition: padding .15s ease-in-out;
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
  &__allow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    max-width: 500px;
    margin: 0 auto 10px;
    text-align: center;
    line-height: 1.25;
  }
  &__devicePicker {
    max-width: 200px;
    margin-left: auto;
    height: 0;
    margin-bottom: -20px;
    .form {
      &__field-placeholder {
        background: $lightColor !important;
      }
    }
  }
  &__chatContainer {
    .video {
      position: relative;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
    .isMuted {
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: 1;
      color: $errorColor;
      font-size: 20px;
    }
  }
  &__lesson {
    position: absolute;
    left: 1px;
    top: 1px;
    right: 1px;
    padding-top: 56.25%;
    z-index: 1;
    background: $white;
  }
  &__lesson-inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }
  &__lesson-blocks {
    width: 250px;
    background: $baseColor;
    max-width: 25%;
    overflow: auto;
    height: 100%;
    > * {
      height: 100% !important;
    }
    .scrollbar__content {
      overflow-x: hidden !important;
    }
    .scrollbar__track {
      z-index: 2;
      > * {
        background: $anchorColor !important;
      }
    }
    .article {
      padding: 10px;
      box-sizing: border-box;
    }
    .article__block {
      margin-bottom: 5px;
      font-size: 8px;
      background: $white;
      position: relative;
      padding: 5px;
      cursor: pointer;
      &:hover {
        &:after {
          background: $anchorColor;
          opacity: .25;
        }
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        transition: all .15s ease-in-out;
      }
      * {
        user-select: none;
      }
      p {
        margin-bottom: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__lesson-content {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    > * {
      height: 100% !important;
    }
    .scrollbar__track {
      > * {
        background: $anchorColor !important;
      }
    }
    .scrollbar__content {
      height: 100% !important;
      overflow-x: hidden !important;
    }
    .article {
      max-width: 900px;
      margin: 0 auto;
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &__block {
        width: 100%;
        height: 100%;
        padding-right: 20px;
        box-sizing: border-box;
      }
    }
  }
}
.student,
.guest {
  .closeFullsizeVideo {
    display: none;
  }
  .chatroom {
    &__chatHolder {
      margin-top: 80px;
    }
    &__users {
      display: none !important;
    }
    &__remoteTracks {
      position: absolute;
      left: 0 !important;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: auto;
      flex-direction: row;
      .video:not(.main-video):not(.localVideo) {
        display: none;
      }
      .video.main-video,
      .video.localVideo {
        width: 50%;
        height: 100%;
      }
      &.tracks_qty_1 {
        .video {
          width: 100%;
          height: 100%;
          border-color: $white;
        }
      }
    }
    &__devicePicker {
      .form {
        &__field-placeholder {
          background: $white !important;
        }
      }
    }
    &__title {
      max-width: 960px;
    }
  }
  .isFullscreen {
    [class$="__title"] {
      color: $white;
      text-align: center;
      left: 0;
      right: 0;
    }
  }
}
.admin {
  .chatroom {
    &__chatHolder:not(.isFullscreen) {
      max-width: 800px;
    }
    .preloader {
      margin-top: 85px;
    }
    &__box {
      margin-top: 85px;
    }
    &__message-holder {
      margin-top: 85px;
    }
    &__remoteTracks {
      position: absolute;
      left: 80px;
      top: 0;
      right: 0;
      bottom: 0;
      background: $white;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: auto;
      flex-direction: row;
      .closeFullsizeVideo {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 30px;
        color: $white;
        text-shadow: 0 0 5px rgba(0,0,0,0.5);
        z-index: 2;
        display: none;
      }
      &.hasFullsizeVideo {
        .closeFullsizeVideo {
          display: block;
          cursor: pointer;
          transform: none;
          transition: transform .15s ease-in-out;
          &:hover {
            transform: scale(1.15);
          }
        }
      }
      .video {
        background: $lightGreyColor;
        border: 1px solid $white;
        box-sizing: border-box;
        width: 33.33333%;
        height: 33.33333%;
        &:hover {
          border-color: $anchorColor;
        }
        &.fullsizeVideo {
          position: absolute;
          left: 0 !important;
          top: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          width: 100% !important;
          height: 100% !important;
          padding: 0 !important;
          z-index: 1;
          border-color: $white !important;
        }
      }
      &.tracks_qty_1 {
        .video {
          width: 100%;
          height: 100%;
          border-color: $white;
        }
      }
      &.tracks_qty_2 {
        .video {
          width: 50%;
          height: 100%;
        }
      }
      &.tracks_qty_3,
      &.tracks_qty_4 {
        .video {
          width: 50%;
          height: 50%;
        }
      }
      &.tracks_qty_5,
      &.tracks_qty_6 {
        .video {
          width: 33.33333%;
          height: 50%;
        }
      }
    }
    &__chatHolder.isFullscreen {
      .video {
        width: 25%;
        height: 50%;
      }
      .tracks_qty_1 {
        .video {
          width: 100%;
          height: 100%;
        }
      }
      .tracks_qty_2 {
        .video {
          width: 50%;
          height: 100%;
        }
      }
      .tracks_qty_3,
      .tracks_qty_4 {
        .video {
          width: 50%;
          height: 50%;
        }
      }
      .tracks_qty_5,
      .tracks_qty_6 {
        .video {
          width: 33.33333%;
          height: 50%;
        }
      }
    }
  }
}
.fixed {
  .textTooltip__text {
    color: $white;
  }
  .chatroom {
    &__chatHolder {
      margin-top: 40px;
    }
    &__users {
      display: none;
    }
    &__allow {
      color: $white;
    }
    &__remoteTracks {
      left: 0;
    }
    &__devicePicker {
      display: none;
    }
  }
  .isFullscreen {
    .chatroom {
      &__remoteTracks {
        left: 80px;
      }
      &__users {
        display: flex;
      }
    }
  }
}
.isHidden {
  width: 180px !important;
  padding: 10px !important;
  .chatroom {
    &__btnsHolder {
      padding-top: 10px !important;
      .textTooltip {
        &__text {
          display: none;
        }
      }
      .btn {
        width: 20px;
        height: 20px;
        font-size: 14px;
        background: none;
      }
    }
    &__allow {
      display: none;
    }
    &__remoteTracks {
      .video {
        width: 100%;
        height: 141px;
      }
    }
    &__interactions-item {
      .btn {
        color: $baseColor;
      }
    }
  }
  &.isOrganizer {
    width: 270px !important;
  }
  .btn__working {
    color: $successColor;
    position: static;
    &:before,
    &:after {
      display: none;
    }
    &:hover {
      i {
        color: $successColor;
      }
    }
  }
}
.isFullscreen {
  background: $baseColor;
  margin-top: 0 !important;
}

@keyframes connectionLost {
  0% {
    width: 33.33333%;
  }
  100% {
    width: 100%;
  }

}
