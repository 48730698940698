@import '../../assets/scss/utils/colors';
@import '../../assets/scss/utils/sizes';
@import '../../assets/scss/utils/fonts';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: $navWidth;
  right: 0;
  top: 0;
  padding: 0 $pageIndent;
  height: $headerHeight;
  background: $white;
  z-index: 100;
  &__user {
    font-family: $RobotoCondensed;
    font-size: 20px;
  }
  &__user-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40px;
  }
  &__user-role {
    color: $greyColor;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .5px;
    display: flex;
  }
  &__user-class {
    color: $baseColor;
    font-weight: bold;
    &:before {
      content: '/';
      margin: 0 5px;
      color: $greyColor;
    }
  }
  &__actions {
    display: flex;
  }
  &__actions-item {
    position: relative;
  }
  &__icon {
    font-size: 20px;
    position: relative;
    z-index: 1;
    &:hover {
      cursor: pointer;
      color: $linkHoverColor;
      + .header__icon-descr {
        opacity: 1;
        z-index: 0;
        transform: translate(-50%, 0);
      }
    }
  }
  &__icon-descr {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 10px);
    bottom: 100%;
    text-transform: uppercase;
    font-family: $RobotoCondensed;
    font-size: 12px;
    margin-bottom: 5px;
    opacity: 0;
    z-index: -1;
    transition: all .15s ease-in-out;
    color: $linkHoverColor;
  }
  &__divider {
    width: 1px;
    height: 20px;
    background: $baseColor;
    margin: 0 20px;
    opacity: .25;
  }
  &__user {
    display: flex;
    align-items: center;
  }
  &__user-avatar {
    margin-right: 10px;
    position: relative;
    width: 50px;
    height: 50px;
    background-color: $mediumGreyColor;
    border-radius: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }
  &__user-avatar-icon {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 14px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}