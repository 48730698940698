.content {
  width: calc(100% - #{$studentNavWidth} - #{$pageIndent});
  background: $white;
  padding: 40px;
  box-sizing: border-box;
  position: relative;
  &__title-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    margin: -40px -40px 40px;
    background: $baseColor;
    color: $white;
    height: 85px;
    box-sizing: border-box;
    position: sticky;
    top: 100px;
    z-index: 2;
    .preloader {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: '';
        background: $baseColor;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        opacity: .75;
      }
    }
  }
  &__title {
    font-family: $RobotoCondensed;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 20px;
    display: flex;
    align-items: center;
    max-width: 80%;
  }
  &__title-inner {
    display: flex;
    flex-wrap: wrap;
  }
  &__title-subtitle {
    color: $greyColor;
    font-size: 14px;
    width: 100%;
    flex: 0 0 auto;
    &:after {
      content: '/';
      margin: 0 5px;
    }
  }
  &__title-actions {
    white-space: nowrap;
  }
  .notification {
    margin-bottom: 40px;
  }
  &_title {
    text-transform: uppercase;
    font-size: 24px;
    font-family: $RobotoCondensed;
    margin-bottom: $pageIndent;
  }
  &_title-icon {
    margin-right: 10px;
  }
  @media screen and (max-width: $maxTablet) {
    transform: translateX(0);
    width: 100%;
    min-width: 100%;
    background: inherit;
    transition: all .25s ease-in-out;
    position: relative;
    z-index: 1;
    margin: 0;
    padding-top: calc(#{$studentHeaderHeightMobile} + #{$studentHeaderIndent * 2} + #{$pageIndent});
    flex: 1;
    &__inner {
      background: $white;
      padding: $pageIndent;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: $maxMobile) {
    padding: 0;
    &__title-holder {
      padding: 0 20px;
      margin: -20px -20px 20px;
      height: 60px;
      position: sticky;
      top: calc(#{$studentHeaderHeightTiny} + #{$studentHeaderIndent * 2});
      z-index: 10;
    }
    &__title {
      font-size: 16px;
    }
    &__inner {
      margin-bottom: 0;
      padding: $pageIndentMobile;
    }
  }
}
