.imageEditor.hasImage:not(.isUsed) .form {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
  z-index: 10;
}
.imageEditor.hasImage:not(.isUsed) .form * {
  height: 100%;
}
.imageEditor.hasImage:not(.isUsed) .form__row {
  margin: 0;
}
.imageEditor.hasImage:not(.isUsed) [class$=__open] {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  font-size: 12px;
  color: #999;
  transition: color 0.15s ease-in-out;
  cursor: pointer;
  width: 100px;
  height: 100px;
  user-select: none;
  line-height: 1;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  overflow: hidden;
  letter-spacing: 0.5px;
  flex: 0 0 auto;
  box-sizing: border-box;
}
.imageEditor.hasImage:not(.isUsed) [class$=__open]:hover {
  color: #333;
}
.imageEditor.hasImage:not(.isUsed) [class$=__open] i {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  transform: translateZ(0);
}
.imageEditor.hasImage:not(.isUsed) [class$=__open] div {
  width: 100%;
  flex: 0 0 auto;
  text-align: center;
}
.imageEditor.isUsed {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.imageEditor.isUsed .imageEditor__shade {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #333;
  opacity: 0.75;
}
.imageEditor.isUsed .imageEditor__holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  z-index: 1;
}
.imageEditor.isUsed .imageEditor__box {
  padding: 40px;
}
.imageEditor.isUsed .imageEditor__inner {
  max-width: 100%;
  max-height: 100%;
  background: #fff;
  padding: 100px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.imageEditor.isUsed .form {
  width: 100px;
  height: 100px;
}
.imageEditor.isUsed .form:hover .noImage {
  color: #333;
}
.imageEditor.isUsed .form:hover .noImage i {
  color: #333;
}
.imageEditor.isUsed .form:hover .textTooltip__text {
  color: #333;
}
.imageEditor.isUsed .form .textTooltip {
  height: 100%;
}
.imageEditor.isUsed .form .textTooltip__text {
  position: static;
  z-index: 0;
  margin: 0;
  transform: none;
  opacity: 1;
  width: 100%;
  flex: 0 0 auto;
  text-align: center;
  color: #999;
  text-transform: none;
  letter-spacing: 0.5px;
  display: block;
  line-height: 1;
}
.imageEditor.isUsed .form__row {
  margin: 0;
  height: 100%;
  display: block;
}
.imageEditor.isUsed .form__file-holder {
  height: 100%;
}
.imageEditor.isUsed .form .noImage {
  background: none;
  color: #999;
  min-height: 0;
  border: none;
  transition: color 0.15s ease-in-out;
}
.imageEditor.isUsed .form .noImage i {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 27px;
  color: #999;
  position: static;
  transition: color 0.15s ease-in-out;
}
.imageEditor__inner {
  position: relative;
}
.imageEditor__image-wrapper {
  position: relative;
  user-select: none;
  max-width: 800px;
}
.imageEditor__image-holder {
  overflow: hidden;
  box-sizing: border-box;
}
.imageEditor__image {
  width: 100%;
  max-width: 100% !important;
  height: auto;
}
.imageEditor__image-bg-holder {
  width: 100%;
  height: 100%;
}
.imageEditor__image-bg {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.imageEditor__image-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.imageEditor__image-text {
  position: absolute;
  width: 400px;
  padding: 40px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  z-index: 1;
  box-sizing: border-box;
}
.imageEditor__image-text-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.imageEditor__image-text.ycenter {
  top: 50%;
  transform: translateY(-50%);
}
.imageEditor__image-text.ycenter.xcenter {
  transform: translate(-50%, -50%);
}
.imageEditor__image-text.ytop {
  top: 0;
}
.imageEditor__image-text.ytop.xcenter {
  transform: translateX(-50%);
}
.imageEditor__image-text.ybottom {
  bottom: 0;
}
.imageEditor__image-text.ybottom.xcenter {
  transform: translateX(-50%);
}
.imageEditor__image-text.xcenter {
  left: 50%;
  text-align: center;
}
.imageEditor__image-text.xleft {
  left: 0;
  text-align: left;
}
.imageEditor__image-text.xright {
  right: 0;
  text-align: right;
}
.imageEditor__image-text h2 {
  font-size: 24px;
}
.imageEditor__image-text h2:not(:last-child) {
  margin-bottom: 10px;
}
.imageEditor__image-text p:not(:last-child) {
  margin-bottom: 20px;
}
.imageEditor__icon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  font-size: 24px;
  background: #333;
  color: #fff;
}
.imageEditor__toolbar {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}
.imageEditor__toolbar:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: -1;
}
.imageEditor__toolbar-col {
  display: flex;
}
.imageEditor__toolbar-heading {
  text-align: center;
  width: 100%;
  flex: 0 0 auto;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.imageEditor__toolbar-btn {
  font-size: 12px;
  color: #999;
  transition: color 0.15s ease-in-out;
  cursor: pointer;
  width: 100px;
  height: 100px;
  user-select: none;
  line-height: 1;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  overflow: hidden;
  letter-spacing: 0.5px;
  flex: 0 0 auto;
  box-sizing: border-box;
}
.imageEditor__toolbar-btn:hover {
  color: #333;
}
.imageEditor__toolbar-btn:hover .coverIcon:after,
.imageEditor__toolbar-btn:hover .containIcon:after {
  border-color: #333;
}
.imageEditor__toolbar-btn:hover .borderIcon {
  border-color: #333;
}
.imageEditor__toolbar-btn:hover .borderStyle {
  border-color: #333;
}
.imageEditor__toolbar-btn:hover .dimensionsIcon {
  background: #333;
}
.imageEditor__toolbar-btn:hover .flipV {
  background: #333;
}
.imageEditor__toolbar-btn:hover .flipV:after {
  border-left-color: #333;
}
.imageEditor__toolbar-btn:hover .flipH {
  background: #333;
}
.imageEditor__toolbar-btn:hover .flipH:after {
  border-top-color: #333;
}
.imageEditor__toolbar-btn:hover .skewX:before,
.imageEditor__toolbar-btn:hover .skewY:before {
  border-color: #333;
}
.imageEditor__toolbar-btn.open {
  background: #f2f2f2;
  color: #333;
}
.imageEditor__toolbar-btn.open .coverIcon:after,
.imageEditor__toolbar-btn.open .containIcon:after {
  border-color: #333;
}
.imageEditor__toolbar-btn.open .borderIcon {
  border-color: #333;
}
.imageEditor__toolbar-btn.active {
  background: #4ec1e2;
  color: #fff;
}
.imageEditor__toolbar-btn-icon {
  display: block;
  font-size: 20px;
  margin: 27px auto 10px;
  text-align: center;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  transform: translateZ(0);
}
.imageEditor__toolbar-btn-label {
  width: 100%;
  flex: 0 0 auto;
  text-align: center;
}
.imageEditor__size {
  position: absolute;
  right: 10px;
  top: 130px;
  bottom: 120px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.imageEditor__size .range {
  max-height: 400px;
  width: 100%;
  height: 100%;
}
.imageEditor__size-track {
  height: 100%;
}
.imageEditor__size-track:after {
  content: "";
  width: 1px;
  bottom: 20px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #ccc;
}
.imageEditor__size-handle {
  width: 20px;
  height: 20px;
  border: 3px solid #999;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: grab;
  border-radius: 100%;
  box-sizing: border-box;
  outline: none;
}
.imageEditor__size-marks {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 20px;
  outline: none;
}
.imageEditor__size-mark {
  position: absolute;
  left: 0;
  right: 0;
}
.imageEditor__size-mark.isActive [class$=-tick] {
  background: #4ec1e2;
}
.imageEditor__size-mark.isActive [class$=-tick-label] {
  color: #4ec1e2;
}
.imageEditor__size-tick {
  width: 5px;
  background: #ccc;
  height: 5px;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
.imageEditor__size-tick-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  margin-left: 15px;
  color: #ccc;
  width: 28px;
  letter-spacing: 0.5px;
}
.imageEditor__actions {
  position: absolute;
  left: 0;
  top: 100px;
  bottom: 100px;
  width: 100px;
  z-index: 2;
}
.imageEditor__actions:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: -1;
}
.imageEditor__dimensions-drop {
  position: absolute;
  left: 100px;
  top: 100%;
  right: 100px;
  background: #f2f2f2;
  max-height: 400px;
  overflow: auto;
  display: flex;
  box-sizing: border-box;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 5px solid #fff;
  animation: topDown 0.15s linear;
  z-index: -1;
}
.imageEditor__dimensions-drop .form {
  width: 200px !important;
  height: 240px !important;
  flex: 0 0 auto;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}
.imageEditor__dimensions-drop .form__row {
  width: 150px;
  height: auto !important;
  flex: 0 0 auto;
}
.imageEditor__dimensions-drop .form__row:not(:first-child) {
  margin-top: 20px;
}
.imageEditor__dimensions-drop .form__field-placeholder {
  background: #f2f2f2 !important;
}
.imageEditor__dimensions-drop .imageEditor__toolbar-btn {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.imageEditor__dimensions-drop .imageEditor__toolbar-btn.active {
  background: #fafafa;
  color: #333;
}
.imageEditor__dimensions-drop .imageEditor__toolbar-btn.active i {
  background: #333;
}
.imageEditor__dimensions-drop .imageEditor__toolbar-btn-label {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageEditor__dimensions-drop .imageEditor__toolbar-btn-icon {
  margin: 0;
}
.imageEditor__dimensions-list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 20px 0;
}
.imageEditor__dimensions .dimensionsIcon {
  background: #ccc;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  transition: background-color 0.15s ease-in-out;
}
.imageEditor .coverIcon {
  position: relative;
  background: #fff;
}
.imageEditor .coverIcon:before {
  content: "";
  position: absolute;
  background: #d7f4fc;
  width: 36px;
  height: 20px;
  left: -8px;
  top: 0;
}
.imageEditor .coverIcon:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 1px solid #999;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out;
}
.imageEditor .containIcon {
  position: relative;
  background: #fff;
}
.imageEditor .containIcon:before {
  content: "";
  position: absolute;
  background: #d7f4fc;
  width: 20px;
  height: 10px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.imageEditor .containIcon:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 1px solid #999;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out;
}
.imageEditor .borderIcon {
  border: 2px dotted #999;
  transition: border-color 0.15s ease-in-out;
}
.imageEditor__text {
  position: absolute;
  left: 100px;
  bottom: 0;
  right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.imageEditor__text:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: -1;
}
.imageEditor__text .form {
  height: auto !important;
  margin: 0 !important;
  width: 100% !important;
}
.imageEditor__text .form__row {
  margin-top: 20px !important;
}
.imageEditor__text .form__row:first-child {
  margin-top: 0 !important;
}
.imageEditor__text .form__field-placeholder {
  background: #f2f2f2 !important;
}
.imageEditor__text-heading-drop, .imageEditor__text-text-drop, .imageEditor__text-btn-drop, .imageEditor__text-position-drop, .imageEditor__text-bg-drop {
  position: absolute;
  left: 0;
  width: 400px;
  bottom: 100px;
  background: #f2f2f2;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: inset 0 -2px 5px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  align-items: center;
  border: 5px solid #fff;
  border-width: 5px 5px 0 0;
  animation: bottomUp 0.15s linear;
  z-index: -1;
}
.imageEditor__text-text-drop {
  left: 47.5px;
  border-width: 5px 5px 0;
}
.imageEditor__text-bg-drop {
  width: 410px;
  left: 345px;
  border-width: 5px 5px 0;
  max-height: 300px;
}
.imageEditor__text-bg-drop .range {
  margin-top: 20px;
  height: 40px;
  width: 100%;
}
.imageEditor__text-position-drop {
  width: 160px;
  left: 370px;
  border-width: 5px 5px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
}
.imageEditor__text-position-item {
  width: 50px;
  height: 50px;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
}
.imageEditor__text-position-item:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: #ccc;
}
.imageEditor__text-position-item:hover, .imageEditor__text-position-item.active {
  background: #fafafa;
}
.imageEditor__text-position-item:hover:after, .imageEditor__text-position-item.active:after {
  background: #333;
}
.imageEditor__text-btn-drop {
  left: 0;
  width: 100%;
  border-width: 5px 0 0;
  overflow: auto;
  max-height: 300px;
  flex-direction: row;
}
.imageEditor__text-btn-drop .form {
  width: 33.33333% !important;
}
.imageEditor__text-btn-drop > * {
  width: 66.66666%;
  flex: 0 0 auto;
}
.imageEditor__text-btn-swatches {
  display: flex;
  flex-wrap: wrap;
  padding-left: 25px;
  box-sizing: border-box;
}
.imageEditor__text-btn-swatches-item {
  width: 100px;
  height: 66.6666666667px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.imageEditor__text-btn-swatches-item.active, .imageEditor__text-btn-swatches-item:hover {
  background: #fafafa;
}
.imageEditor__text-btn-swatches span {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.75);
}
.imageEditor__text-btn-swatches span:not(.btn) {
  color: #4ec1e2;
}
.imageEditor__bg-drop {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  background: #f2f2f2;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  box-shadow: inset 2px 0 5px 0 rgba(0, 0, 0, 0.15);
  border-right: 5px solid #fff;
  animation: ltr 0.15s linear;
  z-index: -1;
}
.imageEditor__filters-drop {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  background: #f2f2f2;
  overflow: auto;
  display: flex;
  flex-direction: column;
  box-shadow: inset 2px 0 5px 0 rgba(0, 0, 0, 0.15);
  border-right: 5px solid #fff;
  animation: ltr 0.15s linear;
  z-index: -1;
  padding: 20px;
  width: 400px;
  box-sizing: border-box;
}
.imageEditor__filters-filter {
  height: 100px;
  margin-bottom: 20px;
}
.imageEditor__filters-insta {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.imageEditor__filters-insta-item {
  width: 33.33333%;
  flex: 0 0 auto;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.imageEditor__filters-insta-item:hover [class*=-img] {
  transform: scale(1.15);
}
.imageEditor__filters-insta-item:hover [class*=-label] {
  color: #333;
}
.imageEditor__filters-insta-item.active [class*=-img] {
  border: 2px solid #4ec1e2;
  padding-top: 95.98%;
}
.imageEditor__filters-insta-item.active [class*=-label] {
  color: #4ec1e2;
}
.imageEditor__filters-insta-img {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: 50% 50%;
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 0.15s ease-in-out;
}
.imageEditor__filters-insta-label {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-top: 10px;
  text-align: center;
  color: #999;
  transition: color 0.15s ease-in-out;
}
.imageEditor .swatches-picker > div {
  height: 100%;
  width: 400px;
}
.imageEditor .swatches-picker > div > div:first-child {
  box-shadow: none !important;
  background: none !important;
  border-radius: 0 !important;
}
.imageEditor .swatches-picker > div > div > div {
  overflow: auto !important;
}
.imageEditor .swatches-picker > div > div > div > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px !important;
}
.imageEditor .swatches-picker > div > div > div > div > div {
  margin: 0 5px !important;
}
.imageEditor__overlay-drop {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: inset 2px 0 5px 0 rgba(0, 0, 0, 0.15);
  border-right: 5px solid #fff;
  animation: ltr 0.15s ease-in-out;
  z-index: -1;
}
.imageEditor__overlay-mode {
  padding: 20px;
  border-bottom: 1px solid #ccc;
}
.imageEditor__overlay-mode .form {
  margin-top: 20px;
  height: auto !important;
  width: 100% !important;
}
.imageEditor__overlay-opacity {
  padding: 20px;
  flex: 0 0 auto;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.imageEditor__overlay-opacity [class$=-heading] {
  margin-bottom: 20px;
}
.imageEditor__overlay-opacity .range {
  height: 40px;
}
.imageEditor__border .imageEditor__toolbar-btn.active {
  background: none;
  color: #333;
}
.imageEditor__border .imageEditor__toolbar-btn.active i {
  border-color: #333;
}
.imageEditor__border-drop {
  position: absolute;
  left: 100px;
  right: 100px;
  top: 100%;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 5px solid #fff;
  animation: topDown 0.15s ease-in-out;
  z-index: -1;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
  padding: 20px;
}
.imageEditor__border-col {
  width: 50%;
  flex: 0 0 auto;
}
.imageEditor__border-col:first-child {
  width: 46.5%;
  padding-right: 10px;
}
.imageEditor__border-style {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.imageEditor__border-style [class$=-heading] {
  margin-bottom: -20px;
  position: relative;
  z-index: 1;
}
.imageEditor__border .borderStyle {
  border-bottom-width: 5px;
  border-bottom-color: #999;
  width: 50px;
  transition: border-color 0.15s ease-in-out;
}
.imageEditor__border .borderStyle.solid {
  border-bottom-style: solid;
}
.imageEditor__border .borderStyle.dashed {
  border-bottom-style: dashed;
}
.imageEditor__border .borderStyle.dotted {
  border-bottom-style: dotted;
}
.imageEditor__border-width {
  flex: 0 0 auto;
}
.imageEditor__border-width [class$=-heading] {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.imageEditor__border-width .range {
  height: 40px;
}
.imageEditor__transform-drop {
  position: absolute;
  left: 100px;
  right: 100px;
  top: 100%;
  background: #f2f2f2;
  max-height: 400px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  box-sizing: border-box;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 5px solid #fff;
  animation: topDown 0.15s linear;
  z-index: -1;
}
.imageEditor__transform-drop > * {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  width: 50%;
  flex: 0 0 auto;
}
.imageEditor__transform-drop > *:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.imageEditor__transform .flipV {
  background: #999;
  width: 30px;
  position: relative;
  transition: background 0.15s ease-in-out;
}
.imageEditor__transform .flipV:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 6px;
  background: #f2f2f2;
  transform: translateX(-50%);
}
.imageEditor__transform .flipV:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -5px;
  bottom: -5px;
  border-left: 2px dashed #999;
  transform: translateX(-50%);
  transition: border-left-color 0.15s ease-in-out;
}
.imageEditor__transform .flipH {
  background: #999;
  width: 30px;
  position: relative;
  transition: background 0.15s ease-in-out;
}
.imageEditor__transform .flipH:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  height: 6px;
  background: #f2f2f2;
  transform: translateY(-50%);
}
.imageEditor__transform .flipH:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 50%;
  right: -5px;
  border-top: 2px dashed #999;
  transform: translateY(-50%);
  transition: border-top-color 0.15s ease-in-out;
}
.imageEditor__transform-skew {
  width: 100%;
}
.imageEditor__transform-skew [class$=-icon-holder] {
  display: flex;
  align-items: center;
  font-family: "Arial", "Helvetica", sans-serif;
}
.imageEditor__transform-skew [class$=-icon-holder] .add,
.imageEditor__transform-skew [class$=-icon-holder] .substract {
  margin-top: 17px;
  font-size: 24px;
  font-style: normal;
  cursor: pointer;
}
.imageEditor__transform-skew [class$=-icon-holder] .add:hover,
.imageEditor__transform-skew [class$=-icon-holder] .substract:hover {
  color: #ea5330;
}
.imageEditor__transform-skew [class$=-icon-holder] .substract {
  margin-left: 5px;
  padding: 5px;
}
.imageEditor__transform-skew [class$=-icon-holder] .add {
  margin-right: 5px;
  margin-top: 20px;
  padding: 5px;
}
.imageEditor__transform-skew [class$=-btn] {
  margin: 0 25px;
}
.imageEditor__transform .skewX {
  background: #d7f4fc;
  border: 1px solid #999;
  width: 30px;
  position: relative;
  cursor: default;
}
.imageEditor__transform .skewX:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px dashed #999;
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  transform: skewX(30deg);
  transition: border-color 0.15s ease-in-out;
}
.imageEditor__transform .skewY {
  background: #d7f4fc;
  border: 1px solid #999;
  width: 30px;
  position: relative;
  cursor: default;
}
.imageEditor__transform .skewY:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px dashed #999;
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  transform: skewY(-20deg);
  transition: border-color 0.15s ease-in-out;
}

@keyframes bottomUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes ltr {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes topDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}