.widget {
  background: $white;
  padding: $widgetIndent;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.15);
  &:not(:first-child) {
    margin-top: $pageIndent;
  }
  &__title {
    color: $white;
    background: $baseColor;
    margin: -$widgetIndent (-$widgetIndent) $widgetIndent;
    padding: $widgetIndent;
    border-radius: 4px 4px 0 0;
  }
  &__icon {
    margin-right: $widgetIndent;
  }
  &__descr {
    border-bottom: 1px solid $mediumGreyColor;
    padding-bottom: $widgetIndent;
    font-family: $RobotoCondensed;
    h3 {
      margin-bottom: $widgetIndent / 2;
      text-transform: uppercase;
    }
    p {
      margin-bottom: $widgetIndent / 2;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.sticky {
    position: sticky;
    top: calc(#{$headerHeight} + #{$sectionTitleHeight} + #{$pageIndent});
  }
}