@import '../../../assets/scss/utils/colors';
@import '../../../assets/scss/utils/fonts';
@import '../../../assets/scss/utils/sizes';

$confirmIndent: 20px;

.confirm {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  height: 100vh;
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $baseColor;
    opacity: .75;
    z-index: -1;
  }
  &__box {
    background: $white;
    box-shadow: 0 2px 5px rgba(0,0,0,.15);
    padding: $confirmIndent;
    border-radius: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    position: absolute;
    text-align: center;
    line-height: 1.5;
    font-family: $RobotoCondensed;
  }
  &__heading {
    text-transform: uppercase;
    background: $baseColor;
    color: $white;
    margin: #{-$confirmIndent - 1} #{-$confirmIndent} 40px;
    padding: $confirmIndent;
    border-radius: 4px 4px 0 0;
  }
  &__text {
    font-size: 18px;
    color: $errorColor;
    margin-bottom: 40px;
  }
  &__actions {
    display: flex;
    justify-content: center;
  }
  &__action {
    cursor: pointer;
    margin: 0 20px;
  }
  @media screen and (max-width: $maxMobile) {
    &__box {
      width: 90%;
      box-sizing: border-box;
      left: 5%;
      transform: none;
      top: 40px;
    }
  }
}
