.btn {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 15px 20px;
  border-radius: 25px;
  min-width: 100px;
  text-transform: uppercase;
  font-family: $RobotoCondensed;
  letter-spacing: .5px;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  &_primary {
    background: $btnPrimary;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &__pale {
    background: $mediumGreyColor;
  }
  &__error {
    background: $errorColor;
    color: $white;
    &:hover {
      color: $white;
    }
    &.btn__working {
      &:after {
        border-top-color: $errorColor;
      }
    }
  }
  &__success {
    background: $successColor;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &__warning {
    background: $warningColor;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &__orange {
    background: $orangeColor;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &__purple {
    background: $purpleColor;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &__pink {
    background: $successColor;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &__dark {
    background: $baseColor;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  &__light {
    background: $white;
    color: $baseColor;
    &:hover {
      color: $baseColor;
    }
  }
  &:hover {
    opacity: .75;
    i {
      color: $white;
    }
  }
  &[disabled] {
    cursor: not-allowed !important;
    filter: grayscale(100%);
    opacity: .5;
  }
  &.inactive {
    filter: grayscale(100%);
    opacity: .5;
  }
  &.round {
    min-width: 0;
    width: 50px;
    height: 50px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__xs {
    padding: 10px 15px;
    &.round {
      width: 40px;
      height: 40px;
    }
  }
  &__working {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: -4px;
      top: -4px;
      right: -4px;
      bottom: -4px;
      background: inherit;
      z-index: -1;
      border-radius: 100%;
      opacity: .25;
    }
    &:after {
      content: '';
      position: absolute;
      left: -4px;
      top: -4px;
      right: -4px;
      bottom: -4px;
      border-top: 4px solid $successColor;
      border-radius: 100%;
      z-index: -1;
      opacity: .5;
      animation: workingBtn 1s linear infinite;
      will-change: transform;
    }
  }
  &__spinning {
    i {
      animation: spinningBtn 2s linear infinite;
    }
  }
  @media screen and (max-width: $maxMobile) {
    min-width: 80px;
    font-size: 12px;
  }
}
@keyframes workingBtn {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinningBtn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
