.lessonPicker__subjectsList {
  border: 1px solid #ccc;
}
.lessonPicker__subject {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  padding: 20px;
  color: #ccc;
}
.lessonPicker__subject:first-child {
  margin-top: 0;
}
.lessonPicker__coursesList {
  padding-top: 10px;
  padding-left: 28px;
}
.lessonPicker__course {
  margin-top: 20px;
}
.lessonPicker__course:first-child {
  margin-top: 0;
}
.lessonPicker__modulesList {
  padding-top: 10px;
  padding-left: 30px;
}
.lessonPicker__module {
  margin-top: 20px;
}
.lessonPicker__module:first-child {
  margin-top: 0;
}
.lessonPicker__lessonsList {
  padding-top: 10px;
  padding-left: 28px;
}
.lessonPicker__lesson {
  margin-top: 10px;
  cursor: pointer;
  color: #333;
  display: flex;
}
.lessonPicker__lesson:hover, .lessonPicker__lesson.isPicked {
  color: #ea5330;
}
.lessonPicker__lesson:first-child {
  margin-top: 0;
}
.lessonPicker__pick {
  margin-top: 20px;
  text-align: center;
}
.lessonPicker__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.lessonPicker__info .nothingFound {
  margin-bottom: -20px;
  width: 100%;
}
.lessonPicker__remove {
  cursor: pointer;
}
.lessonPicker__remove:hover {
  color: #e32929;
}
.lessonPicker__selectedLesson {
  display: flex;
  align-items: center;
  width: 100%;
}
.lessonPicker__selectedLesson span {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  color: #999;
  font-size: 12px;
  letter-spacing: 1px;
}
.lessonPicker__selectedLesson span:before {
  content: "/";
  margin: 0 5px;
}
.lessonPicker__selectedLesson span:first-child:before {
  display: none;
}
.lessonPicker__selectedLesson span:last-child {
  width: 100%;
  flex: 0 0 auto;
  display: block;
  color: #333;
  font-size: inherit;
  margin-top: 5px;
  letter-spacing: inherit;
}
.lessonPicker__selectedLesson span:last-child:before {
  display: none;
}