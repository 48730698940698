@keyframes animateBG {
  0% {
    filter: grayscale(1);
    opacity: .25;
  }
  50% {
    filter: grayscale(0);
    opacity: 1;
  }
  100% {
    filter: grayscale(1);
    opacity: .25;
  }
}

.page {
  padding: ($headerHeight + $pageIndent) $pageIndent $pageIndent ($pageIndent + $navWidth);
  background: $pageColor;
  min-height: calc(100vh - (#{$headerHeight} + #{$pageIndent} * 2));
  position: relative;
  .pageBg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: 850px;
    background-position: 50% 140px;
    opacity: .25;
    filter: grayscale(1);
    background-repeat: repeat-x;

    &.animate {
      animation: animateBG linear 5s infinite;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1280px;
    margin: 0 auto;
    padding-bottom: 80px;
    width: 100%;
    position: relative;
  }
  @media screen and (max-width: $maxMobile) {
    &:after {
      content: 'Контент недоступний для мобільних пристроїв';
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;
      background: $baseColor;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      text-align: center;
      line-height: 1.5;
    }
    &__inner {
      flex-direction: column;
      height: 100%;
      padding-bottom: 0;
    }
  }
  &.student {
    padding: calc(#{$studentHeaderHeight} + #{$studentHeaderIndent * 2} + #{$pageIndent}) $pageIndent 0;
    min-height: calc(100vh - calc(#{$studentHeaderHeight} + #{$studentHeaderIndent * 2} + #{$pageIndent}));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media screen and (max-width: $maxTablet) {
    &.student {
      padding: 0;
      min-height: 0;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      &:after {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: $baseColor;
        opacity: 0;
        transition: all .25s ease-in-out;
        visibility: hidden;
        z-index: 1;
      }
    }
    &.navVisible {
      &:after {
        visibility: visible;
        left: $studentNavWidth;
        opacity: .85;
      }
      .content {
        margin-left: $studentNavWidth;
        filter: blur(2px);
      }
      .footer {
        margin-left: $studentNavWidth;
        filter: blur(2px);
      }
      .studentNav {
        left: 0;
        box-shadow: 0 0 20px 0 rgba(0,0,0,.25);
      }
    }
  }
  @media screen and (max-width: $maxMobile) {
    background: $white;
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-self: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $white;
    opacity: .75;
    z-index: -1;
  }
}

.nothingFound {
  text-align: center;
  color: $errorColor;
  font-weight: bold;
  padding: $pageIndent 0;
}

.pageNotFound {
  height: calc(100vh - #{$headerHeight} - #{$pageIndent * 2});
  display: flex;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: -99999px;
  top: -99999px;
  font-size: 24px;
  text-transform: uppercase;
  color: $greyColor;
  font-weight: bold;
  padding-top: $pageIndent;
  box-sizing: border-box;
  font-family: $RobotoCondensed;
}

.section__title-holder {
  ~ .widget {
    margin-top: $sectionTitleHeight !important;
  }
  ~ .grid {
    margin-top: $sectionTitleHeight - 20px !important;
  }
  + .filters {
    ~ .widget {
      margin-top: $sectionTitleHeight + $filtersHeight !important;
    }
    ~ .grid {
      margin-top: $sectionTitleHeight + $filtersHeight !important;
    }
  }
}
.color-warning {
  color: $warningColor;
}

.laptop-hide {
  opacity: 1;
  visibility: visible;
  @media screen and (min-width: $minLaptop) {
    opacity: 0;
    visibility: hidden;
  }
}
.laptop-show {
  opacity: 0;
  visibility: hidden;
  @media screen and (min-width: $minLaptop) {
    opacity: 1;
    visibility: visible;
  }
}

.isNavCollapsed {
  .main--nav {
    width: $navCollapsedWidth;
    [class$="_text"] {
      display: none;
    }
    i {
      margin-right: 0;
    }
  }
  .page {
    padding-left: #{$navCollapsedWidth + $pageIndent};
  }
  .header {
    left: $navCollapsedWidth;
  }
  .section__title-holder {
    left: $navCollapsedWidth;
  }
  .filters {
    left: $navCollapsedWidth;
  }
}

.tox-statusbar {
  display: none !important;
}
.tinymcePreloader {
  display: none;
  z-index: -99999;
}
.MathJax {
  font-size: 20px !important;
  .math > * > * {
    position: static !important;
  }
  .mspace {
    display: block !important;
    height: 60px !important;
  }
}

#react-mathjax-preview {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
  p {
    margin-bottom: 10px;
  }
  ul {
    li {
      margin-bottom: 10px;
      line-height: 1;
      position: relative;
      padding-left: 15px;
      &:before {
        content: '•';
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  ol {
    counter-reset: section;
    li {
      margin-bottom: 10px;
      line-height: 1;
      position: relative;
      padding-left: 15px;
      &:before {
        counter-increment: section;
        content: counter(section)". ";
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  table {
    width: 100%;
    margin-bottom: 10px;
    border-collapse: collapse;
    td {
      padding: 5px 10px;
      border: 1px solid $mediumGreyColor;
      vertical-align: middle;
      * {
        margin: 0;
      }
    }
  }
}
