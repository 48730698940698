@import '../../../assets/scss/utils/colors';
@import '../../../assets/scss/utils/fonts';
@import '../../../assets/scss/utils/sizes';

$trackerColor: $mediumGreyColor;

.imageEditor {
  &.hasImage:not(.isUsed) {
    .form {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      opacity: 0;
      z-index: 10;
      * {
        height: 100%;
      }
      &__row {
        margin: 0;
      }
    }
    [class$="__open"] {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 11;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: $white;
      font-size: 12px;
      color: #999;
      transition: color .15s ease-in-out;
      cursor: pointer;
      width: $editorBarSize;
      height: $editorBarSize;
      user-select: none;
      line-height: 1;
      font-family: $RobotoCondensed;
      overflow: hidden;
      letter-spacing: .5px;
      flex: 0 0 auto;
      box-sizing: border-box;
      &:hover {
        color: $baseColor;
      }
      i {
        display: block;
        font-size: 20px;
        margin-bottom: 10px;
        text-align: center;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        transform: translateZ(0);
      }
      div {
        width: 100%;
        flex: 0 0 auto;
        text-align: center;
      }
    }
  }
  &.isUsed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    .imageEditor {
      &__shade {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: $baseColor;
        opacity: .75;
      }
      &__holder {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        box-sizing: border-box;
        overflow: auto;
        position: relative;
        z-index: 1;
      }
      &__box {
        padding: $pageIndent;
      }
      &__inner {
        max-width: 100%;
        max-height: 100%;
        background: $white;
        padding: $editorBarSize;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.25);
        overflow: hidden;
      }
    }
    .form {
      width: $editorBarSize;
      height: $editorBarSize;
      &:hover {
        .noImage {
          color: $baseColor;
          i {
            color: $baseColor;
          }
        }
        .textTooltip {
          &__text {
            color: $baseColor;
          }
        }
      }
      .textTooltip {
        height: 100%;
        &__text {
          position: static;
          z-index: 0;
          margin: 0;
          transform: none;
          opacity: 1;
          width: 100%;
          flex: 0 0 auto;
          text-align: center;
          color: $greyColor;
          text-transform: none;
          letter-spacing: .5px;
          display: block;
          line-height: 1;
        }
      }
      &__row {
        margin: 0;
        height: 100%;
        display: block;
      }
      &__file-holder {
        height: 100%;
      }
      .noImage {
        background: none;
        color: $greyColor;
        min-height: 0;
        border: none;
        transition: color .15s ease-in-out;
        i {
          display: block;
          font-size: 20px;
          margin-bottom: 10px;
          text-align: center;
          margin-top: 27px;
          color: $greyColor;
          position: static;
          transition: color .15s ease-in-out;
        }
      }
    }
  }
  &__inner {
    position: relative;
  }
  &__image-wrapper {
    position: relative;
    user-select: none;
    max-width: 800px;
  }
  &__image-holder {
    overflow: hidden;
    box-sizing: border-box;
  }
  &__image {
    width: 100%;
    max-width: 100% !important;
    height: auto;
  }
  &__image-bg-holder {
    width: 100%;
    height: 100%;
  }
  &__image-bg {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
  &__image-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  &__image-text {
    position: absolute;
    width: 400px;
    padding: $pageIndent;
    font-family: $RobotoCondensed;
    z-index: 1;
    box-sizing: border-box;
    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    &.ycenter {
      top: 50%;
      transform: translateY(-50%);
      &.xcenter {
        transform: translate(-50%, -50%);
      }
    }
    &.ytop {
      top: 0;
      &.xcenter {
        transform: translateX(-50%);
      }
    }
    &.ybottom {
      bottom: 0;
      &.xcenter {
        transform: translateX(-50%);
      }
    }
    &.xcenter {
      left: 50%;
      text-align: center;
    }
    &.xleft {
      left: 0;
      text-align: left;
    }
    &.xright {
      right: 0;
      text-align: right;
    }
    h2 {
      font-size: 24px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  &__icon {
    width: $editorBarSize;
    height: $editorBarSize;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    font-size: 24px;
    background: $baseColor;
    color: $white;
  }
  &__toolbar {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: $editorBarSize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $white;
      z-index: -1;
    }
  }
  &__toolbar-col {
    display: flex;
  }
  &__toolbar-heading {
    text-align: center;
    width: 100%;
    flex: 0 0 auto;
    font-family: $RobotoCondensed;
    text-transform: uppercase;
    letter-spacing: .5px;
  }
  &__toolbar-btn {
    font-size: 12px;
    color: #999;
    transition: color .15s ease-in-out;
    cursor: pointer;
    width: $editorBarSize;
    height: $editorBarSize;
    user-select: none;
    line-height: 1;
    font-family: $RobotoCondensed;
    overflow: hidden;
    letter-spacing: .5px;
    flex: 0 0 auto;
    box-sizing: border-box;
    &:hover {
      color: $baseColor;
      .coverIcon,
      .containIcon {
        &:after {
          border-color: $baseColor;
        }
      }
      .borderIcon {
        border-color: $baseColor;
      }
      .borderStyle {
        border-color: $baseColor;
      }
      .dimensionsIcon {
        background: $baseColor;
      }
      .flipV {
        background: $baseColor;
        &:after {
          border-left-color: $baseColor;
        }
      }
      .flipH {
        background: $baseColor;
        &:after {
          border-top-color: $baseColor;
        }
      }
      .skewX,
      .skewY {
        &:before {
          border-color: $baseColor;
        }
      }
    }
    &.open {
      background: $lightGreyColor;
      color: $baseColor;
      .coverIcon,
      .containIcon {
        &:after {
          border-color: $baseColor;
        }
      }
      .borderIcon {
        border-color: $baseColor;
      }
    }
    &.active {
      background: $anchorColor;
      color: $white;
    }
    &-icon {
      display: block;
      font-size: 20px;
      margin: 27px auto 10px;
      text-align: center;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      transform: translateZ(0);
    }
    &-label {
      width: 100%;
      flex: 0 0 auto;
      text-align: center;
    }
  }
  &__size {
    position: absolute;
    right: 10px;
    top: $editorBarSize + 30;
    bottom: $editorBarSize + 20;
    width: $editorBarSize;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    .range {
      max-height: 400px;
      width: 100%;
      height: 100%;
    }
    &-track {
      height: 100%;
      &:after {
        content: '';
        width: 1px;
        bottom: 20px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: $trackerColor;
      }
    }
    &-handle {
      width: 20px;
      height: 20px;
      border: 3px solid $greyColor;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: grab;
      border-radius: 100%;
      box-sizing: border-box;
      outline: none;
    }
    &-marks {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 20px;
      outline: none;
    }
    &-mark {
      position: absolute;
      left: 0;
      right: 0;
      &.isActive {
        [class$="-tick"] {
          background: $anchorColor;
        }
        [class$="-tick-label"] {
          color: $anchorColor;
        }
      }
    }
    &-tick {
      width: 5px;
      background: $trackerColor;
      height: 5px;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
    }
    &-tick-label {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      font-family: $RobotoCondensed;
      margin-left: 15px;
      color: $trackerColor;
      width: 28px;
      letter-spacing: .5px;
    }
  }
  &__actions {
    position: absolute;
    left: 0;
    top: $editorBarSize;
    bottom: $editorBarSize;
    width: $editorBarSize;
    z-index: 2;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $white;
      z-index: -1;
    }
  }
  &__dimensions {
    &-drop {
      position: absolute;
      left: $editorBarSize;
      top: 100%;
      right: $editorBarSize;
      background: $lightGreyColor;
      max-height: 400px;
      overflow: auto;
      display: flex;
      box-sizing: border-box;
      box-shadow: inset 0 2px 5px 0 rgba(0,0,0,.15);
      border-bottom: 5px solid $white;
      animation: topDown .15s linear;
      z-index: -1;
      .form {
        width: 200px !important;
        height: 240px !important;
        flex: 0 0 auto;
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        &__row {
          width: 150px;
          height: auto !important;
          flex: 0 0 auto;
          &:not(:first-child) {
            margin-top: 20px;
          }
        }
        &__field-placeholder {
          background: $lightGreyColor !important;
        }
      }
      .imageEditor__toolbar-btn {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        &.active {
          background: $lightColor;
          color: $baseColor;
          i {
            background: $baseColor;
          }
        }
      }
      .imageEditor__toolbar-btn-label {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .imageEditor__toolbar-btn-icon {
        margin: 0;
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 20px 20px 0;
    }
    .dimensionsIcon {
      background: $mediumGreyColor;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      transition: background-color .15s ease-in-out;
    }
  }
  .coverIcon {
    position: relative;
    background: $white;
    &:before {
      content: '';
      position: absolute;
      background: $lightBlueColor;
      width: 36px;
      height: 20px;
      left: -8px;
      top: 0;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: 1px solid $greyColor;
      box-sizing: border-box;
      transition: border-color .15s ease-in-out;
    }
  }
  .containIcon {
    position: relative;
    background: $white;
    &:before {
      content: '';
      position: absolute;
      background: $lightBlueColor;
      width: 20px;
      height: 10px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border: 1px solid $greyColor;
      box-sizing: border-box;
      transition: border-color .15s ease-in-out;
    }
  }
  .borderIcon {
    border: 2px dotted $greyColor;
    transition: border-color .15s ease-in-out;
  }
  &__text {
    position: absolute;
    left: $editorBarSize;
    bottom: 0;
    right: $editorBarSize;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $white;
      z-index: -1;
    }
    .form {
      height: auto !important;
      margin: 0 !important;
      width: 100% !important;
      &__row {
        margin-top: 20px !important;
        &:first-child {
          margin-top: 0 !important;
        }
      }
      &__field-placeholder {
        background: $lightGreyColor !important;
      }
    }
    &-heading-drop,
    &-text-drop,
    &-btn-drop,
    &-position-drop,
    &-bg-drop {
      position: absolute;
      left: 0;
      width: $editorBarSize * 4;
      bottom: $editorBarSize;
      background: $lightGreyColor;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      padding: 20px;
      box-shadow: inset 0 -2px 5px 0 rgba(0,0,0,.15);
      box-sizing: border-box;
      align-items: center;
      border: 5px solid $white;
      border-width: 5px 5px 0 0;
      animation: bottomUp .15s linear;
      z-index: -1;
    }
    &-text-drop {
      left: #{$editorBarSize / 2 - 2.5px};
      border-width: 5px 5px 0;
    }
    &-bg-drop {
      width: #{$editorBarSize * 4 + 10};
      left: #{$editorBarSize * 3.5 - 5px};
      border-width: 5px 5px 0;
      max-height: $editorBarSize * 3;
      .range {
        margin-top: 20px;
        height: 40px;
        width: 100%;
      }
    }
    &-position-drop {
      width: #{$editorBarSize * 1.5 + 10};
      left: #{$editorBarSize * 3.75 - 5px};
      border-width: 5px 5px 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 0;
    }
    &-position-item {
      width: $editorBarSize / 2;
      height: $editorBarSize / 2;
      flex: 0 0 auto;
      position: relative;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background: $mediumGreyColor;
      }
      &:hover,
      &.active {
        background: $lightColor;
        &:after {
          background: $baseColor;
        }
      }
    }
    &-btn-drop {
      left: 0;
      width: 100%;
      border-width: 5px 0 0;
      overflow: auto;
      max-height: $editorBarSize * 3;
      flex-direction: row;
      .form {
        width: 33.33333% !important;
      }
      > * {
        width: 66.66666%;
        flex: 0 0 auto;
      }
    }
    &-btn-swatches {
      display: flex;
      flex-wrap: wrap;
      padding-left: 25px;
      box-sizing: border-box;
      &-item {
        width: $editorBarSize;
        height: $editorBarSize / 1.5;
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.active,
        &:hover {
          background: $lightColor;
        }
      }
      span {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(.75);
        &:not(.btn) {
          color: $anchorColor;
        }
      }
    }
  }
  &__bg-drop {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    background: $lightGreyColor;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    box-shadow: inset 2px 0 5px 0 rgba(0,0,0,.15);
    border-right: 5px solid $white;
    animation: ltr .15s linear;
    z-index: -1;
  }
  &__filters-drop {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    background: $lightGreyColor;
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-shadow: inset 2px 0 5px 0 rgba(0,0,0,.15);
    border-right: 5px solid $white;
    animation: ltr .15s linear;
    z-index: -1;
    padding: 20px;
    width: $editorBarSize * 4;
    box-sizing: border-box;
  }
  &__filters-filter {
    height: $editorBarSize;
    margin-bottom: 20px;
  }
  &__filters-insta {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -10px 20px;
    border-bottom: 1px solid $mediumGreyColor;
    padding-bottom: 10px;
    &-item {
      width: 33.33333%;
      flex: 0 0 auto;
      position: relative;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        [class*="-img"] {
          transform: scale(1.15);
        }
        [class*="-label"] {
          color: $baseColor;
        }
      }
      &.active {
        [class*="-img"] {
          border: 2px solid $anchorColor;
          padding-top: 95.98%;
        }
        [class*="-label"] {
          color: $anchorColor;
        }
      }
    }
    &-img {
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      background-position: 50% 50%;
      box-sizing: border-box;
      transform: scale(1);
      transition: transform .15s ease-in-out;
    }
    &-label {
      font-family: $RobotoCondensed;
      text-transform: capitalize;
      font-size: 14px;
      letter-spacing: .5px;
      margin-top: 10px;
      text-align: center;
      color: $greyColor;
      transition: color .15s ease-in-out;
    }
  }
  .swatches-picker {
    > div {
      height: 100%;
      width: 400px;
      > div {
        &:first-child {
          box-shadow: none !important;
          background: none !important;
          border-radius: 0 !important;
        }
        > div {
          overflow: auto !important;
          > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 20px !important;
            > div {
              margin: 0 5px !important;
            }
          }
        }
      }
    }
  }
  &__overlay-drop {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    background: $lightGreyColor;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-shadow: inset 2px 0 5px 0 rgba(0,0,0,.15);
    border-right: 5px solid $white;
    animation: ltr .15s ease-in-out;
    z-index: -1;
  }
  &__overlay-mode {
    padding: 20px;
    border-bottom: 1px solid $mediumGreyColor;
    .form {
      margin-top: 20px;
      height: auto !important;
      width: 100% !important;
    }
  }
  &__overlay-opacity {
    padding: 20px;
    flex: 0 0 auto;
    margin-bottom: 20px;
    border-bottom: 1px solid $mediumGreyColor;
    [class$="-heading"] {
      margin-bottom: 20px;
    }
    .range {
      height: 40px;
    }
  }
  &__border {
    .imageEditor__toolbar-btn {
      &.active {
        background: none;
        color: $baseColor;
        i {
          border-color: $baseColor;
        }
      }
    }
    &-drop {
      position: absolute;
      left: $editorBarSize;
      right: $editorBarSize;
      top: 100%;
      background: $lightGreyColor;
      display: flex;
      flex-direction: row;
      box-shadow: inset 0 2px 5px 0 rgba(0,0,0,.15);
      border-bottom: 5px solid $white;
      animation: topDown .15s ease-in-out;
      z-index: -1;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: $editorBarSize * 3;
      padding: 20px;
    }
    &-col {
      width: 50%;
      flex: 0 0 auto;
      &:first-child {
        width: 46.5%;
        padding-right: 10px;
      }
    }
    &-style {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-bottom: 1px solid $mediumGreyColor;
      margin-bottom: 20px;
      [class$="-heading"] {
        margin-bottom: -20px;
        position: relative;
        z-index: 1;
      }
    }
    .borderStyle {
      border-bottom-width: 5px;
      border-bottom-color: $greyColor;
      width: 50px;
      transition: border-color .15s ease-in-out;
      &.solid {
        border-bottom-style: solid;
      }
      &.dashed {
        border-bottom-style: dashed;
      }
      &.dotted {
        border-bottom-style: dotted;
      }
    }
    &-width {
      flex: 0 0 auto;
      [class$="-heading"] {
        margin-bottom: 20px;
        position: relative;
        z-index: 1;
      }
      .range {
        height: 40px;
      }
    }
  }
  &__transform {
    &-drop {
      position: absolute;
      left: $editorBarSize;
      right: $editorBarSize;
      top: 100%;
      background: $lightGreyColor;
      max-height: 400px;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      box-sizing: border-box;
      box-shadow: inset 0 2px 5px 0 rgba(0,0,0,.15);
      border-bottom: 5px solid $white;
      animation: topDown .15s linear;
      z-index: -1;
      > * {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-bottom: 1px solid $mediumGreyColor;
        margin-bottom: 20px;
        width: 50%;
        flex: 0 0 auto;
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
    .flipV {
      background: $greyColor;
      width: 30px;
      position: relative;
      transition: background .15s ease-in-out;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        width: 6px;
        background: $lightGreyColor;
        transform: translateX(-50%);
      }
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: -5px;
        bottom: -5px;
        border-left: 2px dashed $greyColor;
        transform: translateX(-50%);
        transition: border-left-color .15s ease-in-out;
      }
    }
    .flipH {
      background: $greyColor;
      width: 30px;
      position: relative;
      transition: background .15s ease-in-out;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        height: 6px;
        background: $lightGreyColor;
        transform: translateY(-50%);
      }
      &:after {
        content: '';
        position: absolute;
        left: -5px;
        top: 50%;
        right: -5px;
        border-top: 2px dashed $greyColor;
        transform: translateY(-50%);
        transition: border-top-color .15s ease-in-out;
      }
    }
    &-skew {
      width: 100%;
      [class$="-icon-holder"] {
        display: flex;
        align-items: center;
        font-family: 'Arial', 'Helvetica', sans-serif;
        .add,
        .substract {
          margin-top: 17px;
          font-size: 24px;
          font-style: normal;
          cursor: pointer;
          &:hover {
            color: $linkHoverColor;
          }
        }
        .substract {
          margin-left: 5px;
          padding: 5px;
        }
        .add {
          margin-right: 5px;
          margin-top: 20px;
          padding: 5px;
        }
      }
      [class$="-btn"] {
        margin: 0 25px;
      }
    }
    .skewX {
      background: $lightBlueColor;
      border: 1px solid $greyColor;
      width: 30px;
      position: relative;
      cursor: default;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        border: 2px dashed $greyColor;
        position: absolute;
        left: -2px;
        top: -2px;
        right: -2px;
        bottom: -2px;
        transform: skewX(30deg);
        transition: border-color .15s ease-in-out;
      }
    }
    .skewY {
      background: $lightBlueColor;
      border: 1px solid $greyColor;
      width: 30px;
      position: relative;
      cursor: default;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        border: 2px dashed $greyColor;
        position: absolute;
        left: -2px;
        top: -2px;
        right: -2px;
        bottom: -2px;
        transform: skewY(-20deg);
        transition: border-color .15s ease-in-out;
      }
    }
  }
}

@keyframes bottomUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes ltr {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes topDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}