@import '../../assets/scss/utils/colors';
@import '../../assets/scss/utils/fonts';
@import '../../assets/scss/utils/sizes';

.studentContact {
  &__list {
    font-family: $RobotoCondensed;
    display: flex;
    flex-wrap: wrap;
    margin: -40px -20px 0;
  }
  &__list-item {
    margin-top: 40px;
    width: 50%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  &__list-item-name {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }
  &__list-item-phone {
    display: flex;
    align-items: center;
    i {
      background: $lightColor;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
    }
  }
  .map {
    margin-bottom: 40px;
  }
  @media screen and (max-width: $maxMobile) {
    &__list-item {
      width: 100%;
    }
  }
}