.confirm {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  height: 100vh;
}
.confirm__overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #333;
  opacity: 0.75;
  z-index: -1;
}
.confirm__box {
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  position: absolute;
  text-align: center;
  line-height: 1.5;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.confirm__heading {
  text-transform: uppercase;
  background: #333;
  color: #fff;
  margin: -21px -20px 40px;
  padding: 20px;
  border-radius: 4px 4px 0 0;
}
.confirm__text {
  font-size: 18px;
  color: #e32929;
  margin-bottom: 40px;
}
.confirm__actions {
  display: flex;
  justify-content: center;
}
.confirm__action {
  cursor: pointer;
  margin: 0 20px;
}
@media screen and (max-width: 769px) {
  .confirm__box {
    width: 90%;
    box-sizing: border-box;
    left: 5%;
    transform: none;
    top: 40px;
  }
}