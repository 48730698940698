[class*="imageFilter"] {
  position: relative;
}

[class*="imageFilter"]::before {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.imageFilter-1977 {
  -webkit-filter: sepia(.5) hue-rotate(-30deg) saturate(1.4);
  filter: sepia(.5) hue-rotate(-30deg) saturate(1.4);
}

.imageFilter-aden {
  -webkit-filter: sepia(.2) brightness(1.15) saturate(1.4);
  filter: sepia(.2) brightness(1.15) saturate(1.4);
}

.imageFilter-aden::before {
  background: rgba(125, 105, 24, .1);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-amaro {
  -webkit-filter: sepia(.35) contrast(1.1) brightness(1.2) saturate(1.3);
  filter: sepia(.35) contrast(1.1) brightness(1.2) saturate(1.3);
}

.imageFilter-amaro::before {
  background: rgba(125, 105, 24, .2);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-ashby {
  -webkit-filter: sepia(.5) contrast(1.2) saturate(1.8);
  filter: sepia(.5) contrast(1.2) saturate(1.8);
}

.imageFilter-ashby::before {
  background: rgba(125, 105, 24, .35);
  content: "";
  mix-blend-mode: lighten;
}

.imageFilter-brannan {
  -webkit-filter: sepia(.4) contrast(1.25) brightness(1.1) saturate(.9) hue-rotate(-2deg);
  filter: sepia(.4) contrast(1.25) brightness(1.1) saturate(.9) hue-rotate(-2deg);
}

.imageFilter-brooklyn {
  -webkit-filter: sepia(.25) contrast(1.25) brightness(1.25) hue-rotate(5deg);
  filter: sepia(.25) contrast(1.25) brightness(1.25) hue-rotate(5deg);
}

.imageFilter-brooklyn::before {
  background: rgba(127, 187, 227, .2);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-charmes {
  -webkit-filter: sepia(.25) contrast(1.25) brightness(1.25) saturate(1.35) hue-rotate(-5deg);
  filter: sepia(.25) contrast(1.25) brightness(1.25) saturate(1.35) hue-rotate(-5deg);
}

.imageFilter-charmes::before {
  background: rgba(125, 105, 24, .25);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-clarendon {
  -webkit-filter: sepia(.15) contrast(1.25) brightness(1.25) hue-rotate(5deg);
  filter: sepia(.15) contrast(1.25) brightness(1.25) hue-rotate(5deg);
}

.imageFilter-clarendon::before {
  background: rgba(127, 187, 227, .4);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-crema {
  -webkit-filter: sepia(.5) contrast(1.25) brightness(1.15) saturate(.9) hue-rotate(-2deg);
  filter: sepia(.5) contrast(1.25) brightness(1.15) saturate(.9) hue-rotate(-2deg);
}

.imageFilter-crema::before {
  background: rgba(125, 105, 24, .2);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-dogpatch {
  -webkit-filter: sepia(.35) saturate(1.1) contrast(1.5);
  filter: sepia(.35) saturate(1.1) contrast(1.5);
}

.imageFilter-earlybird {
  -webkit-filter: sepia(.25) contrast(1.25) brightness(1.15) saturate(.9) hue-rotate(-5deg);
  filter: sepia(.25) contrast(1.25) brightness(1.15) saturate(.9) hue-rotate(-5deg);
}

.imageFilter-earlybird::before {
  background: radial-gradient(circle closest-corner, transparent 0, rgba(125, 105, 24, .2) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 0, rgba(125, 105, 24, .2) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 0, rgba(125, 105, 24, .2) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 0, rgba(125, 105, 24, .2) 100%);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-gingham {
  -webkit-filter: contrast(1.1) brightness(1.1);
  filter: contrast(1.1) brightness(1.1);
}

.imageFilter-gingham::before {
  background: #e6e6e6;
  content: "";
  mix-blend-mode: soft-light;
}

.imageFilter-ginza {
  -webkit-filter: sepia(.25) contrast(1.15) brightness(1.2) saturate(1.35) hue-rotate(-5deg);
  filter: sepia(.25) contrast(1.15) brightness(1.2) saturate(1.35) hue-rotate(-5deg);
}

.imageFilter-ginza::before {
  background: rgba(125, 105, 24, .15);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-hefe {
  -webkit-filter: sepia(.4) contrast(1.5) brightness(1.2) saturate(1.4) hue-rotate(-10deg);
  filter: sepia(.4) contrast(1.5) brightness(1.2) saturate(1.4) hue-rotate(-10deg);
}

.imageFilter-hefe::before {
  background: radial-gradient(circle closest-corner, transparent 0, rgba(0, 0, 0, .25) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 0, rgba(0, 0, 0, .25) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 0, rgba(0, 0, 0, .25) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 0, rgba(0, 0, 0, .25) 100%);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-helena {
  -webkit-filter: sepia(.5) contrast(1.05) brightness(1.05) saturate(1.35);
  filter: sepia(.5) contrast(1.05) brightness(1.05) saturate(1.35);
}

.imageFilter-helena::before {
  background: rgba(158, 175, 30, .25);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-hudson {
  -webkit-filter: sepia(.25) contrast(1.2) brightness(1.2) saturate(1.05) hue-rotate(-15deg);
  filter: sepia(.25) contrast(1.2) brightness(1.2) saturate(1.05) hue-rotate(-15deg);
}

.imageFilter-hudson::before {
  background: radial-gradient(circle closest-corner, transparent 25%, rgba(25, 62, 167, .25) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 25%, rgba(25, 62, 167, .25) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 25%, rgba(25, 62, 167, .25) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 25%, rgba(25, 62, 167, .25) 100%);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-inkwell {
  -webkit-filter: brightness(1.25) contrast(.85) grayscale(1);
  filter: brightness(1.25) contrast(.85) grayscale(1);
}

.imageFilter-juno {
  -webkit-filter: sepia(.35) contrast(1.15) brightness(1.15) saturate(1.8);
  filter: sepia(.35) contrast(1.15) brightness(1.15) saturate(1.8);
}

.imageFilter-juno::before {
  background: rgba(127, 187, 227, .2);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-kelvin {
  -webkit-filter: sepia(.15) contrast(1.5) brightness(1.1) hue-rotate(-10deg);
  filter: sepia(.15) contrast(1.5) brightness(1.1) hue-rotate(-10deg);
}

.imageFilter-kelvin::before {
  background: radial-gradient(circle closest-corner, rgba(128, 78, 15, .25) 0, rgba(128, 78, 15, .5) 100%);
  background: -o-radial-gradient(circle closest-corner, rgba(128, 78, 15, .25) 0, rgba(128, 78, 15, .5) 100%);
  background: -moz-radial-gradient(circle closest-corner, rgba(128, 78, 15, .25) 0, rgba(128, 78, 15, .5) 100%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(128, 78, 15, .25) 0, rgba(128, 78, 15, .5) 100%);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-lark {
  -webkit-filter: sepia(.25) contrast(1.2) brightness(1.3) saturate(1.25);
  filter: sepia(.25) contrast(1.2) brightness(1.3) saturate(1.25);
}

.imageFilter-lofi {
  -webkit-filter: saturate(1.1) contrast(1.5);
  filter: saturate(1.1) contrast(1.5);
}

.imageFilter-ludwig {
  -webkit-filter: sepia(.25) contrast(1.05) brightness(1.05) saturate(2);
  filter: sepia(.25) contrast(1.05) brightness(1.05) saturate(2);
}

.imageFilter-ludwig::before {
  background: rgba(125, 105, 24, .1);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-maven {
  -webkit-filter: sepia(.35) contrast(1.05) brightness(1.05) saturate(1.75);
  filter: sepia(.35) contrast(1.05) brightness(1.05) saturate(1.75);
}

.imageFilter-maven::before {
  background: rgba(158, 175, 30, .25);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-mayfair {
  -webkit-filter: contrast(1.1) brightness(1.15) saturate(1.1);
  filter: contrast(1.1) brightness(1.15) saturate(1.1);
}

.imageFilter-mayfair::before {
  background: radial-gradient(circle closest-corner, transparent 0, rgba(175, 105, 24, .4) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 0, rgba(175, 105, 24, .4) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 0, rgba(175, 105, 24, .4) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 0, rgba(175, 105, 24, .4) 100%);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-moon {
  -webkit-filter: brightness(1.4) contrast(.95) saturate(0) sepia(.35);
  filter: brightness(1.4) contrast(.95) saturate(0) sepia(.35);
}

.imageFilter-nashville {
  -webkit-filter: sepia(.25) contrast(1.5) brightness(.9) hue-rotate(-15deg);
  filter: sepia(.25) contrast(1.5) brightness(.9) hue-rotate(-15deg);
}

.imageFilter-nashville::before {
  background: radial-gradient(circle closest-corner, rgba(128, 78, 15, .5) 0, rgba(128, 78, 15, .65) 100%);
  background: -o-radial-gradient(circle closest-corner, rgba(128, 78, 15, .5) 0, rgba(128, 78, 15, .65) 100%);
  background: -moz-radial-gradient(circle closest-corner, rgba(128, 78, 15, .5) 0, rgba(128, 78, 15, .65) 100%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(128, 78, 15, .5) 0, rgba(128, 78, 15, .65) 100%);
  content: "";
  mix-blend-mode: screen;
}

.imageFilter-perpetua {
  -webkit-filter: contrast(1.1) brightness(1.25) saturate(1.1);
  filter: contrast(1.1) brightness(1.25) saturate(1.1);
}

.imageFilter-perpetua::before {
  background: linear-gradient(to bottom, rgba(0, 91, 154, .25), rgba(230, 193, 61, .25));
  background: -o-linear-gradient(top, rgba(0, 91, 154, .25), rgba(230, 193, 61, .25));
  background: -moz-linear-gradient(top, rgba(0, 91, 154, .25), rgba(230, 193, 61, .25));
  background: -webkit-linear-gradient(top, rgba(0, 91, 154, .25), rgba(230, 193, 61, .25));
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 91, 154, .25)), to(rgba(230, 193, 61, .25)));
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-poprocket {
  -webkit-filter: sepia(.15) brightness(1.2);
  filter: sepia(.15) brightness(1.2);
}

.imageFilter-poprocket::before {
  background: radial-gradient(circle closest-corner, rgba(206, 39, 70, .75) 40%, black 80%);
  background: -o-radial-gradient(circle closest-corner, rgba(206, 39, 70, .75) 40%, black 80%);
  background: -moz-radial-gradient(circle closest-corner, rgba(206, 39, 70, .75) 40%, black 80%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(206, 39, 70, .75) 40%, black 80%);
  content: "";
  mix-blend-mode: screen;
}

.imageFilter-reyes {
  -webkit-filter: sepia(.75) contrast(.75) brightness(1.25) saturate(1.4);
  filter: sepia(.75) contrast(.75) brightness(1.25) saturate(1.4);
}

.imageFilter-rise {
  -webkit-filter: sepia(.25) contrast(1.25) brightness(1.2) saturate(.9);
  filter: sepia(.25) contrast(1.25) brightness(1.2) saturate(.9);
}

.imageFilter-rise::before {
  background: radial-gradient(circle closest-corner, transparent 0, rgba(230, 193, 61, .25) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 0, rgba(230, 193, 61, .25) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 0, rgba(230, 193, 61, .25) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 0, rgba(230, 193, 61, .25) 100%);
  content: "";
  mix-blend-mode: lighten;
}

.imageFilter-sierra {
  -webkit-filter: sepia(.25) contrast(1.5) brightness(.9) hue-rotate(-15deg);
  filter: sepia(.25) contrast(1.5) brightness(.9) hue-rotate(-15deg);
}

.imageFilter-sierra::before {
  background: radial-gradient(circle closest-corner, rgba(128, 78, 15, .5) 0, rgba(0, 0, 0, .65) 100%);
  background: -o-radial-gradient(circle closest-corner, rgba(128, 78, 15, .5) 0, rgba(0, 0, 0, .65) 100%);
  background: -moz-radial-gradient(circle closest-corner, rgba(128, 78, 15, .5) 0, rgba(0, 0, 0, .65) 100%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(128, 78, 15, .5) 0, rgba(0, 0, 0, .65) 100%);
  content: "";
  mix-blend-mode: screen;
}

.imageFilter-skyline {
  -webkit-filter: sepia(.15) contrast(1.25) brightness(1.25) saturate(1.2);
  filter: sepia(.15) contrast(1.25) brightness(1.25) saturate(1.2);
}

.imageFilter-slumber {
  -webkit-filter: sepia(.35) contrast(1.25) saturate(1.25);
  filter: sepia(.35) contrast(1.25) saturate(1.25);
}

.imageFilter-slumber::before {
  background: rgba(125, 105, 24, .2);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-stinson {
  -webkit-filter: sepia(.35) contrast(1.25) brightness(1.1) saturate(1.25);
  filter: sepia(.35) contrast(1.25) brightness(1.1) saturate(1.25);
}

.imageFilter-stinson::before {
  background: rgba(125, 105, 24, .45);
  content: "";
  mix-blend-mode: lighten;
}

.imageFilter-sutro {
  -webkit-filter: sepia(.4) contrast(1.2) brightness(.9) saturate(1.4) hue-rotate(-10deg);
  filter: sepia(.4) contrast(1.2) brightness(.9) saturate(1.4) hue-rotate(-10deg);
}

.imageFilter-sutro::before {
  background: radial-gradient(circle closest-corner, transparent 50%, rgba(0, 0, 0, .5) 90%);
  background: -o-radial-gradient(circle closest-corner, transparent 50%, rgba(0, 0, 0, .5) 90%);
  background: -moz-radial-gradient(circle closest-corner, transparent 50%, rgba(0, 0, 0, .5) 90%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 50%, rgba(0, 0, 0, .5) 90%);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-toaster {
  -webkit-filter: sepia(.25) contrast(1.5) brightness(.95) hue-rotate(-15deg);
  filter: sepia(.25) contrast(1.5) brightness(.95) hue-rotate(-15deg);
}

.imageFilter-toaster::before {
  background: radial-gradient(circle, #804e0f, rgba(0, 0, 0, .25));
  background: -o-radial-gradient(circle, #804e0f, rgba(0, 0, 0, .25));
  background: -moz-radial-gradient(circle, #804e0f, rgba(0, 0, 0, .25));
  background: -webkit-radial-gradient(circle, #804e0f, rgba(0, 0, 0, .25));
  content: "";
  mix-blend-mode: screen;
}

.imageFilter-valencia {
  -webkit-filter: sepia(.25) contrast(1.1) brightness(1.1);
  filter: sepia(.25) contrast(1.1) brightness(1.1);
}

.imageFilter-valencia::before {
  background: rgba(230, 193, 61, .1);
  content: "";
  mix-blend-mode: lighten;
}

.imageFilter-vesper {
  -webkit-filter: sepia(.35) contrast(1.15) brightness(1.2) saturate(1.3);
  filter: sepia(.35) contrast(1.15) brightness(1.2) saturate(1.3);
}

.imageFilter-vesper::before {
  background: rgba(125, 105, 24, .25);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-walden {
  -webkit-filter: sepia(.35) contrast(.8) brightness(1.25) saturate(1.4);
  filter: sepia(.35) contrast(.8) brightness(1.25) saturate(1.4);
}

.imageFilter-walden::before {
  background: rgba(229, 240, 128, .5);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-willow {
  -webkit-filter: brightness(1.2) contrast(.85) saturate(.05) sepia(.2);
  filter: brightness(1.2) contrast(.85) saturate(.05) sepia(.2);
}

.imageFilter-xpro-ii {
  -webkit-filter: sepia(.45) contrast(1.25) brightness(1.75) saturate(1.3) hue-rotate(-5deg);
  filter: sepia(.45) contrast(1.25) brightness(1.75) saturate(1.3) hue-rotate(-5deg);
}

.imageFilter-xpro-ii::before {
  background: radial-gradient(circle closest-corner, rgba(0, 91, 154, .35) 0, rgba(0, 0, 0, .65) 100%);
  background: -o-radial-gradient(circle closest-corner, rgba(0, 91, 154, .35) 0, rgba(0, 0, 0, .65) 100%);
  background: -moz-radial-gradient(circle closest-corner, rgba(0, 91, 154, .35) 0, rgba(0, 0, 0, .65) 100%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(0, 91, 154, .35) 0, rgba(0, 0, 0, .65) 100%);
  content: "";
  mix-blend-mode: multiply;
}