.chatroom__info, .chatroom__error {
  display: flex;
  padding-top: 56.25%;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #fff;
}
.chatroom__info > span, .chatroom__error > span {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ccc;
}
.chatroom__info > span i, .chatroom__error > span i {
  font-size: 80px;
  margin-bottom: 40px;
  color: #ccc;
}

hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #fff;
  opacity: 0.25;
}

.chatroom__title {
  text-align: center;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  max-width: 800px;
  padding: 0 45px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
}
.chatroom__title-text {
  text-align: left;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chatroom__title-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatroom__connection {
  margin-right: 10px;
  margin-left: -25px;
  position: relative;
}
.chatroom__connection.good {
  color: #00c020;
}
.chatroom__connection.medium {
  color: #ffbb33;
}
.chatroom__connection.bad {
  color: #e32929;
}
.chatroom__connection.offline {
  color: #ccc;
}
.chatroom__connection.offline .offlineAnimation {
  position: absolute;
  left: 0;
  top: 0;
  color: #999;
  width: 0;
  overflow: hidden;
  animation: connectionLost 1s linear infinite;
}
.chatroom__timer {
  font-size: 14px;
  margin-top: 5px;
  font-weight: 300;
}
.chatroom__interactions-holder {
  position: relative;
  padding-top: 10px;
  transform: translateY(-5px);
}
.chatroom__interactions-holder:hover [class$=__interactions] {
  display: block;
}
.chatroom__interactions {
  position: absolute;
  bottom: 100%;
  background: #fff;
  padding: 10px;
  transform: translateX(-50%);
  left: 50%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: none;
  z-index: 1;
}
.chatroom__interactions:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -7px;
  background: #fff;
  width: 10px;
  height: 10px;
  transform: rotate(45deg) translateX(-50%);
}
.chatroom__interactions-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 10px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  cursor: pointer;
}
.chatroom__interactions-item:first-child {
  margin-top: 0;
}
.chatroom__interactions-item .btn {
  margin-right: 10px;
  opacity: 1 !important;
  width: 30px;
  height: 30px;
}
.chatroom__interactions-item .btn i {
  font-size: 14px;
}
.chatroom__interactions-item:hover {
  background: #d7f4fc;
}
.chatroom__message-holder {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}
.chatroom__box {
  position: relative;
}
.chatroom__box [class$=_chatContainer] {
  background: #333;
  border: 1px solid #ea5330;
  box-sizing: border-box;
}
.chatroom__box.fixed {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999999;
  width: 460px;
  max-width: 100%;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  background: #333;
  opacity: 0.75;
  box-sizing: border-box;
  transition: all 0.15s ease-in-out;
}
.chatroom__box.fixed .btn {
  width: 40px;
  height: 40px;
  font-size: 16px;
}
.chatroom__box.fixed:hover {
  opacity: 1;
}
.chatroom__box.fixed [class$=__btnsHolder] {
  height: auto;
  padding-top: 20px;
}
.chatroom__box.fixed [class$=__users] {
  color: #fff;
}
.chatroom__box.fixed [class$=__user-avatar-holder] {
  background-color: #999;
}
.chatroom__box.fixed.noOpacity {
  opacity: 1;
}
.chatroom__box.fixed [class$=__title] {
  color: #fff;
  top: -45px;
}
.chatroom__users {
  display: flex;
  min-height: 150px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  flex-direction: column;
  height: calc(100% - 120px);
  overflow: auto;
  box-sizing: border-box;
}
.chatroom__users > * {
  display: flex;
  flex-direction: column;
}
.chatroom__user {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 10px;
  width: 60px;
  text-align: center;
  line-height: 1.25;
  font-size: 12px;
  color: #fff;
  opacity: 0.25;
}
.chatroom__user.isPresent {
  opacity: 1;
  color: #00c020;
}
.chatroom__user-avatar-holder {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}
.chatroom__user-avatar {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50% 50%;
}
.chatroom__user-avatar-placeholder {
  color: #333;
}
.chatroom__user-name {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
}
.chatroom__error {
  color: #e32929;
}
.chatroom__error > span {
  color: #e32929;
}
.chatroom__error > span i {
  color: #e32929;
}
.chatroom__info {
  position: relative;
}
.chatroom__info [class$=__users] {
  background: #333;
  height: 100%;
}
.chatroom__shareScreen:not(:empty) {
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.chatroom__shareScreen:not(:empty) ~ * {
  display: none;
}
.chatroom__shareScreen:not(:empty) .video {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}
.chatroom__shareScreen:not(:empty) video {
  object-fit: contain;
  width: 100% !important;
  height: 100% !important;
}
.chatroom__btnsHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  z-index: 1;
  max-width: 600px;
  margin: 0 auto;
}
.chatroom__btnsHolder > * {
  margin: 0 10px;
}
.chatroom__btnsHolder .btn {
  cursor: pointer;
  font-size: 20px;
}
.chatroom__chatHolder {
  margin: 120px auto 0;
  position: relative;
}
.chatroom__chatHolder.isFullscreen {
  max-width: 100%;
  height: 100%;
}
.chatroom__chatHolder.isFullscreen .jitsi-container {
  height: 100%;
  padding-top: 0;
}
.chatroom__chatHolder.isFullscreen .textTooltip__text {
  color: #fff;
}
.chatroom__chatHolder.isFullscreen [class$=__lesson] {
  padding: 0;
  bottom: 1px;
}
.chatroom__chatHolder.isFullscreen [class$=__title] {
  position: absolute;
  z-index: 1;
  color: #fff;
  max-width: 100%;
  top: 0;
  left: 80px;
  padding: 20px 100px 20px 20px;
  background: rgb(248, 80, 50);
  background: linear-gradient(rgba(0, 0, 0, 0.75), transparent);
}
.chatroom__chatHolder.isFullscreen [class$=__users] {
  height: 100%;
  z-index: 1;
}
.chatroom__chatHolder.isFullscreen [class$=__chatContainer] {
  padding-top: 0;
  height: 100%;
}
.chatroom__chatHolder.isFullscreen [class$=__chatContainer] iframe {
  position: static;
  height: 100%;
}
.chatroom__chatHolder.isFullscreen [class$=__user-avatar-holder] {
  background-color: #999;
}
.chatroom__chatHolder.isFullscreen [class$="__user-avatar-placeholder fa fa-user"] {
  color: #fff;
}
.chatroom__chatHolder.isFullscreen [class$=__btnsHolder] {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 120px;
}
.chatroom__chatHolder.isFullscreen [class$=__remoteTracks] {
  left: 80px;
}
.chatroom__chatContainer {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  transition: padding 0.15s ease-in-out;
}
.chatroom__chatContainer iframe {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.chatroom__allow {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  max-width: 500px;
  margin: 0 auto 10px;
  text-align: center;
  line-height: 1.25;
}
.chatroom__devicePicker {
  max-width: 200px;
  margin-left: auto;
  height: 0;
  margin-bottom: -20px;
}
.chatroom__devicePicker .form__field-placeholder {
  background: #fafafa !important;
}
.chatroom__chatContainer .video {
  position: relative;
}
.chatroom__chatContainer video {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.chatroom__chatContainer .isMuted {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1;
  color: #e32929;
  font-size: 20px;
}
.chatroom__lesson {
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  padding-top: 56.25%;
  z-index: 1;
  background: #fff;
}
.chatroom__lesson-inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
.chatroom__lesson-blocks {
  width: 250px;
  background: #333;
  max-width: 25%;
  overflow: auto;
  height: 100%;
}
.chatroom__lesson-blocks > * {
  height: 100% !important;
}
.chatroom__lesson-blocks .scrollbar__content {
  overflow-x: hidden !important;
}
.chatroom__lesson-blocks .scrollbar__track {
  z-index: 2;
}
.chatroom__lesson-blocks .scrollbar__track > * {
  background: #4ec1e2 !important;
}
.chatroom__lesson-blocks .article {
  padding: 10px;
  box-sizing: border-box;
}
.chatroom__lesson-blocks .article__block {
  margin-bottom: 5px;
  font-size: 8px;
  background: #fff;
  position: relative;
  padding: 5px;
  cursor: pointer;
}
.chatroom__lesson-blocks .article__block:hover:after {
  background: #4ec1e2;
  opacity: 0.25;
}
.chatroom__lesson-blocks .article__block:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transition: all 0.15s ease-in-out;
}
.chatroom__lesson-blocks .article__block * {
  user-select: none;
}
.chatroom__lesson-blocks .article__block p {
  margin-bottom: 5px;
}
.chatroom__lesson-blocks .article__block:last-child {
  margin-bottom: 0;
}
.chatroom__lesson-content {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.chatroom__lesson-content > * {
  height: 100% !important;
}
.chatroom__lesson-content .scrollbar__track > * {
  background: #4ec1e2 !important;
}
.chatroom__lesson-content .scrollbar__content {
  height: 100% !important;
  overflow-x: hidden !important;
}
.chatroom__lesson-content .article {
  max-width: 900px;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatroom__lesson-content .article__block {
  width: 100%;
  height: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}

.student .closeFullsizeVideo,
.guest .closeFullsizeVideo {
  display: none;
}
.student .chatroom__chatHolder,
.guest .chatroom__chatHolder {
  margin-top: 80px;
}
.student .chatroom__users,
.guest .chatroom__users {
  display: none !important;
}
.student .chatroom__remoteTracks,
.guest .chatroom__remoteTracks {
  position: absolute;
  left: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  flex-direction: row;
}
.student .chatroom__remoteTracks .video:not(.main-video):not(.localVideo),
.guest .chatroom__remoteTracks .video:not(.main-video):not(.localVideo) {
  display: none;
}
.student .chatroom__remoteTracks .video.main-video,
.student .chatroom__remoteTracks .video.localVideo,
.guest .chatroom__remoteTracks .video.main-video,
.guest .chatroom__remoteTracks .video.localVideo {
  width: 50%;
  height: 100%;
}
.student .chatroom__remoteTracks.tracks_qty_1 .video,
.guest .chatroom__remoteTracks.tracks_qty_1 .video {
  width: 100%;
  height: 100%;
  border-color: #fff;
}
.student .chatroom__devicePicker .form__field-placeholder,
.guest .chatroom__devicePicker .form__field-placeholder {
  background: #fff !important;
}
.student .chatroom__title,
.guest .chatroom__title {
  max-width: 960px;
}
.student .isFullscreen [class$=__title],
.guest .isFullscreen [class$=__title] {
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
}

.admin .chatroom__chatHolder:not(.isFullscreen) {
  max-width: 800px;
}
.admin .chatroom .preloader {
  margin-top: 85px;
}
.admin .chatroom__box {
  margin-top: 85px;
}
.admin .chatroom__message-holder {
  margin-top: 85px;
}
.admin .chatroom__remoteTracks {
  position: absolute;
  left: 80px;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  flex-direction: row;
}
.admin .chatroom__remoteTracks .closeFullsizeVideo {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  color: #fff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: none;
}
.admin .chatroom__remoteTracks.hasFullsizeVideo .closeFullsizeVideo {
  display: block;
  cursor: pointer;
  transform: none;
  transition: transform 0.15s ease-in-out;
}
.admin .chatroom__remoteTracks.hasFullsizeVideo .closeFullsizeVideo:hover {
  transform: scale(1.15);
}
.admin .chatroom__remoteTracks .video {
  background: #f2f2f2;
  border: 1px solid #fff;
  box-sizing: border-box;
  width: 33.33333%;
  height: 33.33333%;
}
.admin .chatroom__remoteTracks .video:hover {
  border-color: #4ec1e2;
}
.admin .chatroom__remoteTracks .video.fullsizeVideo {
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  z-index: 1;
  border-color: #fff !important;
}
.admin .chatroom__remoteTracks.tracks_qty_1 .video {
  width: 100%;
  height: 100%;
  border-color: #fff;
}
.admin .chatroom__remoteTracks.tracks_qty_2 .video {
  width: 50%;
  height: 100%;
}
.admin .chatroom__remoteTracks.tracks_qty_3 .video, .admin .chatroom__remoteTracks.tracks_qty_4 .video {
  width: 50%;
  height: 50%;
}
.admin .chatroom__remoteTracks.tracks_qty_5 .video, .admin .chatroom__remoteTracks.tracks_qty_6 .video {
  width: 33.33333%;
  height: 50%;
}
.admin .chatroom__chatHolder.isFullscreen .video {
  width: 25%;
  height: 50%;
}
.admin .chatroom__chatHolder.isFullscreen .tracks_qty_1 .video {
  width: 100%;
  height: 100%;
}
.admin .chatroom__chatHolder.isFullscreen .tracks_qty_2 .video {
  width: 50%;
  height: 100%;
}
.admin .chatroom__chatHolder.isFullscreen .tracks_qty_3 .video,
.admin .chatroom__chatHolder.isFullscreen .tracks_qty_4 .video {
  width: 50%;
  height: 50%;
}
.admin .chatroom__chatHolder.isFullscreen .tracks_qty_5 .video,
.admin .chatroom__chatHolder.isFullscreen .tracks_qty_6 .video {
  width: 33.33333%;
  height: 50%;
}

.fixed .textTooltip__text {
  color: #fff;
}
.fixed .chatroom__chatHolder {
  margin-top: 40px;
}
.fixed .chatroom__users {
  display: none;
}
.fixed .chatroom__allow {
  color: #fff;
}
.fixed .chatroom__remoteTracks {
  left: 0;
}
.fixed .chatroom__devicePicker {
  display: none;
}
.fixed .isFullscreen .chatroom__remoteTracks {
  left: 80px;
}
.fixed .isFullscreen .chatroom__users {
  display: flex;
}

.isHidden {
  width: 180px !important;
  padding: 10px !important;
}
.isHidden .chatroom__btnsHolder {
  padding-top: 10px !important;
}
.isHidden .chatroom__btnsHolder .textTooltip__text {
  display: none;
}
.isHidden .chatroom__btnsHolder .btn {
  width: 20px;
  height: 20px;
  font-size: 14px;
  background: none;
}
.isHidden .chatroom__allow {
  display: none;
}
.isHidden .chatroom__remoteTracks .video {
  width: 100%;
  height: 141px;
}
.isHidden .chatroom__interactions-item .btn {
  color: #333;
}
.isHidden.isOrganizer {
  width: 270px !important;
}
.isHidden .btn__working {
  color: #00c020;
  position: static;
}
.isHidden .btn__working:before, .isHidden .btn__working:after {
  display: none;
}
.isHidden .btn__working:hover i {
  color: #00c020;
}

.isFullscreen {
  background: #333;
  margin-top: 0 !important;
}

@keyframes connectionLost {
  0% {
    width: 33.33333%;
  }
  100% {
    width: 100%;
  }
}