.textTooltip {
  position: relative;
  z-index: 1;
  display: flex;
}
.textTooltip__trigger:hover + .textTooltip__text {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}
.textTooltip__trigger:hover + .textTooltip__text.left {
  transform: translateX(0) translateY(-50%);
}
.textTooltip__trigger:hover + .textTooltip__text.right {
  transform: translateX(0) translateY(-50%);
}
.textTooltip__text {
  position: absolute;
  left: 50%;
  bottom: 100%;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  margin-bottom: 3px;
  white-space: nowrap;
  opacity: 0;
  transform: translateX(-50%) translateY(150%);
  transition: all 0.15s ease-in-out;
  z-index: -1;
}
.textTooltip__text.left {
  left: auto;
  right: 100%;
  bottom: auto;
  top: 50%;
  transform: translateX(150%) translateY(-50%);
  margin-bottom: 0;
  margin-right: 5px;
}
.textTooltip__text.right {
  left: 100%;
  bottom: auto;
  top: 50%;
  transform: translateX(-150%) translateY(-50%);
  margin-bottom: 0;
  margin-left: 5px;
}