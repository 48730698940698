.customSelect {
  position: relative;
}
.customSelect__value {
  cursor: pointer;
  padding-right: 34px;
  text-overflow: ellipsis;
}
.customSelect__value.isActive ~ [class$=__drop] {
  max-height: 200px;
  overflow: hidden;
}
.customSelect__value.isActive ~ [class$=__arrow] {
  transform: translateY(-50%) rotate(-180deg);
}
.customSelect__drop {
  position: absolute;
  z-index: 10;
  background: #fff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 0;
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
}
.customSelect__drop-opt {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.customSelect__drop-opt:hover {
  background: #f2f2f2;
}
.customSelect__drop-opt.isReset {
  color: #e32929;
}
.customSelect__arrow {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  transition: transform 0.25s ease-in-out;
  font-size: 12px;
}