@import '../../../assets/scss/utils/colors';
@import '../../../assets/scss/utils/sizes';
@import '../../../assets/scss/utils/fonts';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $baseColor;
    opacity: .8;
    z-index: -1;
  }
  &__inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex: 1;
  }
  &__box-wrapper {
    width: 100%;
    display: flex;
    min-height: min-content;
    justify-content: center;
    overflow: auto;
  }
  &__box-holder {
    width: 100%;
    text-align: center;
  }
  &__box {
    display: inline-block;
    vertical-align: top;
    width: 90%;
    max-width: 450px;
    position: relative;
    overflow: hidden;
    margin: 40px 20px;
    text-align: left;
    border-bottom: 20px solid transparent;
  }
  &__box-inner {
    border-radius: 4px;
    padding: $pageIndent / 2;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.15);
    background: $white;
  }
  &__close {
    position: absolute;
    right: $pageIndent / 2 - 5px;
    top: $pageIndent / 2 - 5px;
    padding: 5px;
    cursor: pointer;
    font-size: 20px;
    transform: rotate(0);
    transition: all .25s ease-in-out;
    z-index: 5;
    &:hover {
      color: $linkHoverColor;
      transform: rotate(90deg);
    }
  }
  &__heading {
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 20px;
    font-size: 20px;
    font-family: $RobotoCondensed;
    padding: 0 40px;
    line-height: 1.5;
    span {
      color: $greyColor;
      font-weight: 300;
    }
  }
  &__backToTop {
    position: fixed;
    right: $pageIndent;
    bottom: $pageIndent;
    z-index: 1;
    color: $white;
    font-size: 40px;
    cursor: pointer;
    transition: color .15s ease-in-out;
    &:hover {
      color: $linkHoverColor
    }
  }
}