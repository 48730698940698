.tabs__nav {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #ccc;
  padding: 0 10px 10px;
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.tabs__nav-item {
  cursor: pointer;
  margin-left: 20px;
  color: #333;
  transition: color 0.25s ease-in-out;
}
.tabs__nav-item:first-child {
  margin-left: 0;
}
.tabs__nav-item:hover {
  color: #ea5330;
}
.tabs__nav-item.active {
  color: #ea5330;
}
.tabs__contents-item {
  display: none;
}
.tabs__contents-item.active {
  display: block;
}