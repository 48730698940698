$gridIndent: 20px;

.grid {
  display: flex;
  margin: -$gridIndent;
  flex-wrap: wrap;
  &_col {
    padding: $gridIndent;
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 100%;
    &:empty {
      display: none;
    }
    &.col-1 {
      width: 8.33333%;
    }
    &.col-2 {
      width: 16.66666%;
    }
    &.col-3 {
      width: 25%;
    }
    &.col-4 {
      width: 33.33333%;
    }
    &.col-5 {
      width: 41.66666%;
    }
    &.col-6 {
      width: 50%;
    }
    &.col-7 {
      width: 58.33333%;
    }
    &.col-8 {
      width: 66.66666%;
    }
    &.col-9 {
      width: 75%;
    }
    &.col-10 {
      width: 83.33333%;
    }
    &.col-11 {
      width: 91.66666%;
    }
    @media screen and (min-width: $minTablet) {
      &.tablet-col-1 {
        width: 8.33333%;
      }
      &.tablet-col-2 {
        width: 16.66666%;
      }
      &.tablet-col-3 {
        width: 25%;
      }
      &.tablet-col-4 {
        width: 33.33333%;
      }
      &.tablet-col-5 {
        width: 41.66666%;
      }
      &.tablet-col-6 {
        width: 50%;
      }
      &.tablet-col-7 {
        width: 58.33333%;
      }
      &.tablet-col-8 {
        width: 66.66666%;
      }
      &.tablet-col-9 {
        width: 75%;
      }
      &.tablet-col-10 {
        width: 83.33333%;
      }
      &.tablet-col-11 {
        width: 91.66666%;
      }
    }
    @media screen and (min-width: $minDesktop) {
      &.desktop-col-1 {
        width: 8.33333%;
      }
      &.desktop-col-2 {
        width: 16.66666%;
      }
      &.desktop-col-3 {
        width: 25%;
      }
      &.desktop-col-4 {
        width: 33.33333%;
      }
      &.desktop-col-5 {
        width: 41.66666%;
      }
      &.desktop-col-6 {
        width: 50%;
      }
      &.desktop-col-7 {
        width: 58.33333%;
      }
      &.desktop-col-8 {
        width: 66.66666%;
      }
      &.desktop-col-9 {
        width: 75%;
      }
      &.desktop-col-10 {
        width: 83.33333%;
      }
      &.desktop-col-11 {
        width: 91.66666%;
      }
    }
  }
}