@import '../../assets/scss/utils/colors';
@import '../../assets/scss/utils/sizes';

$filtersIndent: 20px;

.filters {
  padding: $filtersIndent $pageIndent;
  background: $pageColor;
  position: fixed;
  z-index: 10;
  left: $navWidth;
  top: $headerHeight + $sectionTitleHeight;
  right: 0;
  &__heading {
    margin-right: 20px;
  }
  &__body {
    max-width: 1500px;
    display: flex;
    align-items: center;
  }
  &__inner {
    display: flex;
    align-items: center;
    margin: 0 #{-$filtersIndent};
    width: 100%;
  }
  &__item {
    width: 100%;
  }
  &__searchQuery {
    max-width: 340px;
  }
  .form {
    box-sizing: border-box;
    display: flex;
    margin-top: -20px;
    &__row {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
    }
    &__field {
      &-placeholder {
        background: $pageColor !important;
      }
      &:focus:not(.readonly) {
        ~ .form__field-placeholder {
          background: $pageColor;
        }
      }
    }
    &__field-placeholder {
      &.isFilled {
        background: $pageColor;
      }
    }
    &__radio-holder {
      padding: 0;
    }
  }
  &__showPerPage {
    max-width: 120px;
  }
  &__showOnlyMy {
    .check {
      border: 1px solid $mediumGreyColor;
    }
    .form__checkbox.checked,
    .form__checkbox:checked {
      + label .check {
        border-color: $greenColor;
      }
    }
  }
  &__filterByDate {
    width: 300px;
    .form__row {
      margin-top: 20px !important;
    }
  }
}