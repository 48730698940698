.filters {
  padding: 20px 40px;
  background: #eee;
  position: fixed;
  z-index: 10;
  left: 200px;
  top: 197px;
  right: 0;
}
.filters__heading {
  margin-right: 20px;
}
.filters__body {
  max-width: 1500px;
  display: flex;
  align-items: center;
}
.filters__inner {
  display: flex;
  align-items: center;
  margin: 0 -20px;
  width: 100%;
}
.filters__item {
  width: 100%;
}
.filters__searchQuery {
  max-width: 340px;
}
.filters .form {
  box-sizing: border-box;
  display: flex;
  margin-top: -20px;
}
.filters .form__row {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.filters .form__field-placeholder {
  background: #eee !important;
}
.filters .form__field:focus:not(.readonly) ~ .form__field-placeholder {
  background: #eee;
}
.filters .form__field-placeholder.isFilled {
  background: #eee;
}
.filters .form__radio-holder {
  padding: 0;
}
.filters__showPerPage {
  max-width: 120px;
}
.filters__showOnlyMy .check {
  border: 1px solid #ccc;
}
.filters__showOnlyMy .form__checkbox.checked + label .check,
.filters__showOnlyMy .form__checkbox:checked + label .check {
  border-color: #a1d042;
}
.filters__filterByDate {
  width: 300px;
}
.filters__filterByDate .form__row {
  margin-top: 20px !important;
}