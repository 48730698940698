@import '../../assets/scss/utils/colors';
@import '../../assets/scss/utils/fonts';
@import '../../assets/scss/utils/sizes';

.studentHeader {
  display: flex;
  position: fixed;
  background: $white;
  left: 0;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 20px $pageIndent;
  height: $studentHeaderHeight;
  z-index: 10;
  transform: translateZ(0);
  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: $studentHeaderHeight - 10;
    img {
      max-height: 100%;
    }
  }
  .preloader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    &:before {
      content: '';
      background: $white;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: .75;
    }
  }
  &__burger {
    transform: translateZ(0);
    position: relative;
    width: 20px;
    height: 14px;
    span {
      height: 2px;
      background: $baseColor;
      position: absolute;
      transform: rotate(0);
      width: 100%;
      left: 0;
      transition: all .25s ease-in-out;
      &:nth-child(2) {
        top: 5px;
      }
      &:nth-child(3) {
        top: 10px;
      }
    }
    &.active {
      span {
        top: 5px;
        &:first-child {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }
  @media screen and (max-width: $maxTablet) {
    height: $studentHeaderHeightMobile;
    justify-content: space-between;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.25);
    &__logo {
      height: $studentHeaderHeightMobile;
    }
  }
  @media screen and (max-width: $maxMobile) {
    padding: $studentHeaderIndent $pageIndentMobile;
    height: calc(#{$studentHeaderHeightTiny} + #{$studentHeaderIndent * 2});
    min-height: calc(#{$studentHeaderHeightTiny} + #{$studentHeaderIndent * 2});
    position: sticky;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    &__logo {
      height: $studentHeaderHeightTiny + 10px;
    }
    .header__divider {
      margin: 0 15px;
    }
  }
}