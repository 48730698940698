.libraryPicker__add-btn {
  color: #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: #fafafa;
  padding: 10px;
  transition: background 0.25s ease-in-out;
}
.libraryPicker__add-btn:hover {
  background: #d7f4fc;
  color: #4ec1e2;
}
.libraryPicker__placeholder {
  margin-left: 10px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.libraryPicker__list {
  margin: -5px -20px 20px;
}
.libraryPicker__selectedList {
  margin: -5px 0 -20px;
}
.libraryPicker__selectedList + [class$=__add] {
  margin-top: 20px;
}
.libraryPicker__list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
}
.libraryPicker__list-item:hover {
  background: #d7f4fc;
}
.libraryPicker__list-item.selected {
  background: #a1d042;
}
.libraryPicker__list-item.selectedUserItem {
  background: transparent;
  cursor: initial;
  padding: 10px 20px;
}
.libraryPicker__list-item-avatar {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-size: cover;
  background-position: 50% 50%;
  flex: 0 0 auto;
}
.libraryPicker__list-item-avatar i {
  font-size: 14px;
  color: #ccc;
}
.libraryPicker__list-item-name {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.libraryPicker__list-item-check {
  margin-right: 10px;
}
.libraryPicker__list-item-check.selected {
  color: #fff;
}
.libraryPicker__list-btn {
  display: flex;
  justify-content: center;
}
.libraryPicker__list-btn .btn {
  cursor: pointer;
}
.libraryPicker__list-btn .btn[disabled] {
  cursor: not-allowed;
}
.libraryPicker__list-item-remove {
  cursor: pointer;
}
.libraryPicker__list-item-remove:hover {
  color: #e32929;
}
.libraryPicker .filters {
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  background: none;
  padding: 0 20px 10px;
  margin: 0 -20px 25px;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.05);
}
.libraryPicker .filters__heading {
  display: none;
}
.libraryPicker .filters__inner {
  margin: 0;
}
.libraryPicker .filters__searchQuery {
  max-width: 100%;
}
.libraryPicker .filters .form {
  margin: 0;
  min-height: 0;
}
.libraryPicker .filters .form__row {
  margin: 0;
  padding: 0;
}
.libraryPicker .filters .form__field-placeholder {
  background: #fff !important;
}
.libraryPicker .nothingFound:not(:first-child:last-child) {
  padding: 22px 0;
  margin-bottom: -20px;
  overflow: hidden;
}