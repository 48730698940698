.dropdown {
  position: relative;
}
.dropdown_block {
  position: absolute;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin-top: 10px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
.dropdown_block:before {
  content: "";
  border-bottom: 5px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}