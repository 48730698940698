@import '../../assets/scss/utils/colors';
@import '../../assets/scss/utils/sizes';
@import '../../assets/scss/utils/fonts';

@keyframes showQuickCall {
  0% {
    transform: translateY(calc(100% + 20px));
  }
  100% {
    transform: translateY(0);
  }
}

.jitsi-container {
  position: relative;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.quickCall {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999999;
  animation: showQuickCall linear .15s;
  width: 300px;
  audio {
    opacity: 0;
    position: fixed;
    left: -9999px;
    top: -9999px;
  }
  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $baseColor;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.5);
  }
  &__name {
    font-size: 20px;
    margin-bottom: 20px;
    height: 20px;
    font-family: $RobotoCondensed;
    letter-spacing: .5px;
    color: $white;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0,0,0,.5);
  }
  &__title {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: $RobotoCondensed;
    letter-spacing: .5px;
    color: $white;
    text-shadow: 2px 2px 5px rgba(0,0,0,.5);
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    line-height: 1.25;
  }
  &__avatar {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 100%;
    box-shadow: inset -10px -10px 20px 0 rgba(0,0,0,.75), 2px 2px 5px 0 rgba(0,0,0,.5);
    border: 2px solid white;
  }
  .btn {
    position: relative;
    display: block;
    margin: 0;
    font-size: 25vmin;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    box-shadow: 0 0 0 0 rgba($successColor, 0), 0 0.05em 0.1em rgba($successColor, 0.2);
    transform: translate3d(0, 0, 0) scale(1);
    animation: phone-outer 4050ms infinite;
    &.btn__before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#fff, 0.1);
      border-radius: 100%;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0);
      animation: phone-inner 4050ms infinite;
    }

    i {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      animation: phone-icon 4050ms infinite;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $white;
      border-radius: 100%;
      box-shadow: 2px 2px 5px 0 rgba(0,0,0,.25);
    }
  }
}

@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow:
            0 0 0 0 rgba($successColor, 0),
            0 0.05em 0.1em rgba($successColor, 0.2);
  }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow:
            0 0 0 0em rgba($successColor, 0.1),
            0em 0.05em 0.1em rgba($successColor, 0.5);
  }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow:
            0 0 0 0.5em rgba($successColor, 0),
            0em 0.05em 0.1em rgba($successColor, 0.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow:
            0 0 0 0em rgba($successColor, 0),
            0em 0.05em 0.1em rgba($successColor, 0.2);
  }
}

@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
  }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9);
  }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
}

@keyframes phone-icon {
  0% { transform: translate3d(0em, 0, 0); }
  2% { transform: translate3d(0.05em, 0, 0); }
  4% { transform: translate3d(-0.05em, 0, 0); }
  6% { transform: translate3d(0.05em, 0, 0); }
  8% { transform: translate3d(-0.05em, 0, 0); }
  10% { transform: translate3d(0.05em, 0, 0); }
  12% { transform: translate3d(-0.05em, 0, 0); }
  14% { transform: translate3d(0.05em, 0, 0); }
  16% { transform: translate3d(-0.05em, 0, 0); }
  18% { transform: translate3d(0.05em, 0, 0); }
  20% { transform: translate3d(-0.05em, 0, 0); }
  22% { transform: translate3d(0.05em, 0, 0); }
  24% { transform: translate3d(-0.05em, 0, 0); }
  26% { transform: translate3d(0.05em, 0, 0); }
  28% { transform: translate3d(-0.05em, 0, 0); }
  30% { transform: translate3d(0.05em, 0, 0); }
  32% { transform: translate3d(-0.05em, 0, 0); }
  34% { transform: translate3d(0.05em, 0, 0); }
  36% { transform: translate3d(-0.05em, 0, 0); }
  38% { transform: translate3d(0.05em, 0, 0); }
  40% { transform: translate3d(-0.05em, 0, 0); }
  42% { transform: translate3d(0.05em, 0, 0); }
  44% { transform: translate3d(-0.05em, 0, 0); }
  46% { transform: translate3d(0em, 0, 0); }
}
