@charset "UTF-8";
[class*=imageFilter] {
  position: relative;
}

[class*=imageFilter]::before {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.imageFilter-1977 {
  -webkit-filter: sepia(0.5) hue-rotate(-30deg) saturate(1.4);
  filter: sepia(0.5) hue-rotate(-30deg) saturate(1.4);
}

.imageFilter-aden {
  -webkit-filter: sepia(0.2) brightness(1.15) saturate(1.4);
  filter: sepia(0.2) brightness(1.15) saturate(1.4);
}

.imageFilter-aden::before {
  background: rgba(125, 105, 24, 0.1);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-amaro {
  -webkit-filter: sepia(0.35) contrast(1.1) brightness(1.2) saturate(1.3);
  filter: sepia(0.35) contrast(1.1) brightness(1.2) saturate(1.3);
}

.imageFilter-amaro::before {
  background: rgba(125, 105, 24, 0.2);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-ashby {
  -webkit-filter: sepia(0.5) contrast(1.2) saturate(1.8);
  filter: sepia(0.5) contrast(1.2) saturate(1.8);
}

.imageFilter-ashby::before {
  background: rgba(125, 105, 24, 0.35);
  content: "";
  mix-blend-mode: lighten;
}

.imageFilter-brannan {
  -webkit-filter: sepia(0.4) contrast(1.25) brightness(1.1) saturate(0.9) hue-rotate(-2deg);
  filter: sepia(0.4) contrast(1.25) brightness(1.1) saturate(0.9) hue-rotate(-2deg);
}

.imageFilter-brooklyn {
  -webkit-filter: sepia(0.25) contrast(1.25) brightness(1.25) hue-rotate(5deg);
  filter: sepia(0.25) contrast(1.25) brightness(1.25) hue-rotate(5deg);
}

.imageFilter-brooklyn::before {
  background: rgba(127, 187, 227, 0.2);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-charmes {
  -webkit-filter: sepia(0.25) contrast(1.25) brightness(1.25) saturate(1.35) hue-rotate(-5deg);
  filter: sepia(0.25) contrast(1.25) brightness(1.25) saturate(1.35) hue-rotate(-5deg);
}

.imageFilter-charmes::before {
  background: rgba(125, 105, 24, 0.25);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-clarendon {
  -webkit-filter: sepia(0.15) contrast(1.25) brightness(1.25) hue-rotate(5deg);
  filter: sepia(0.15) contrast(1.25) brightness(1.25) hue-rotate(5deg);
}

.imageFilter-clarendon::before {
  background: rgba(127, 187, 227, 0.4);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-crema {
  -webkit-filter: sepia(0.5) contrast(1.25) brightness(1.15) saturate(0.9) hue-rotate(-2deg);
  filter: sepia(0.5) contrast(1.25) brightness(1.15) saturate(0.9) hue-rotate(-2deg);
}

.imageFilter-crema::before {
  background: rgba(125, 105, 24, 0.2);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-dogpatch {
  -webkit-filter: sepia(0.35) saturate(1.1) contrast(1.5);
  filter: sepia(0.35) saturate(1.1) contrast(1.5);
}

.imageFilter-earlybird {
  -webkit-filter: sepia(0.25) contrast(1.25) brightness(1.15) saturate(0.9) hue-rotate(-5deg);
  filter: sepia(0.25) contrast(1.25) brightness(1.15) saturate(0.9) hue-rotate(-5deg);
}

.imageFilter-earlybird::before {
  background: radial-gradient(circle closest-corner, transparent 0, rgba(125, 105, 24, 0.2) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 0, rgba(125, 105, 24, 0.2) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 0, rgba(125, 105, 24, 0.2) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 0, rgba(125, 105, 24, 0.2) 100%);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-gingham {
  -webkit-filter: contrast(1.1) brightness(1.1);
  filter: contrast(1.1) brightness(1.1);
}

.imageFilter-gingham::before {
  background: #e6e6e6;
  content: "";
  mix-blend-mode: soft-light;
}

.imageFilter-ginza {
  -webkit-filter: sepia(0.25) contrast(1.15) brightness(1.2) saturate(1.35) hue-rotate(-5deg);
  filter: sepia(0.25) contrast(1.15) brightness(1.2) saturate(1.35) hue-rotate(-5deg);
}

.imageFilter-ginza::before {
  background: rgba(125, 105, 24, 0.15);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-hefe {
  -webkit-filter: sepia(0.4) contrast(1.5) brightness(1.2) saturate(1.4) hue-rotate(-10deg);
  filter: sepia(0.4) contrast(1.5) brightness(1.2) saturate(1.4) hue-rotate(-10deg);
}

.imageFilter-hefe::before {
  background: radial-gradient(circle closest-corner, transparent 0, rgba(0, 0, 0, 0.25) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 0, rgba(0, 0, 0, 0.25) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 0, rgba(0, 0, 0, 0.25) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 0, rgba(0, 0, 0, 0.25) 100%);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-helena {
  -webkit-filter: sepia(0.5) contrast(1.05) brightness(1.05) saturate(1.35);
  filter: sepia(0.5) contrast(1.05) brightness(1.05) saturate(1.35);
}

.imageFilter-helena::before {
  background: rgba(158, 175, 30, 0.25);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-hudson {
  -webkit-filter: sepia(0.25) contrast(1.2) brightness(1.2) saturate(1.05) hue-rotate(-15deg);
  filter: sepia(0.25) contrast(1.2) brightness(1.2) saturate(1.05) hue-rotate(-15deg);
}

.imageFilter-hudson::before {
  background: radial-gradient(circle closest-corner, transparent 25%, rgba(25, 62, 167, 0.25) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 25%, rgba(25, 62, 167, 0.25) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 25%, rgba(25, 62, 167, 0.25) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 25%, rgba(25, 62, 167, 0.25) 100%);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-inkwell {
  -webkit-filter: brightness(1.25) contrast(0.85) grayscale(1);
  filter: brightness(1.25) contrast(0.85) grayscale(1);
}

.imageFilter-juno {
  -webkit-filter: sepia(0.35) contrast(1.15) brightness(1.15) saturate(1.8);
  filter: sepia(0.35) contrast(1.15) brightness(1.15) saturate(1.8);
}

.imageFilter-juno::before {
  background: rgba(127, 187, 227, 0.2);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-kelvin {
  -webkit-filter: sepia(0.15) contrast(1.5) brightness(1.1) hue-rotate(-10deg);
  filter: sepia(0.15) contrast(1.5) brightness(1.1) hue-rotate(-10deg);
}

.imageFilter-kelvin::before {
  background: radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.25) 0, rgba(128, 78, 15, 0.5) 100%);
  background: -o-radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.25) 0, rgba(128, 78, 15, 0.5) 100%);
  background: -moz-radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.25) 0, rgba(128, 78, 15, 0.5) 100%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.25) 0, rgba(128, 78, 15, 0.5) 100%);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-lark {
  -webkit-filter: sepia(0.25) contrast(1.2) brightness(1.3) saturate(1.25);
  filter: sepia(0.25) contrast(1.2) brightness(1.3) saturate(1.25);
}

.imageFilter-lofi {
  -webkit-filter: saturate(1.1) contrast(1.5);
  filter: saturate(1.1) contrast(1.5);
}

.imageFilter-ludwig {
  -webkit-filter: sepia(0.25) contrast(1.05) brightness(1.05) saturate(2);
  filter: sepia(0.25) contrast(1.05) brightness(1.05) saturate(2);
}

.imageFilter-ludwig::before {
  background: rgba(125, 105, 24, 0.1);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-maven {
  -webkit-filter: sepia(0.35) contrast(1.05) brightness(1.05) saturate(1.75);
  filter: sepia(0.35) contrast(1.05) brightness(1.05) saturate(1.75);
}

.imageFilter-maven::before {
  background: rgba(158, 175, 30, 0.25);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-mayfair {
  -webkit-filter: contrast(1.1) brightness(1.15) saturate(1.1);
  filter: contrast(1.1) brightness(1.15) saturate(1.1);
}

.imageFilter-mayfair::before {
  background: radial-gradient(circle closest-corner, transparent 0, rgba(175, 105, 24, 0.4) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 0, rgba(175, 105, 24, 0.4) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 0, rgba(175, 105, 24, 0.4) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 0, rgba(175, 105, 24, 0.4) 100%);
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-moon {
  -webkit-filter: brightness(1.4) contrast(0.95) saturate(0) sepia(0.35);
  filter: brightness(1.4) contrast(0.95) saturate(0) sepia(0.35);
}

.imageFilter-nashville {
  -webkit-filter: sepia(0.25) contrast(1.5) brightness(0.9) hue-rotate(-15deg);
  filter: sepia(0.25) contrast(1.5) brightness(0.9) hue-rotate(-15deg);
}

.imageFilter-nashville::before {
  background: radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.5) 0, rgba(128, 78, 15, 0.65) 100%);
  background: -o-radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.5) 0, rgba(128, 78, 15, 0.65) 100%);
  background: -moz-radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.5) 0, rgba(128, 78, 15, 0.65) 100%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.5) 0, rgba(128, 78, 15, 0.65) 100%);
  content: "";
  mix-blend-mode: screen;
}

.imageFilter-perpetua {
  -webkit-filter: contrast(1.1) brightness(1.25) saturate(1.1);
  filter: contrast(1.1) brightness(1.25) saturate(1.1);
}

.imageFilter-perpetua::before {
  background: linear-gradient(to bottom, rgba(0, 91, 154, 0.25), rgba(230, 193, 61, 0.25));
  background: -o-linear-gradient(top, rgba(0, 91, 154, 0.25), rgba(230, 193, 61, 0.25));
  background: -moz-linear-gradient(top, rgba(0, 91, 154, 0.25), rgba(230, 193, 61, 0.25));
  background: -webkit-linear-gradient(top, rgba(0, 91, 154, 0.25), rgba(230, 193, 61, 0.25));
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 91, 154, 0.25)), to(rgba(230, 193, 61, 0.25)));
  content: "";
  mix-blend-mode: multiply;
}

.imageFilter-poprocket {
  -webkit-filter: sepia(0.15) brightness(1.2);
  filter: sepia(0.15) brightness(1.2);
}

.imageFilter-poprocket::before {
  background: radial-gradient(circle closest-corner, rgba(206, 39, 70, 0.75) 40%, black 80%);
  background: -o-radial-gradient(circle closest-corner, rgba(206, 39, 70, 0.75) 40%, black 80%);
  background: -moz-radial-gradient(circle closest-corner, rgba(206, 39, 70, 0.75) 40%, black 80%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(206, 39, 70, 0.75) 40%, black 80%);
  content: "";
  mix-blend-mode: screen;
}

.imageFilter-reyes {
  -webkit-filter: sepia(0.75) contrast(0.75) brightness(1.25) saturate(1.4);
  filter: sepia(0.75) contrast(0.75) brightness(1.25) saturate(1.4);
}

.imageFilter-rise {
  -webkit-filter: sepia(0.25) contrast(1.25) brightness(1.2) saturate(0.9);
  filter: sepia(0.25) contrast(1.25) brightness(1.2) saturate(0.9);
}

.imageFilter-rise::before {
  background: radial-gradient(circle closest-corner, transparent 0, rgba(230, 193, 61, 0.25) 100%);
  background: -o-radial-gradient(circle closest-corner, transparent 0, rgba(230, 193, 61, 0.25) 100%);
  background: -moz-radial-gradient(circle closest-corner, transparent 0, rgba(230, 193, 61, 0.25) 100%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 0, rgba(230, 193, 61, 0.25) 100%);
  content: "";
  mix-blend-mode: lighten;
}

.imageFilter-sierra {
  -webkit-filter: sepia(0.25) contrast(1.5) brightness(0.9) hue-rotate(-15deg);
  filter: sepia(0.25) contrast(1.5) brightness(0.9) hue-rotate(-15deg);
}

.imageFilter-sierra::before {
  background: radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.5) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -o-radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.5) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -moz-radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.5) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(128, 78, 15, 0.5) 0, rgba(0, 0, 0, 0.65) 100%);
  content: "";
  mix-blend-mode: screen;
}

.imageFilter-skyline {
  -webkit-filter: sepia(0.15) contrast(1.25) brightness(1.25) saturate(1.2);
  filter: sepia(0.15) contrast(1.25) brightness(1.25) saturate(1.2);
}

.imageFilter-slumber {
  -webkit-filter: sepia(0.35) contrast(1.25) saturate(1.25);
  filter: sepia(0.35) contrast(1.25) saturate(1.25);
}

.imageFilter-slumber::before {
  background: rgba(125, 105, 24, 0.2);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-stinson {
  -webkit-filter: sepia(0.35) contrast(1.25) brightness(1.1) saturate(1.25);
  filter: sepia(0.35) contrast(1.25) brightness(1.1) saturate(1.25);
}

.imageFilter-stinson::before {
  background: rgba(125, 105, 24, 0.45);
  content: "";
  mix-blend-mode: lighten;
}

.imageFilter-sutro {
  -webkit-filter: sepia(0.4) contrast(1.2) brightness(0.9) saturate(1.4) hue-rotate(-10deg);
  filter: sepia(0.4) contrast(1.2) brightness(0.9) saturate(1.4) hue-rotate(-10deg);
}

.imageFilter-sutro::before {
  background: radial-gradient(circle closest-corner, transparent 50%, rgba(0, 0, 0, 0.5) 90%);
  background: -o-radial-gradient(circle closest-corner, transparent 50%, rgba(0, 0, 0, 0.5) 90%);
  background: -moz-radial-gradient(circle closest-corner, transparent 50%, rgba(0, 0, 0, 0.5) 90%);
  background: -webkit-radial-gradient(circle closest-corner, transparent 50%, rgba(0, 0, 0, 0.5) 90%);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-toaster {
  -webkit-filter: sepia(0.25) contrast(1.5) brightness(0.95) hue-rotate(-15deg);
  filter: sepia(0.25) contrast(1.5) brightness(0.95) hue-rotate(-15deg);
}

.imageFilter-toaster::before {
  background: radial-gradient(circle, #804e0f, rgba(0, 0, 0, 0.25));
  background: -o-radial-gradient(circle, #804e0f, rgba(0, 0, 0, 0.25));
  background: -moz-radial-gradient(circle, #804e0f, rgba(0, 0, 0, 0.25));
  background: -webkit-radial-gradient(circle, #804e0f, rgba(0, 0, 0, 0.25));
  content: "";
  mix-blend-mode: screen;
}

.imageFilter-valencia {
  -webkit-filter: sepia(0.25) contrast(1.1) brightness(1.1);
  filter: sepia(0.25) contrast(1.1) brightness(1.1);
}

.imageFilter-valencia::before {
  background: rgba(230, 193, 61, 0.1);
  content: "";
  mix-blend-mode: lighten;
}

.imageFilter-vesper {
  -webkit-filter: sepia(0.35) contrast(1.15) brightness(1.2) saturate(1.3);
  filter: sepia(0.35) contrast(1.15) brightness(1.2) saturate(1.3);
}

.imageFilter-vesper::before {
  background: rgba(125, 105, 24, 0.25);
  content: "";
  mix-blend-mode: overlay;
}

.imageFilter-walden {
  -webkit-filter: sepia(0.35) contrast(0.8) brightness(1.25) saturate(1.4);
  filter: sepia(0.35) contrast(0.8) brightness(1.25) saturate(1.4);
}

.imageFilter-walden::before {
  background: rgba(229, 240, 128, 0.5);
  content: "";
  mix-blend-mode: darken;
}

.imageFilter-willow {
  -webkit-filter: brightness(1.2) contrast(0.85) saturate(0.05) sepia(0.2);
  filter: brightness(1.2) contrast(0.85) saturate(0.05) sepia(0.2);
}

.imageFilter-xpro-ii {
  -webkit-filter: sepia(0.45) contrast(1.25) brightness(1.75) saturate(1.3) hue-rotate(-5deg);
  filter: sepia(0.45) contrast(1.25) brightness(1.75) saturate(1.3) hue-rotate(-5deg);
}

.imageFilter-xpro-ii::before {
  background: radial-gradient(circle closest-corner, rgba(0, 91, 154, 0.35) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -o-radial-gradient(circle closest-corner, rgba(0, 91, 154, 0.35) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -moz-radial-gradient(circle closest-corner, rgba(0, 91, 154, 0.35) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-radial-gradient(circle closest-corner, rgba(0, 91, 154, 0.35) 0, rgba(0, 0, 0, 0.65) 100%);
  content: "";
  mix-blend-mode: multiply;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
body.overflow {
  overflow: hidden;
}
body.overflow .page {
  overflow: hidden;
}

a {
  color: #4ec1e2;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}
a:hover {
  color: #ea5330;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@keyframes animateBG {
  0% {
    filter: grayscale(1);
    opacity: 0.25;
  }
  50% {
    filter: grayscale(0);
    opacity: 1;
  }
  100% {
    filter: grayscale(1);
    opacity: 0.25;
  }
}
.page {
  padding: 152px 40px 40px 240px;
  background: #eee;
  min-height: calc(100vh - (112px + 40px * 2));
  position: relative;
}
.page .pageBg {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: 850px;
  background-position: 50% 140px;
  opacity: 0.25;
  filter: grayscale(1);
  background-repeat: repeat-x;
}
.page .pageBg.animate {
  animation: animateBG linear 5s infinite;
}
.page__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 80px;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 769px) {
  .page:after {
    content: "Контент недоступний для мобільних пристроїв";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    line-height: 1.5;
  }
  .page__inner {
    flex-direction: column;
    height: 100%;
    padding-bottom: 0;
  }
}
.page.student {
  padding: calc(60px + 40px + 40px) 40px 0;
  min-height: calc(100vh - (60px + 40px + 40px));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 1025px) {
  .page.student {
    padding: 0;
    min-height: 0;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .page.student:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #333;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    visibility: hidden;
    z-index: 1;
  }
  .page.navVisible:after {
    visibility: visible;
    left: 200px;
    opacity: 0.85;
  }
  .page.navVisible .content {
    margin-left: 200px;
    filter: blur(2px);
  }
  .page.navVisible .footer {
    margin-left: 200px;
    filter: blur(2px);
  }
  .page.navVisible .studentNav {
    left: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  }
}
@media screen and (max-width: 769px) {
  .page {
    background: #fff;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-self: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.loader:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.75;
  z-index: -1;
}

.nothingFound {
  text-align: center;
  color: #e32929;
  font-weight: bold;
  padding: 40px 0;
}

.pageNotFound {
  height: calc(100vh - 112px - 80px);
  display: flex;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: -99999px;
  top: -99999px;
  font-size: 24px;
  text-transform: uppercase;
  color: #999;
  font-weight: bold;
  padding-top: 40px;
  box-sizing: border-box;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}

.section__title-holder ~ .widget {
  margin-top: 85px !important;
}
.section__title-holder ~ .grid {
  margin-top: 65px !important;
}
.section__title-holder + .filters ~ .widget {
  margin-top: 165px !important;
}
.section__title-holder + .filters ~ .grid {
  margin-top: 165px !important;
}

.color-warning {
  color: #ffbb33;
}

.laptop-hide {
  opacity: 1;
  visibility: visible;
}
@media screen and (min-width: 1026px) {
  .laptop-hide {
    opacity: 0;
    visibility: hidden;
  }
}

.laptop-show {
  opacity: 0;
  visibility: hidden;
}
@media screen and (min-width: 1026px) {
  .laptop-show {
    opacity: 1;
    visibility: visible;
  }
}

.isNavCollapsed .main--nav {
  width: 60px;
}
.isNavCollapsed .main--nav [class$=_text] {
  display: none;
}
.isNavCollapsed .main--nav i {
  margin-right: 0;
}
.isNavCollapsed .page {
  padding-left: 100px;
}
.isNavCollapsed .header {
  left: 60px;
}
.isNavCollapsed .section__title-holder {
  left: 60px;
}
.isNavCollapsed .filters {
  left: 60px;
}

.tox-statusbar {
  display: none !important;
}

.tinymcePreloader {
  display: none;
  z-index: -99999;
}

.MathJax {
  font-size: 20px !important;
}
.MathJax .math > * > * {
  position: static !important;
}
.MathJax .mspace {
  display: block !important;
  height: 60px !important;
}

#react-mathjax-preview h1,
#react-mathjax-preview h2,
#react-mathjax-preview h3,
#react-mathjax-preview h4,
#react-mathjax-preview h5,
#react-mathjax-preview h6 {
  margin-bottom: 20px;
}
#react-mathjax-preview h1 {
  font-size: 24px;
}
#react-mathjax-preview h2 {
  font-size: 22px;
}
#react-mathjax-preview h3 {
  font-size: 20px;
}
#react-mathjax-preview h4 {
  font-size: 18px;
}
#react-mathjax-preview h5 {
  font-size: 16px;
}
#react-mathjax-preview h6 {
  font-size: 14px;
}
#react-mathjax-preview p {
  margin-bottom: 10px;
}
#react-mathjax-preview ul li {
  margin-bottom: 10px;
  line-height: 1;
  position: relative;
  padding-left: 15px;
}
#react-mathjax-preview ul li:before {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
}
#react-mathjax-preview ol {
  counter-reset: section;
}
#react-mathjax-preview ol li {
  margin-bottom: 10px;
  line-height: 1;
  position: relative;
  padding-left: 15px;
}
#react-mathjax-preview ol li:before {
  counter-increment: section;
  content: counter(section) ". ";
  position: absolute;
  left: 0;
  top: 0;
}
#react-mathjax-preview table {
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
}
#react-mathjax-preview table td {
  padding: 5px 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
}
#react-mathjax-preview table td * {
  margin: 0;
}

.content {
  width: calc(100% - 200px - 40px);
  background: #fff;
  padding: 40px;
  box-sizing: border-box;
  position: relative;
}
.content__title-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  margin: -40px -40px 40px;
  background: #333;
  color: #fff;
  height: 85px;
  box-sizing: border-box;
  position: sticky;
  top: 100px;
  z-index: 2;
}
.content__title-holder .preloader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content__title-holder .preloader:before {
  content: "";
  background: #333;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.75;
}
.content__title {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 20px;
  display: flex;
  align-items: center;
  max-width: 80%;
}
.content__title-inner {
  display: flex;
  flex-wrap: wrap;
}
.content__title-subtitle {
  color: #999;
  font-size: 14px;
  width: 100%;
  flex: 0 0 auto;
}
.content__title-subtitle:after {
  content: "/";
  margin: 0 5px;
}
.content__title-actions {
  white-space: nowrap;
}
.content .notification {
  margin-bottom: 40px;
}
.content_title {
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  margin-bottom: 40px;
}
.content_title-icon {
  margin-right: 10px;
}
@media screen and (max-width: 1025px) {
  .content {
    transform: translateX(0);
    width: 100%;
    min-width: 100%;
    background: inherit;
    transition: all 0.25s ease-in-out;
    position: relative;
    z-index: 1;
    margin: 0;
    padding-top: calc(40px + 40px + 40px);
    flex: 1;
  }
  .content__inner {
    background: #fff;
    padding: 40px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 769px) {
  .content {
    padding: 0;
  }
  .content__title-holder {
    padding: 0 20px;
    margin: -20px -20px 20px;
    height: 60px;
    position: sticky;
    top: calc(20px + 40px);
    z-index: 10;
  }
  .content__title {
    font-size: 16px;
  }
  .content__inner {
    margin-bottom: 0;
    padding: 20px;
  }
}

.section {
  margin-bottom: 40px;
}
.section:last-of-type {
  margin-bottom: 0;
}
.section__title-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;
  padding: 20px 40px;
  min-height: 85px;
  box-sizing: border-box;
  position: fixed;
  left: 200px;
  top: 112px;
  right: 0;
  z-index: 10;
}
.section__title-holder [class$=__title] {
  margin-bottom: 0;
}
.section__title-holder .preloader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section__title-holder .preloader:before {
  content: "";
  background: #333;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.75;
}
.section__title {
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  color: #fff;
}
.section__title-actions {
  white-space: nowrap;
}
.section__title-actions .btn {
  margin-left: 20px;
}
.section__title-separator {
  color: #999;
}
.section__title-separator:after {
  content: "/";
  margin: 0 5px;
}
.section__actions {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.section__data {
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.btn {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 15px 20px;
  border-radius: 25px;
  min-width: 100px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}
.btn_primary {
  background: #4ec1e2;
  color: #fff;
}
.btn_primary:hover {
  color: #fff;
}
.btn__pale {
  background: #ccc;
}
.btn__error {
  background: #e32929;
  color: #fff;
}
.btn__error:hover {
  color: #fff;
}
.btn__error.btn__working:after {
  border-top-color: #e32929;
}
.btn__success {
  background: #00c020;
  color: #fff;
}
.btn__success:hover {
  color: #fff;
}
.btn__warning {
  background: #ffbb33;
  color: #fff;
}
.btn__warning:hover {
  color: #fff;
}
.btn__orange {
  background: #ff8a00;
  color: #fff;
}
.btn__orange:hover {
  color: #fff;
}
.btn__purple {
  background: #7f00a3;
  color: #fff;
}
.btn__purple:hover {
  color: #fff;
}
.btn__pink {
  background: #00c020;
  color: #fff;
}
.btn__pink:hover {
  color: #fff;
}
.btn__dark {
  background: #333;
  color: #fff;
}
.btn__dark:hover {
  color: #fff;
}
.btn__light {
  background: #fff;
  color: #333;
}
.btn__light:hover {
  color: #333;
}
.btn:hover {
  opacity: 0.75;
}
.btn:hover i {
  color: #fff;
}
.btn[disabled] {
  cursor: not-allowed !important;
  filter: grayscale(100%);
  opacity: 0.5;
}
.btn.inactive {
  filter: grayscale(100%);
  opacity: 0.5;
}
.btn.round {
  min-width: 0;
  width: 50px;
  height: 50px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn__xs {
  padding: 10px 15px;
}
.btn__xs.round {
  width: 40px;
  height: 40px;
}
.btn__working {
  position: relative;
}
.btn__working:before {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  right: -4px;
  bottom: -4px;
  background: inherit;
  z-index: -1;
  border-radius: 100%;
  opacity: 0.25;
}
.btn__working:after {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  right: -4px;
  bottom: -4px;
  border-top: 4px solid #00c020;
  border-radius: 100%;
  z-index: -1;
  opacity: 0.5;
  animation: workingBtn 1s linear infinite;
  will-change: transform;
}
.btn__spinning i {
  animation: spinningBtn 2s linear infinite;
}
@media screen and (max-width: 769px) {
  .btn {
    min-width: 80px;
    font-size: 12px;
  }
}

@keyframes workingBtn {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinningBtn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.grid {
  display: flex;
  margin: -20px;
  flex-wrap: wrap;
}
.grid_col {
  padding: 20px;
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 100%;
}
.grid_col:empty {
  display: none;
}
.grid_col.col-1 {
  width: 8.33333%;
}
.grid_col.col-2 {
  width: 16.66666%;
}
.grid_col.col-3 {
  width: 25%;
}
.grid_col.col-4 {
  width: 33.33333%;
}
.grid_col.col-5 {
  width: 41.66666%;
}
.grid_col.col-6 {
  width: 50%;
}
.grid_col.col-7 {
  width: 58.33333%;
}
.grid_col.col-8 {
  width: 66.66666%;
}
.grid_col.col-9 {
  width: 75%;
}
.grid_col.col-10 {
  width: 83.33333%;
}
.grid_col.col-11 {
  width: 91.66666%;
}
@media screen and (min-width: 770px) {
  .grid_col.tablet-col-1 {
    width: 8.33333%;
  }
  .grid_col.tablet-col-2 {
    width: 16.66666%;
  }
  .grid_col.tablet-col-3 {
    width: 25%;
  }
  .grid_col.tablet-col-4 {
    width: 33.33333%;
  }
  .grid_col.tablet-col-5 {
    width: 41.66666%;
  }
  .grid_col.tablet-col-6 {
    width: 50%;
  }
  .grid_col.tablet-col-7 {
    width: 58.33333%;
  }
  .grid_col.tablet-col-8 {
    width: 66.66666%;
  }
  .grid_col.tablet-col-9 {
    width: 75%;
  }
  .grid_col.tablet-col-10 {
    width: 83.33333%;
  }
  .grid_col.tablet-col-11 {
    width: 91.66666%;
  }
}
@media screen and (min-width: 1281px) {
  .grid_col.desktop-col-1 {
    width: 8.33333%;
  }
  .grid_col.desktop-col-2 {
    width: 16.66666%;
  }
  .grid_col.desktop-col-3 {
    width: 25%;
  }
  .grid_col.desktop-col-4 {
    width: 33.33333%;
  }
  .grid_col.desktop-col-5 {
    width: 41.66666%;
  }
  .grid_col.desktop-col-6 {
    width: 50%;
  }
  .grid_col.desktop-col-7 {
    width: 58.33333%;
  }
  .grid_col.desktop-col-8 {
    width: 66.66666%;
  }
  .grid_col.desktop-col-9 {
    width: 75%;
  }
  .grid_col.desktop-col-10 {
    width: 83.33333%;
  }
  .grid_col.desktop-col-11 {
    width: 91.66666%;
  }
}

.widget {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
.widget:not(:first-child) {
  margin-top: 40px;
}
.widget__title {
  color: #fff;
  background: #333;
  margin: -20px -20px 20px;
  padding: 20px;
  border-radius: 4px 4px 0 0;
}
.widget__icon {
  margin-right: 20px;
}
.widget__descr {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.widget__descr h3 {
  margin-bottom: 10px;
  text-transform: uppercase;
}
.widget__descr p {
  margin-bottom: 10px;
}
.widget__descr p:last-child {
  margin-bottom: 0;
}
.widget.sticky {
  position: sticky;
  top: calc(112px + 85px + 40px);
}

.table__body-row, .table__head-row {
  background: #fff;
}
.disabled.table__body-row td > *:not(.table__actions):not(.status):not(.modal), .disabled.table__head-row td > *:not(.table__actions):not(.status):not(.modal) {
  opacity: 0.35;
}
.table__body-row:nth-child(2n), .table__head-row:nth-child(2n) {
  background: #f2f2f2;
}

.table__body-cell, .table__head-cell {
  padding: 10px;
  vertical-align: middle;
}

.table {
  table-layout: fixed;
  width: 100%;
  text-align: left;
}
.table__holder {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}
.table__holder .preloader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.table__holder .preloader:before {
  content: "";
  background: #fff;
  z-index: -1;
  opacity: 0.75;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.table__head-row {
  background: #333;
  color: #fff;
}
.table__head-cell {
  font-weight: normal;
  text-transform: uppercase;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.table__body-row:hover {
  background: #d7f4fc;
}
.table__body-row.prominent {
  background: #d7f4fc;
}
.table__body-row.prominent [class$=-cell] {
  border-bottom: 1px solid #4ec1e2;
}
.table__body-row.current + :not(.current) [class$=-cell] {
  border-top: 1px solid #333;
}
.table__actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.table__actions-btn {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  margin-top: 10px;
}
.table__actions-btn:first-child {
  margin-top: 0;
}
.table__actions-btn:hover {
  color: #4ec1e2;
  text-decoration: underline;
}
.table__actions-btn-error {
  color: #e32929;
}
.table__actions-btn-error:hover {
  color: #e32929;
}
.table__img {
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  width: 30px;
  height: 30px;
  background-color: #ccc;
  overflow: hidden;
  margin: 0 auto;
}
.table__img-icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
}
.table__key {
  text-transform: uppercase;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  display: block;
  border-right: 1px solid #ccc;
  font-weight: bold;
}
.table__ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pager {
  display: flex;
  justify-content: center;
  padding: 20px 20px 0;
  flex-wrap: wrap;
}
.pager[hidden] {
  display: none;
}
.pager__item {
  display: block;
  padding: 10px;
  margin: 1px;
  background: #fafafa;
  color: #ccc;
  cursor: pointer;
}
.pager__item:hover {
  background: #d7f4fc;
  color: #4ec1e2;
}
.pager__item.active {
  background: #d7f4fc;
  color: #4ec1e2;
  cursor: default;
}
.pager.student {
  line-height: 1;
}
.pager.student .pager__item:hover, .pager.student .pager__item.active {
  background: #7f00a3;
  color: #fff;
}

.notifications__typeHeading {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.notifications__typeHeading-remove {
  font-weight: normal;
}
.notifications__item-body {
  background: #fafafa;
  margin-bottom: 20px;
}
.notifications__add-btn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  color: #ccc;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
.notifications__add-btn:hover {
  background: #d7f4fc;
  color: #4ec1e2;
}
.notifications .nothingFound {
  color: #ccc;
}
@media screen and (max-width: 1281px) {
  .notifications {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;
  }
  .notifications:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.notification {
  padding: 30px 40px 20px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.notification a {
  color: #fff;
}
.notification.message {
  background: #d7f4fc;
  color: #4ec1e2;
}
.notification.message [class$=__icon] {
  background: #4ec1e2;
  color: #d7f4fc;
}
.notification.message a {
  background: #4ec1e2;
}
.notification.warning {
  background: #ffebc4;
  color: #ffbb33;
}
.notification.warning [class$=__icon] {
  background: #ffbb33;
  color: #ffebc4;
}
.notification.warning a {
  background: #ffbb33;
}
.notification.error {
  background: #f2c2c2;
  color: #e32929;
}
.notification.error [class$=__icon] {
  background: #e32929;
  color: #f2c2c2;
}
.notification.error a {
  background: #e32929;
}
.notification__icon {
  font-size: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification__content {
  padding-left: 80px;
  width: 100%;
}
.notification__heading {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}
.notification__text {
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: center;
}
.notification__link {
  text-align: center;
}
@media screen and (max-width: 769px) {
  .notification {
    margin: -20px -20px 20px !important;
  }
  .notification__icon {
    width: 50px;
    font-size: 20px;
  }
  .notification__content {
    padding-left: 50px;
  }
}
.notification__actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.notification__actions > * {
  margin-left: 20px;
  cursor: pointer;
}
.notification__actions > *:hover {
  opacity: 0.75;
}
.notification__actions-edit {
  color: #4ec1e2;
}
.notification__actions-remove {
  color: #e32929;
}

.block {
  margin-bottom: 40px;
}
.block:last-child, .block.grid_col {
  margin-bottom: 0;
}
.block__heading {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 -10px 20px;
  border-bottom: 1px solid #ccc;
  padding: 0 10px 20px;
  color: #7f00a3;
}
.block__heading:after {
  content: "";
  display: block;
  clear: both;
}
.block__heading.teacher {
  color: #333;
  margin-left: 0;
  margin-right: 0;
}
.block__heading-subheading {
  color: #999;
}
.block__heading-subheading:after {
  content: "/";
  margin: 0 5px;
}

.article {
  line-height: 1.5;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.article__title {
  margin: 40px 0 20px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #333;
  padding: 10px;
  color: #7f00a3;
  font-size: 18px;
}
.article__subtitle {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  color: #7f00a3;
}
.article__allAnswerMarks {
  position: fixed;
  right: 40px;
  top: 40px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 4px;
}
.article__allAnswerMarks i {
  margin-top: 10px;
}
.article__allAnswerMarks i:first-child {
  margin-top: 0;
}
.article__allAnswerMarks i.fa-circle-check {
  color: #00c020;
}
.article__allAnswerMarks i.fa-triangle-exclamation {
  color: #ffbb33;
}
.article__allAnswerMarks i.fa-square-xmark {
  color: #e32929;
}
.article__testing-answers {
  background: #fafafa;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}
.article__testing-answers [class$=__subtitle] {
  margin-bottom: 10px;
}
.article__testing-answers.correct {
  background: #dcf2ae;
}
.article__testing-answers.correct [class$=__subtitle] {
  color: #00c020;
}
.article__testing-answers.warning {
  background: #ffebc4;
}
.article__testing-answers.warning [class$=__subtitle] {
  color: #ffbb33;
}
.article__testing-answers.incorrect {
  background: #ffe5e5;
}
.article__testing-answers.incorrect [class$=__subtitle] {
  color: #e32929;
}
.article__answer-mark {
  position: absolute;
  right: 15px;
  top: 15px;
}
.article__answer-mark i {
  padding: 5px;
  cursor: pointer;
  font-size: 18px;
  color: #999;
}
.article__answer-mark i:first-child {
  margin-left: 0;
}
.article__answer-mark i:hover:first-child, .article__answer-mark i.active:first-child {
  color: #00c020;
}
.article__answer-mark i:hover:nth-child(2), .article__answer-mark i.active:nth-child(2) {
  color: #ffbb33;
}
.article__answer-mark i:hover:last-child, .article__answer-mark i.active:last-child {
  color: #e32929;
}
.article__testing-comments {
  background: #fafafa;
  padding: 20px;
  margin-bottom: 20px;
}
.article__testing-comments [class$=__subtitle] {
  margin-bottom: 10px;
}
.article__answerTitle {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  color: #7f00a3;
}
.article img {
  max-width: 100%;
}
.article .tox-statusbar {
  display: none !important;
}
.article__submit-holder {
  padding: 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #f2f2f2;
  margin-top: 20px;
}
.article__submit-text {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  color: #e32929;
}
.article__submit {
  text-align: center;
}
.article__block {
  margin-bottom: 20px;
}
.article__block:last-child {
  margin-bottom: 0;
}
.article__block-placeholder {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  color: #ccc;
}
.article__block.type-formula, .article__block.type-text {
  position: relative;
  user-select: none;
  overflow: hidden;
}
.article__block.type-formula h1, .article__block.type-text h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
.article__block.type-formula h2, .article__block.type-text h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}
.article__block.type-formula h3, .article__block.type-text h3 {
  font-size: 18px;
  margin-bottom: 20px;
}
.article__block.type-formula h4, .article__block.type-text h4 {
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 20px;
}
.article__block.type-formula p, .article__block.type-text p {
  margin-bottom: 20px;
}
.article__block.type-formula p br, .article__block.type-text p br {
  display: none;
}
.article__block.type-formula pre, .article__block.type-text pre {
  margin-bottom: 20px;
}
.article__block.type-formula sub, .article__block.type-text sub {
  font-size: 10px;
  vertical-align: baseline;
}
.article__block.type-formula sup, .article__block.type-text sup {
  font-size: 10px;
  vertical-align: top;
}
.article__block:last-child {
  margin-bottom: 0;
}
.article__block.type-formula {
  position: relative;
  user-select: none;
  overflow: hidden;
}
.article__block.type-formula .tox-editor-header {
  display: none !important;
}
.article__block.type-formula:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.article__block.type-formula script {
  display: block;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
}
.article__block.type-formula .MathJax {
  margin-left: 10px;
  font-size: 20px;
}
.article__block.type-formula .MathJax .math > * > * {
  position: static !important;
}
.article__block.type-formula .MathJax .mspace {
  display: block !important;
  height: 20px !important;
}
.article__image {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.article__image.size-16x9 {
  padding-top: 56.25%;
}
.article__image.size-4x3 {
  padding-top: 75%;
}
.article__image.size-1x1 {
  padding-top: 100%;
}
.article__image.size-3x4 {
  padding-top: 125%;
}
.article__image.size-4x6 {
  padding-top: 150%;
}
.article__image.size-9x16 {
  padding-top: 177.77%;
}
.article__image-caption {
  text-align: center;
  font-weight: lighter;
  padding-top: 10px;
  color: #999;
  font-style: italic;
}
.article hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 40px 0;
}
.article__comment {
  background: #fafafa;
  padding: 20px;
  margin-top: 20px;
}
.article__comment-heading {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  color: #7f00a3;
}
.article__answers {
  background: #fafafa;
  padding: 20px;
  position: relative;
}
.article__answers-mark {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 18px;
}
.article__answers.correct {
  background: #dcf2ae;
}
.article__answers.correct .article__answerTitle,
.article__answers.correct i.fa-solid {
  color: #00c020;
}
.article__answers.warning {
  background: #ffebc4;
}
.article__answers.warning .article__answerTitle,
.article__answers.warning i.fa-solid {
  color: #ffbb33;
}
.article__answers.incorrect {
  background: #ffe5e5;
}
.article__answers.incorrect .article__answerTitle,
.article__answers.incorrect i.fa-solid {
  color: #e32929;
}
.article__answers.isReadonly {
  position: relative;
}
.article__answers.isReadonly:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.article__answers .form__radio-item {
  justify-content: flex-start;
}
.article__answers-confirm {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.article__preloader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article__preloader:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #fff;
  opacity: 0.75;
}
.article__audio p {
  margin-bottom: 10px;
  color: #999;
  text-transform: uppercase;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
}
.article__audio audio {
  width: 100%;
  vertical-align: top;
}
.article .youtube-holder {
  position: relative;
  padding-top: 56.25%;
}
.article .youtube-holder > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}
.article__video-holder {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.article__video-holder iframe {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.alphabetIndex {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  margin-left: 20px;
  width: 200px;
  user-select: none;
}
.alphabetIndex__list {
  position: sticky;
  top: calc(60px + 40px + 20px);
  opacity: 0.5;
}
.alphabetIndex__list:hover {
  opacity: 1;
}
.alphabetIndex__item {
  margin: 10px 0;
  cursor: pointer;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}
.alphabetIndex__item:hover {
  color: #7f00a3;
}
.alphabetIndex__btn {
  display: none;
}
@media screen and (max-width: 1660px) {
  .alphabetIndex {
    width: auto;
    left: auto;
    margin-left: 0;
    right: 0;
    text-align: right;
    z-index: 3;
  }
  .alphabetIndex.show [class$=__btn] {
    background: #7f00a3;
  }
  .alphabetIndex.show [class$=__list] {
    display: block;
    opacity: 1;
  }
  .alphabetIndex__list {
    display: none;
    padding: 40px 20px 10px;
    top: 190px;
  }
  .alphabetIndex__btn {
    position: sticky;
    top: calc(60px + 40px + 20px);
    margin: 20px 20px 0 0;
    background: #333;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    cursor: pointer;
    font-size: 12px;
    z-index: 1;
    float: right;
  }
  .alphabetIndex__btn:hover {
    background: #7f00a3;
  }
}
@media screen and (max-width: 1025px) {
  .alphabetIndex {
    display: none;
  }
}

.scrollbar__content:hover ~ [class$=__track] {
  opacity: 1;
}
.scrollbar__track {
  background: #f2f2f2;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px !important;
  opacity: 0.75;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
}
.scrollbar__track > div {
  border-radius: 4px;
}
.scrollbar__track:hover {
  opacity: 1;
}

.totalItems {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  width: 100%;
}

.preloader {
  display: flex;
  justify-content: center;
  align-self: center;
}
.preloader__text {
  color: #ff8a00;
  text-align: center;
  font-size: 18px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-weight: 300;
  text-transform: lowercase;
  margin-bottom: 40px;
}

.linePreloader {
  height: 100%;
  background: #f2f2f2;
  overflow: hidden;
  position: relative;
}
.linePreloader:after {
  content: "";
  animation: line-loader 1s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  background: linear-gradient(to right, #f2f2f2 0%, #ccc 40%, #ccc 60%, #f2f2f2 100%);
  opacity: 0.25;
  width: 100%;
}

@keyframes line-loader {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.status {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.status__active {
  background: #00c020;
}
.status__suspended {
  background: #bfbfbf;
}