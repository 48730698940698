.block {
  margin-bottom: 40px;
  &:last-child,
  &.grid_col {
    margin-bottom: 0;
  }
  &__heading {
    font-family: $RobotoCondensed;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 -10px 20px;
    border-bottom: 1px solid $mediumGreyColor;
    padding: 0 10px 20px;
    color: $purpleColor;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    &.teacher {
      color: $baseColor;
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__heading-subheading {
    color: $greyColor;
    &:after {
      content: '/';
      margin: 0 5px;
    }
  }
}