.datepicker {
  display: flex;
  justify-content: center;
  align-items: center;
  .form__row {
    max-width: 250px;
    margin-top: 0;
    > .form__field-holder {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      &:first-child {
        width: 30%;
        &:after {
          content: '/';
          margin-left: -7px;
        }
        .form__field {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          border-right: none;
        }
      }
      &:nth-child(2) {
        width: 30%;
        &:after {
          content: '/';
          margin-left: -7px;
        }
        .form__field {
          border-radius: 0;
          border-left: none;
          border-right: none;
        }
      }
      &:nth-child(3) {
        width: 40%;
        .form__field {
          border-left: none;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
      &:nth-child(4) {
        width: 100px;
        margin-left: 20px;
      }
    }
  }
}