@import '../../../assets/scss/utils/colors';
@import '../../../assets/scss/utils/fonts';

.userPicker {
  border: 1px solid transparent;
  &.hasErrors {
    border-color: $errorColor;
    [class$="__add-btn"] {
      color: $errorColor;
    }
  }
  &__add-btn {
    color: $mediumGreyColor;
    cursor: pointer;
    display: flex;
    justify-content: center;
    background: $lightColor;
    padding: 10px;
    transition: background .25s ease-in-out;
    &:hover {
      background: $lightBlueColor;
      color: $anchorColor;
    }
  }
  &__placeholder {
    margin-left: 10px;
    font-family: $RobotoCondensed;
  }
  &__list {
    margin: -5px -20px 20px;
    max-height: 500px;
    overflow: auto;
  }
  &__selectedList {
    margin: -5px -10px 0;
    overflow: hidden;
    + [class$="__add"] {
      margin-top: 20px;
    }
  }
  &__list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    &:nth-child(2n) {
      background: $lightColor;
    }
    &:hover {
      background: $lightBlueColor;
    }
    &.selected {
      background: $greenColor;
    }
    &.selectedUserItem {
      background: transparent;
      cursor: initial;
      padding: 10px;
      &:nth-child(2n) {
        background: $lightColor;
      }
    }
    &.teacher:not(.multiple) {
      flex-direction: column;
      padding-bottom: 0;
      [class$="__list-item-avatar"] {
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
        box-shadow: inset 0 5px 5px 0 rgba(0,0,0,.25);
        i {
          font-size: 24px;
        }
      }
      [class$="__list-item-name"] {
        font-size: 20px;
        text-align: center;
      }
      [class$="__list-item-remove"] {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    &.selectAll {
      background: $baseColor;
      color: $white;
      height: 40px;
      &.selected {
        color: $greenColor;
        i {
          color: $greenColor;
        }
      }
    }
  }
  &__list-item-avatar {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    background-color: $lightGreyColor;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-size: cover;
    background-position: 50% 50%;
    flex: 0 0 auto;
    i {
      font-size: 14px;
      color: $mediumGreyColor;
    }
  }
  &__list-item-name {
    font-family: $RobotoCondensed;
    width: 100%;
  }
  &__list-item-class {
    font-family: $RobotoCondensed;
    white-space: nowrap;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    i {
      font-size: 12px;
    }
  }
  &__list-item-check {
    margin-right: 10px;
    &.selected {
      color: $white;
    }
  }
  &__list-btn {
    display: flex;
    justify-content: center;
    .btn {
      cursor: pointer;
      &[disabled] {
        cursor: not-allowed;
      }
    }
  }
  &__list-item-remove {
    cursor: pointer;
    &:hover {
      color: $errorColor;
    }
  }
  .modal {
    &__box {
      max-width: 500px;
    }
  }
  .filters {
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    background: none;
    padding: 0 20px 10px;
    margin: 0 -30px 25px;
    border-bottom: 1px solid $lightGreyColor;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.05);
    &__item {
      padding: 0 5px;
      box-sizing: border-box;
      flex: 0 0 auto;
    }
    &__searchQuery {
      width: 50%;
    }
    &__filterBy {
      width: 25%;
    }
    &__selectClass {
      width: 25%;
    }
    &__heading {
      display: none;
    }
    &__inner {
      margin: 0;
    }
    &__searchQuery {
      max-width: 100%;
    }
    .form {
      margin: 0;
      min-height: 0;
      &__row {
        margin: 0;
        padding: 0;
      }
      &__field-placeholder {
        background: $white !important;
      }
    }
  }
  .nothingFound {
    &:not(:first-child:last-child) {
      padding: 22px 0;
      margin-bottom: -20px;
      overflow: hidden;
    }
  }
  &__list {
    > div {
      > .scrollbar__track {
        width: 6px !important;
        border-radius: 0;
        background: transparent;
        > div {
          background: $baseColor !important;
          border-radius: 0;
        }
      }
    }
  }
}
