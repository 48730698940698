@import '../../../assets/scss/utils/colors';
@import '../../../assets/scss/utils/fonts';

.libraryPicker {
  &__add {

  }
  &__add-btn {
    color: $mediumGreyColor;
    cursor: pointer;
    display: flex;
    justify-content: center;
    background: $lightColor;
    padding: 10px;
    transition: background .25s ease-in-out;
    &:hover {
      background: $lightBlueColor;
      color: $anchorColor;
    }
  }
  &__placeholder {
    margin-left: 10px;
    font-family: $RobotoCondensed;
  }
  &__list {
    margin: -5px -20px 20px;
  }
  &__selectedList {
    margin: -5px 0 -20px;
    + [class$="__add"] {
      margin-top: 20px;
    }
  }
  &__list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      background: $lightBlueColor;
    }
    &.selected {
      background: $greenColor;
    }
    &.selectedUserItem {
      background: transparent;
      cursor: initial;
      padding: 10px 20px;
    }
  }
  &__list-item-avatar {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    background-color: $lightGreyColor;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-size: cover;
    background-position: 50% 50%;
    flex: 0 0 auto;
    i {
      font-size: 14px;
      color: $mediumGreyColor;
    }
  }
  &__list-item-name {
    font-family: $RobotoCondensed;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__list-item-check {
    margin-right: 10px;
    &.selected {
      color: $white;
    }
  }
  &__list-btn {
    display: flex;
    justify-content: center;
    .btn {
      cursor: pointer;
      &[disabled] {
        cursor: not-allowed;
      }
    }
  }
  &__list-item-remove {
    cursor: pointer;
    &:hover {
      color: $errorColor;
    }
  }
  .filters {
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    background: none;
    padding: 0 20px 10px;
    margin: 0 -20px 25px;
    border-bottom: 1px solid $lightGreyColor;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.05);
    &__heading {
      display: none;
    }
    &__inner {
      margin: 0;
    }
    &__searchQuery {
      max-width: 100%;
    }
    .form {
      margin: 0;
      min-height: 0;
      &__row {
        margin: 0;
        padding: 0;
      }
      &__field-placeholder {
        background: $white !important;
      }
    }
  }
  .nothingFound {
    &:not(:first-child:last-child) {
      padding: 22px 0;
      margin-bottom: -20px;
      overflow: hidden;
    }
  }
}