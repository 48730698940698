@import '../../assets/scss/utils/colors';
@import '../../assets/scss/utils/fonts';

.form {
  width: 100%;
  position: relative;
  .tabs {
    width: 100%;
    &__noTabs {
      margin-top: -20px;
    }
  }
  &.hasErrors {
    animation: shake .25s ease-in-out;
  }
  &__heading {
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 20px;
    font-size: 20px;
    font-family: $RobotoCondensed;
  }
  &__block {
    border: 1px solid $lightGreyColor;
    padding: 0 10px 10px;
    box-sizing: border-box;
    margin: 0 -10px;
    width: calc(100% + 20px);
    position: relative;
  }
  &__block-heading {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateY(-50%) translateX(-50%);
    font-family: $RobotoCondensed;
    color: $mediumGreyColor;
    background: $white;
    font-size: 14px;
    text-transform: uppercase;
    padding: 2px;
  }
  &__block-btnRemove {
    padding: 5px;
    background: $white;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    &:hover {
      color: $errorColor;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: -20px;
    width: 100%;
  }
  &__row {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  &__col {
    width: 50%;
    padding: 0 10px;
    flex: 0 0 auto;
    box-sizing: border-box;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &__field-holder {
    position: relative;
    width: 100%;
    &:focus {
      background: #000;
    }
  }
  &__field {
    border: 1px solid $mediumGreyColor;
    background: none;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 14px;
    line-height: 32px;
    height: 40px;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    font-family: $RobotoCondensed;
    position: relative;
    z-index: 1;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    &.readonly {
      cursor: not-allowed;
    }
    &:focus:not(.readonly):not(.customSelect__value),
    &.customSelect__value.isActive {
      border-color: $btnPrimary;
      ~ .form__field-placeholder {
        top: 0;
        z-index: 1;
        background: $white;
        padding: 2px;
        margin-left: -2px;
        line-height: 1;
        color: $btnPrimary;
      }
      ~ .form__field-icon {
        top: 0;
        z-index: 1;
        color: $btnPrimary;
      }
    }
    &.isUpdated {
      border-color: $greenColor;
      ~ .form__field-placeholder {
        color: $greenColor;
      }
      ~ .form__field-icon {
        color: $greenColor;
      }
    }
    &.hasBtn {
      padding-right: 36px;
    }
    &.hasErrors {
      border-color: $errorColor;
      ~ .form__field-placeholder {
        color: $errorColor;
      }
      ~ .form__field-icon {
        color: $errorColor;
      }
    }
    &::-webkit-input-placeholder {
      color: $mediumGreyColor;
    }
    &::-moz-placeholder {
      color: $mediumGreyColor;
    }
    &:-ms-input-placeholder {
      color: $mediumGreyColor;
    }
    &:-moz-placeholder {
      color: $mediumGreyColor;
    }
    &[type="time"] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button,
      &::-webkit-clear-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  &__textarea {
    height: 100px;
    resize: none;
    padding: 10px;
    line-height: 1.5;
    + .form__field-placeholder {
      top: 0;
      transform: translateY(1px);
      &.isFilled {
        transform: translateY(-50%);
      }
    }
    &:focus,
    &.isUpdated {
      + .form__field-placeholder {
        transform: translateY(-50%);
      }
    }
  }
  &__field-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $mediumGreyColor;
    background: $white;
    transition: all .15s ease-in-out;
    padding: 2px;
    &.isFilled {
      top: 0;
      z-index: 1;
    }
    ~ .form__field-placeholder {
      left: 35px;
    }
  }
  &__field-error {
    color: $errorColor;
    padding: 10px 10px 0;
    font-size: 14px;
    font-family: $RobotoCondensed;
  }
  &__field-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    &:hover {
      color: $linkHoverColor;
    }
  }
  &__field-placeholder {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $mediumGreyColor;
    font-family: $RobotoCondensed;
    font-size: 14px;
    line-height: 32px;
    user-select: none;
    transition: all .15s ease-in-out;
    &.isFilled {
      top: 0;
      z-index: 1;
      background: $white;
      padding: 2px;
      margin-left: -2px;
      line-height: 1;
    }
  }
  &__select-holder {
    &.hasErrors {
      .form__field {
        border-color: $errorColor;
      }
      .form__field-placeholder {
        color: $errorColor;
      }
    }
  }
  &__checkbox {
    display: none;
    + label {
      font-family: $RobotoCondensed;
      font-size: 14px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      line-height: 26px;
      &.isReadonly {
        cursor: default;
        .check-text {
          margin-right: -5px;
        }
        .check {
          display: none;
        }
      }
      .check-text {
        margin-right: 10px;
      }
      .check-descr {
        margin-left: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        color: $mediumGreyColor;
        margin-top: 1px;
        margin-bottom: -1px;
      }
      .check {
        width: 46px;
        height: 26px;
        background: $lightGreyColor;
        border-radius: 20px;
        position: relative;
        transition: background .25s ease-in-out;
        &:after {
          content: '';
          position: absolute;
          left: 2px;
          top: 2px;
          width: 22px;
          height: 22px;
          background: $mediumGreyColor;
          border-radius: 20px;
          transform: translateX(0);
          transition: all .25s ease-in-out;
        }
      }
    }
    &:checked,
    &.checked {
      + label {
        .check-descr {
          color: $greenColor;
        }
        .check {
          background: $lightGreenColor;
          &:after {
            transform: translateX(20px);
            background: $greenColor;
          }
        }
      }
    }
  }
  &__radio-holder {
    display: flex;
    align-items: center;
    padding: 0 10px;
    &.alt,
    &.checkboxes {
      display: block;
      padding: 0;
      font-family: $RobotoCondensed;
      letter-spacing: .5px;
      [class$="__radio-heading"] {
        text-transform: uppercase;
        letter-spacing: .5px;
        font-size: initial;
      }
      [class$="__radio-item"] {
        margin-top: 10px;
        &:first-child {
          margin-top: 0;
        }
        &:after {
          display: none;
        }
      }
      label {
        padding: 0;
        font-size: initial;
        &:hover {
          color: $linkHoverColor;
        }
      }
      input[type="checkbox"] {
        &:checked {
          + label {
            color: $linkHoverColor;
          }
        }
      }
    }
  }
  &__radio-heading {
    font-size: 14px;
    font-family: $RobotoCondensed;
    margin-right: 10px;
  }
  &__radio-item {
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      content: '|';
      color: $mediumGreyColor;
      margin: 0 10px;
      font-weight: 300;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  &__radio {
    display: none;
    + label {
      cursor: pointer;
      &:hover {
        color: $linkHoverColor;
      }
    }
    &:checked {
      + label {
        color: $linkHoverColor;
      }
    }
  }
  &__file-holder {
    position: relative;
    margin: 0 auto;
    > .textTooltip {
      display: block;
      .textTooltip__trigger {
        display: block;
        overflow: hidden;
      }
    }
    &:hover {
      color: $anchorColor;
      [class$="__file-trigger"] {
        background: $lightBlueColor;
      }
      [class$="__file-icon"] {
        color: $anchorColor;
      }
    }
    &.isUpdated {
      [class$="__file-trigger"] {
        border-color: $greenColor;
        background: $lightGreenColor;
      }
      [class$="__file-icon"] {
        color: $greenColor;
      }
    }
  }
  &__file {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    width: 100%;
    z-index: 1;
  }
  &__file-trigger {
    padding-top: 100%;
    display: block;
    background: $lightGreyColor;
    position: relative;
    overflow: hidden;
    border: 1px solid transparent;
    &.round {
      border-radius: 100%;
    }
    &.landscape {
      padding-top: 56.25%;
    }
    &.contain {
      [class$="__file-image"] {
        background-size: contain;
        margin: 20px;
      }
    }
    &.customSize {
      padding-top: 0;
      min-height: 120px;
      img {
        width: 100%;
        height: auto;
        position: relative;
        vertical-align: top;
      }
    }
    &.PNG {
      [class$="__file-image"] {
        background-color: $white;
        box-shadow: 0 0 20px 50px rgba(255,255,255,1);
      }
    }
  }
  &__file-icon {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: $mediumGreyColor;
  }
  &__file-image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 1;
  }
  &__file-image-actions {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  &__file-image-btn {
    cursor: pointer;
    padding: 10px;
    margin: -10px;
  }
  &__file-remove-holder {
    position: absolute;
    right: 0;
    top: 0;
    color: $errorColor;
  }
  &__file-remove {
    cursor: pointer;
    padding: 5px;
    margin: -5px;
    transform: scale(1) translateZ(0);
    transition: transform .15s ease-in-out;
    z-index: 1;
  }
  &__fileItem-holder {
    background: $lightGreyColor;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    position: relative;
    color: $mediumGreyColor;
    transition: all .15s ease-in-out;
    margin: 0 auto;
    &:hover {
      background: $lightBlueColor;
      color: $anchorColor;
    }
    .textTooltip {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      &__trigger {
        height: 100%;
        width: 100%;
        display: block;
      }
    }
  }
  &__fileItem-trigger {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__fileItem-icon {
    font-size: 24px;
  }
  &__fileItem {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    width: 100%;
    z-index: 1;
  }
  &__btn-holder {
    text-align: center;
    width: 100%;
  }
  &__btn {
    border: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;
  }
  &__error {
    background: $errorColor;
    color: $white;
    text-align: center;
    padding: 10px;
  }
  &__loading {
    position: absolute;
    left: 0;
    top: -6px;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $white;
      z-index: -1;
      opacity: .75;
    }
  }
}
