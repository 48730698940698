.studentHeader {
  display: flex;
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 40px;
  height: 60px;
  z-index: 10;
  transform: translateZ(0);
}
.studentHeader__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
}
.studentHeader__logo img {
  max-height: 100%;
}
.studentHeader .preloader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.studentHeader .preloader:before {
  content: "";
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.75;
}
.studentHeader__burger {
  transform: translateZ(0);
  position: relative;
  width: 20px;
  height: 14px;
}
.studentHeader__burger span {
  height: 2px;
  background: #333;
  position: absolute;
  transform: rotate(0);
  width: 100%;
  left: 0;
  transition: all 0.25s ease-in-out;
}
.studentHeader__burger span:nth-child(2) {
  top: 5px;
}
.studentHeader__burger span:nth-child(3) {
  top: 10px;
}
.studentHeader__burger.active span {
  top: 5px;
}
.studentHeader__burger.active span:first-child {
  transform: rotate(45deg);
}
.studentHeader__burger.active span:nth-child(2) {
  transform: rotate(45deg);
}
.studentHeader__burger.active span:nth-child(3) {
  transform: rotate(-45deg);
}
@media screen and (max-width: 1025px) {
  .studentHeader {
    height: 40px;
    justify-content: space-between;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  }
  .studentHeader__logo {
    height: 40px;
  }
}
@media screen and (max-width: 769px) {
  .studentHeader {
    padding: 20px 20px;
    height: calc(20px + 40px);
    min-height: calc(20px + 40px);
    position: sticky;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  }
  .studentHeader__logo {
    height: 30px;
  }
  .studentHeader .header__divider {
    margin: 0 15px;
  }
}