.form {
  width: 100%;
  position: relative;
}
.form .tabs {
  width: 100%;
}
.form .tabs__noTabs {
  margin-top: -20px;
}
.form.hasErrors {
  animation: shake 0.25s ease-in-out;
}
.form__heading {
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.form__block {
  border: 1px solid #f2f2f2;
  padding: 0 10px 10px;
  box-sizing: border-box;
  margin: 0 -10px;
  width: calc(100% + 20px);
  position: relative;
}
.form__block-heading {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateY(-50%) translateX(-50%);
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  color: #ccc;
  background: #fff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 2px;
}
.form__block-btnRemove {
  padding: 5px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0, -50%);
  cursor: pointer;
}
.form__block-btnRemove:hover {
  color: #e32929;
}
.form__actions {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
  width: 100%;
}
.form__row {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.form__col {
  width: 50%;
  padding: 0 10px;
  flex: 0 0 auto;
  box-sizing: border-box;
}
.form__col:first-child {
  padding-left: 0;
}
.form__col:last-child {
  padding-right: 0;
}
.form__field-holder {
  position: relative;
  width: 100%;
}
.form__field-holder:focus {
  background: #000;
}
.form__field {
  border: 1px solid #ccc;
  background: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 14px;
  line-height: 32px;
  height: 40px;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  position: relative;
  z-index: 1;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}
.form__field.readonly {
  cursor: not-allowed;
}
.form__field:focus:not(.readonly):not(.customSelect__value), .form__field.customSelect__value.isActive {
  border-color: #4ec1e2;
}
.form__field:focus:not(.readonly):not(.customSelect__value) ~ .form__field-placeholder, .form__field.customSelect__value.isActive ~ .form__field-placeholder {
  top: 0;
  z-index: 1;
  background: #fff;
  padding: 2px;
  margin-left: -2px;
  line-height: 1;
  color: #4ec1e2;
}
.form__field:focus:not(.readonly):not(.customSelect__value) ~ .form__field-icon, .form__field.customSelect__value.isActive ~ .form__field-icon {
  top: 0;
  z-index: 1;
  color: #4ec1e2;
}
.form__field.isUpdated {
  border-color: #a1d042;
}
.form__field.isUpdated ~ .form__field-placeholder {
  color: #a1d042;
}
.form__field.isUpdated ~ .form__field-icon {
  color: #a1d042;
}
.form__field.hasBtn {
  padding-right: 36px;
}
.form__field.hasErrors {
  border-color: #e32929;
}
.form__field.hasErrors ~ .form__field-placeholder {
  color: #e32929;
}
.form__field.hasErrors ~ .form__field-icon {
  color: #e32929;
}
.form__field::-webkit-input-placeholder {
  color: #ccc;
}
.form__field::-moz-placeholder {
  color: #ccc;
}
.form__field:-ms-input-placeholder {
  color: #ccc;
}
.form__field:-moz-placeholder {
  color: #ccc;
}
.form__field[type=time]::-webkit-outer-spin-button, .form__field[type=time]::-webkit-inner-spin-button, .form__field[type=time]::-webkit-clear-button {
  -webkit-appearance: none;
  margin: 0;
}
.form__textarea {
  height: 100px;
  resize: none;
  padding: 10px;
  line-height: 1.5;
}
.form__textarea + .form__field-placeholder {
  top: 0;
  transform: translateY(1px);
}
.form__textarea + .form__field-placeholder.isFilled {
  transform: translateY(-50%);
}
.form__textarea:focus + .form__field-placeholder, .form__textarea.isUpdated + .form__field-placeholder {
  transform: translateY(-50%);
}
.form__field-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
  background: #fff;
  transition: all 0.15s ease-in-out;
  padding: 2px;
}
.form__field-icon.isFilled {
  top: 0;
  z-index: 1;
}
.form__field-icon ~ .form__field-placeholder {
  left: 35px;
}
.form__field-error {
  color: #e32929;
  padding: 10px 10px 0;
  font-size: 14px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.form__field-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}
.form__field-btn:hover {
  color: #ea5330;
}
.form__field-placeholder {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 32px;
  user-select: none;
  transition: all 0.15s ease-in-out;
}
.form__field-placeholder.isFilled {
  top: 0;
  z-index: 1;
  background: #fff;
  padding: 2px;
  margin-left: -2px;
  line-height: 1;
}
.form__select-holder.hasErrors .form__field {
  border-color: #e32929;
}
.form__select-holder.hasErrors .form__field-placeholder {
  color: #e32929;
}
.form__checkbox {
  display: none;
}
.form__checkbox + label {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 26px;
}
.form__checkbox + label.isReadonly {
  cursor: default;
}
.form__checkbox + label.isReadonly .check-text {
  margin-right: -5px;
}
.form__checkbox + label.isReadonly .check {
  display: none;
}
.form__checkbox + label .check-text {
  margin-right: 10px;
}
.form__checkbox + label .check-descr {
  margin-left: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  color: #ccc;
  margin-top: 1px;
  margin-bottom: -1px;
}
.form__checkbox + label .check {
  width: 46px;
  height: 26px;
  background: #f2f2f2;
  border-radius: 20px;
  position: relative;
  transition: background 0.25s ease-in-out;
}
.form__checkbox + label .check:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 22px;
  height: 22px;
  background: #ccc;
  border-radius: 20px;
  transform: translateX(0);
  transition: all 0.25s ease-in-out;
}
.form__checkbox:checked + label .check-descr, .form__checkbox.checked + label .check-descr {
  color: #a1d042;
}
.form__checkbox:checked + label .check, .form__checkbox.checked + label .check {
  background: #dcf2ae;
}
.form__checkbox:checked + label .check:after, .form__checkbox.checked + label .check:after {
  transform: translateX(20px);
  background: #a1d042;
}
.form__radio-holder {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.form__radio-holder.alt, .form__radio-holder.checkboxes {
  display: block;
  padding: 0;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
}
.form__radio-holder.alt [class$=__radio-heading], .form__radio-holder.checkboxes [class$=__radio-heading] {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: initial;
}
.form__radio-holder.alt [class$=__radio-item], .form__radio-holder.checkboxes [class$=__radio-item] {
  margin-top: 10px;
}
.form__radio-holder.alt [class$=__radio-item]:first-child, .form__radio-holder.checkboxes [class$=__radio-item]:first-child {
  margin-top: 0;
}
.form__radio-holder.alt [class$=__radio-item]:after, .form__radio-holder.checkboxes [class$=__radio-item]:after {
  display: none;
}
.form__radio-holder.alt label, .form__radio-holder.checkboxes label {
  padding: 0;
  font-size: initial;
}
.form__radio-holder.alt label:hover, .form__radio-holder.checkboxes label:hover {
  color: #ea5330;
}
.form__radio-holder.alt input[type=checkbox]:checked + label, .form__radio-holder.checkboxes input[type=checkbox]:checked + label {
  color: #ea5330;
}
.form__radio-heading {
  font-size: 14px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  margin-right: 10px;
}
.form__radio-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form__radio-item:after {
  content: "|";
  color: #ccc;
  margin: 0 10px;
  font-weight: 300;
}
.form__radio-item:last-child:after {
  display: none;
}
.form__radio {
  display: none;
}
.form__radio + label {
  cursor: pointer;
}
.form__radio + label:hover {
  color: #ea5330;
}
.form__radio:checked + label {
  color: #ea5330;
}
.form__file-holder {
  position: relative;
  margin: 0 auto;
}
.form__file-holder > .textTooltip {
  display: block;
}
.form__file-holder > .textTooltip .textTooltip__trigger {
  display: block;
  overflow: hidden;
}
.form__file-holder:hover {
  color: #4ec1e2;
}
.form__file-holder:hover [class$=__file-trigger] {
  background: #d7f4fc;
}
.form__file-holder:hover [class$=__file-icon] {
  color: #4ec1e2;
}
.form__file-holder.isUpdated [class$=__file-trigger] {
  border-color: #a1d042;
  background: #dcf2ae;
}
.form__file-holder.isUpdated [class$=__file-icon] {
  color: #a1d042;
}
.form__file {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  width: 100%;
  z-index: 1;
}
.form__file-trigger {
  padding-top: 100%;
  display: block;
  background: #f2f2f2;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
}
.form__file-trigger.round {
  border-radius: 100%;
}
.form__file-trigger.landscape {
  padding-top: 56.25%;
}
.form__file-trigger.contain [class$=__file-image] {
  background-size: contain;
  margin: 20px;
}
.form__file-trigger.customSize {
  padding-top: 0;
  min-height: 120px;
}
.form__file-trigger.customSize img {
  width: 100%;
  height: auto;
  position: relative;
  vertical-align: top;
}
.form__file-trigger.PNG [class$=__file-image] {
  background-color: #fff;
  box-shadow: 0 0 20px 50px rgb(255, 255, 255);
}
.form__file-icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: #ccc;
}
.form__file-image {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 1;
}
.form__file-image-actions {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.form__file-image-btn {
  cursor: pointer;
  padding: 10px;
  margin: -10px;
}
.form__file-remove-holder {
  position: absolute;
  right: 0;
  top: 0;
  color: #e32929;
}
.form__file-remove {
  cursor: pointer;
  padding: 5px;
  margin: -5px;
  transform: scale(1) translateZ(0);
  transition: transform 0.15s ease-in-out;
  z-index: 1;
}
.form__fileItem-holder {
  background: #f2f2f2;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  position: relative;
  color: #ccc;
  transition: all 0.15s ease-in-out;
  margin: 0 auto;
}
.form__fileItem-holder:hover {
  background: #d7f4fc;
  color: #4ec1e2;
}
.form__fileItem-holder .textTooltip {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.form__fileItem-holder .textTooltip__trigger {
  height: 100%;
  width: 100%;
  display: block;
}
.form__fileItem-trigger {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form__fileItem-icon {
  font-size: 24px;
}
.form__fileItem {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  width: 100%;
  z-index: 1;
}
.form__btn-holder {
  text-align: center;
  width: 100%;
}
.form__btn {
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.form__error {
  background: #e32929;
  color: #fff;
  text-align: center;
  padding: 10px;
}
.form__loading {
  position: absolute;
  left: 0;
  top: -6px;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form__loading:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: -1;
  opacity: 0.75;
}