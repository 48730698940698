%s-row {
  background: $white;
  &.disabled {
    td {
      > *:not(.table__actions):not(.status):not(.modal) {
        opacity: .35;
      }
    }
  }
  &:nth-child(2n) {
    background: $lightGreyColor;
  }
}
%s-cell {
  padding: 10px;
  vertical-align: middle;
}

.table {
  table-layout: fixed;
  width: 100%;
  text-align: left;
  &__holder {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    .preloader {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      &:before {
        content: '';
        background: $white;
        z-index: -1;
        opacity: .75;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  &__head {
    &-row {
      background: $baseColor;
      color: $white;
      @extend %s-row;
    }
    &-cell {
      font-weight: normal;
      text-transform: uppercase;
      font-family: $RobotoCondensed;
      @extend %s-cell;
    }
  }
  &__body {
    &-row {
      &:hover {
        background: $lightBlueColor;
      }
      @extend %s-row;
      &.prominent {
        background: $lightBlueColor;
        [class$="-cell"] {
          border-bottom: 1px solid $anchorColor;
        }
      }
      &.current {
        + :not(.current) {
          [class$="-cell"] {
            border-top: 1px solid $baseColor;
          }
        }
      }
    }
    &-cell {
      @extend %s-cell;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__actions-btn {
    font-family: $RobotoCondensed;
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    &:hover {
      color: $btnPrimary;
      text-decoration: underline;
    }
    &-error {
      color: $errorColor;
      &:hover {
        color: $errorColor;
      }
    }
  }
  &__img {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    width: 30px;
    height: 30px;
    background-color: $mediumGreyColor;
    overflow: hidden;
    margin: 0 auto;
  }
  &__img-icon {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 12px;
  }
  &__key {
    text-transform: uppercase;
    font-family: $RobotoCondensed;
    display: block;
    border-right: 1px solid $mediumGreyColor;
    font-weight: bold;
  }
  &__ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}