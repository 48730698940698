.studentContact__list {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  display: flex;
  flex-wrap: wrap;
  margin: -40px -20px 0;
}
.studentContact__list-item {
  margin-top: 40px;
  width: 50%;
  padding: 0 20px;
  box-sizing: border-box;
}
.studentContact__list-item-name {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.studentContact__list-item-phone {
  display: flex;
  align-items: center;
}
.studentContact__list-item-phone i {
  background: #fafafa;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.studentContact .map {
  margin-bottom: 40px;
}
@media screen and (max-width: 769px) {
  .studentContact__list-item {
    width: 100%;
  }
}