.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 200px;
  right: 0;
  top: 0;
  padding: 0 40px;
  height: 112px;
  background: #fff;
  z-index: 100;
}
.header__user {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 20px;
}
.header__user-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;
}
.header__user-role {
  color: #999;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
  display: flex;
}
.header__user-class {
  color: #333;
  font-weight: bold;
}
.header__user-class:before {
  content: "/";
  margin: 0 5px;
  color: #999;
}
.header__actions {
  display: flex;
}
.header__actions-item {
  position: relative;
}
.header__icon {
  font-size: 20px;
  position: relative;
  z-index: 1;
}
.header__icon:hover {
  cursor: pointer;
  color: #ea5330;
}
.header__icon:hover + .header__icon-descr {
  opacity: 1;
  z-index: 0;
  transform: translate(-50%, 0);
}
.header__icon-descr {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 10px);
  bottom: 100%;
  text-transform: uppercase;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0;
  z-index: -1;
  transition: all 0.15s ease-in-out;
  color: #ea5330;
}
.header__divider {
  width: 1px;
  height: 20px;
  background: #333;
  margin: 0 20px;
  opacity: 0.25;
}
.header__user {
  display: flex;
  align-items: center;
}
.header__user-avatar {
  margin-right: 10px;
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 100%;
  background-size: cover;
  background-position: 50% 50%;
}
.header__user-avatar-icon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 14px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}