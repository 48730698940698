.languageSelect {
  position: relative;
  z-index: 10;
}
.languageSelect_item:not(:first-child) {
  margin-top: 20px;
}
.languageSelect_link {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.languageSelect_link:hover {
  color: #ea5330;
}
.languageSelect_icon {
  width: 20px !important;
  height: 20px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 100%;
  overflow: hidden;
  flex: 0 0 auto;
}
.languageSelect_text {
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
}
.languageSelect .dropdown_block [class$=_icon] {
  margin-right: 5px;
}