.status {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  &__active {
    background: $successColor;
  }
  &__suspended {
    background: $statusSuspendedColor;
  }
}