@import '../../../assets/scss/utils/fonts';

.textTooltip {
  position: relative;
  z-index: 1;
  display: flex;
  
  &__trigger {
    &:hover {
      + .textTooltip__text {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
        &.left {
          transform: translateX(0) translateY(-50%);
        }
        &.right {
          transform: translateX(0) translateY(-50%);
        }
      }
    }
  }
  &__text {
    position: absolute;
    left: 50%;
    bottom: 100%;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $RobotoCondensed;
    margin-bottom: 3px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(-50%) translateY(150%);
    transition: all .15s ease-in-out;
    z-index: -1;
    &.left {
      left: auto;
      right: 100%;
      bottom: auto;
      top: 50%;
      transform: translateX(150%) translateY(-50%);
      margin-bottom: 0;
      margin-right: 5px;
    }
    &.right {
      left: 100%;
      bottom: auto;
      top: 50%;
      transform: translateX(-150%) translateY(-50%);
      margin-bottom: 0;
      margin-left: 5px;
    }
  }
}
