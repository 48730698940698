$navWidth: 200px;
$navCollapsedWidth: 60px;
$pageIndent: 40px;
$pageIndentMobile: 20px;
$headerHeight: 112px;
$sectionTitleHeight: 85px;
$filtersHeight: 80px;
$studentHeaderHeight: 60px;
$studentHeaderHeightMobile: 40px;
$studentHeaderHeightTiny: 20px;
$studentHeaderIndent: 20px;
$studentNavWidth: 200px;
$contentWidth: 960px;
$widgetIndent: 20px;
$editorBarSize: 100px;

// === Breakpoints
$maxSmallMobile: 375px;
$maxMobile: 769px;
$maxTablet: 1025px;
$maxLaptop: 1280px;
$maxSmallDesktop: 1660px;

$minMobile: 376px;
$minTablet: 770px;
$minLaptop: 1026px;
$minDesktop: 1281px;
$minLargeDesktop: 1661px;
