@import '../../assets/scss/utils/colors';
@import '../../assets/scss/utils/sizes';
@import '../../assets/scss/utils/fonts';

.studentNav {
  position: sticky;
  top: calc(#{$studentHeaderHeight} + #{$studentHeaderIndent * 2} + #{$pageIndent});
  z-index: 1;
  width: $studentNavWidth;
  background: $white;
  padding: 20px 20px 40px;
  box-sizing: border-box;
  left: 0;
  &__list {
    font-family: $RobotoCondensed;
    text-transform: uppercase;
    letter-spacing: .5px;
    width: 120px;
    margin: 0 auto;
  }
  &__item {
    margin-top: 20px;
    white-space: nowrap;
    &:first-child {
      margin-top: 0;
    }
  }
  &__link {
    color: $baseColor;
    &:hover,
    &.active {
      color: $purpleColor;
    }
  }
  &__icon {
    width: 20px;
    text-align: center;
    margin-right: 10px;
    font-size: 18px;
  }
  &__user {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $lightGreyColor;
    font-family: $RobotoCondensed;
    &-avatar {
      width: 120px;
      height: 120px;
      margin: 0 auto 10px;
      background-color: $lightColor;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: $mediumGreyColor;
      border-radius: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        border-radius: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 5px 5px 0 rgba(0,0,0,.25);
      }
    }
    &-name {
      text-align: center;
      margin-bottom: 10px;
      font-size: 18px;
    }
    &-role {
      display: flex;
      justify-content: center;
      color: $greyColor;
      text-transform: uppercase;
      font-size: 14px;
      * {
        width: 50%;
      }
    }
    &-class {
      color: $baseColor;
      font-weight: bold;
      display: flex;
      &:before {
        font-weight: normal;
        content: '/';
        margin: 0 5px;
        color: $greyColor;
      }
    }
  }
  @media screen and (max-width: $maxTablet) {
    position: fixed;
    left: -$studentNavWidth;
    top: calc(#{$studentHeaderHeightMobile} + #{$studentHeaderIndent * 2});
    bottom: 0;
    transition: all .25s ease-in-out;
    z-index: 2;
    box-shadow: 0 0 0 0 rgba(0,0,0,0);
  }
  @media screen and (max-width: $maxMobile) {
    top: calc(#{$studentHeaderHeightTiny} + #{$studentHeaderIndent * 2});
  }
}
