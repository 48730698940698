.footer {
  background: #333;
  color: #fff;
  margin: -40px -40px 0;
  padding: 40px;
  position: relative;
  z-index: 1;
}
.footer__inner {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}
.footer__inner .grid {
  width: 100%;
}
.footer__list {
  overflow: hidden;
}
.footer__heading {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
}
.footer__list-item {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.footer__list-icon {
  margin-right: 10px;
}
.footer__logo {
  width: calc(200px);
  margin-right: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}
.footer__logo img {
  max-height: 50px;
}
.footer .studentContact__list {
  display: block;
}
.footer .studentContact__list-item {
  width: 100%;
}
.footer i {
  background: none !important;
  width: auto !important;
  height: auto !important;
}
.footer .preloader {
  width: 100%;
}
@media screen and (max-width: 1025px) {
  .footer {
    margin: 0;
    transition: all 0.25s ease-in-out;
    width: 100%;
    box-sizing: border-box;
  }
  .footer__logo img {
    max-height: 40px;
  }
}
@media screen and (max-width: 769px) {
  .footer {
    padding: 20px;
  }
  .footer__logo {
    width: 100%;
  }
  .footer__logo img {
    max-width: 100px;
  }
}