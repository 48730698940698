@import '../../assets/scss/utils/colors';
@import '../../assets/scss/utils/fonts';

.languageSelect {
  position: relative;
  z-index: 10;
  &_item {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  &_link {
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      color: $linkHoverColor;
    }
  }
  &_icon {
    width: 20px !important;
    height: 20px;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 100%;
    overflow: hidden;
    flex: 0 0 auto;
  }
  &_text {
    font-size: 14px;
    text-transform: uppercase;
    font-family: $RobotoCondensed;
    letter-spacing: .5px;
  }
  .dropdown_block {
    [class$="_icon"] {
      margin-right: 5px;
    }
  }
}