.article {
  line-height: 1.5;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  &__title {
    margin: 40px 0 20px;
    font-family: $RobotoCondensed;
    border-bottom: 1px solid $baseColor;
    padding: 10px;
    color: $purpleColor;
    font-size: 18px;
  }

  &__subtitle {
    font-family: $RobotoCondensed;
    color: $purpleColor;
  }

  &__allAnswerMarks {
    position: fixed;
    right: 40px;
    top: 40px;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    background: rgba(0,0,0,.6);
    padding: 10px;
    border-radius: 4px;

    i {
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      &.fa-circle-check {
        color: $successColor;
      }

      &.fa-triangle-exclamation {
        color: $warningColor;
      }

      &.fa-square-xmark {
        color: $errorColor;
      }
    }
  }

  &__testing-answers {
    background: $lightColor;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;

    [class$="__subtitle"] {
      margin-bottom: 10px;
    }

    &.correct {
      background: $lightGreenColor;

      [class$="__subtitle"] {
        color: $successColor;
      }
    }

    &.warning {
      background: $warningTextColor;

      [class$="__subtitle"] {
        color: $warningColor;
      }
    }

    &.incorrect {
      background: $lightRedColor;

      [class$="__subtitle"] {
        color: $errorColor;
      }
    }
  }

  &__answer-mark {
    position: absolute;
    right: 15px;
    top: 15px;

    i {
      padding: 5px;
      cursor: pointer;
      font-size: 18px;
      color: $greyColor;

      &:first-child {
        margin-left: 0;
      }

      &:hover,
      &.active {
        &:first-child {
          color: $successColor;
        }
        &:nth-child(2) {
          color: $warningColor;
        }
        &:last-child {
          color: $errorColor;
        }
      }
    }
  }

  &__testing-comments {
    background: $lightColor;
    padding: 20px;
    margin-bottom: 20px;

    [class$="__subtitle"] {
      margin-bottom: 10px;
    }
  }

  &__answerTitle {
    font-family: $RobotoCondensed;
    color: $purpleColor;
  }

  img {
    max-width: 100%;
  }
  .tox-statusbar {
    display: none !important;
  }
  &__submit-holder {
    padding: 20px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid $lightGreyColor;
    margin-top: 20px;
  }
  &__submit-text {
    font-family: $RobotoCondensed;
    margin-bottom: 10px;
    color: $errorColor;
  }
  &__submit {
    text-align: center;
  }
  &__block {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &-placeholder {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $lightColor;
      color: $mediumGreyColor;
    }
    &.type-formula,
    &.type-text {
      position: relative;
      user-select: none;
      overflow: hidden;
      h1 {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
      }
      h2 {
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 18px;
        margin-bottom: 20px;
      }
      h4 {
        text-transform: uppercase;
        font-weight: normal;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 20px;
        br {
          display: none;
        }
      }
      pre {
        margin-bottom: 20px;
      }
      sub {
        font-size: 10px;
        vertical-align: baseline;
      }
      sup {
        font-size: 10px;
        vertical-align: top;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.type-formula {
      position: relative;
      user-select: none;
      overflow: hidden;
      .tox-editor-header {
        display: none !important;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
      script {
        display: block;
        height: 0;
        overflow: hidden;
        margin-bottom: 20px;
      }
      .MathJax {
        margin-left: 10px;
        font-size: 20px;
        .math > * > * {
          position: static !important;
        }
        .mspace {
          display: block !important;
          height: 20px !important;
        }
      }
    }
  }
  &__image {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    &.size-16x9 {
      padding-top: 56.25%;
    }
    &.size-4x3 {
      padding-top: 75%;
    }
    &.size-1x1 {
      padding-top: 100%;
    }
    &.size-3x4 {
      padding-top: 125%;
    }
    &.size-4x6 {
      padding-top: 150%;
    }
    &.size-9x16 {
      padding-top: 177.77%;
    }
  }
  &__image-caption {
    text-align: center;
    font-weight: lighter;
    padding-top: 10px;
    color: $greyColor;
    font-style: italic;
  }
  hr {
    border: none;
    border-top: 1px solid $mediumGreyColor;
    margin: 40px 0;
  }
  &__comment {
    background: $lightColor;
    padding: 20px;
    margin-top: 20px;

    &-heading {
      font-family: $RobotoCondensed;
      margin-bottom: 10px;
      color: $purpleColor;
    }
  }
  &__answers {
    background: $lightColor;
    padding: 20px;
    position: relative;

    &-mark {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 18px;
    }

    &.correct {
      background: $lightGreenColor;

      .article__answerTitle,
      i.fa-solid {
        color: $successColor;
      }
    }

    &.warning {
      background: $warningTextColor;

      .article__answerTitle,
      i.fa-solid {
        color: $warningColor;
      }
    }

    &.incorrect {
      background: $lightRedColor;

      .article__answerTitle,
      i.fa-solid {
        color: $errorColor;
      }
    }

    &.isReadonly {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }
    .form__radio-item {
      justify-content: flex-start;
    }
  }
  &__answers-confirm {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  &__preloader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: $white;
      opacity: .75;
    }
  }
  &__audio {
    p {
      margin-bottom: 10px;
      color: $greyColor;
      text-transform: uppercase;
      font-family: $RobotoCondensed;
      letter-spacing: .5px;
    }
    audio {
      width: 100%;
      vertical-align: top;
    }
  }
  .youtube-holder {
    position: relative;
    padding-top: 56.25%;
    > * {
      position: absolute;
      left: 0;
      top: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
  &__video-holder {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}
