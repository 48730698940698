.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.modal__overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #333;
  opacity: 0.8;
  z-index: -1;
}
.modal__inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: 1;
}
.modal__box-wrapper {
  width: 100%;
  display: flex;
  min-height: min-content;
  justify-content: center;
  overflow: auto;
}
.modal__box-holder {
  width: 100%;
  text-align: center;
}
.modal__box {
  display: inline-block;
  vertical-align: top;
  width: 90%;
  max-width: 450px;
  position: relative;
  overflow: hidden;
  margin: 40px 20px;
  text-align: left;
  border-bottom: 20px solid transparent;
}
.modal__box-inner {
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
}
.modal__close {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px;
  cursor: pointer;
  font-size: 20px;
  transform: rotate(0);
  transition: all 0.25s ease-in-out;
  z-index: 5;
}
.modal__close:hover {
  color: #ea5330;
  transform: rotate(90deg);
}
.modal__heading {
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  padding: 0 40px;
  line-height: 1.5;
}
.modal__heading span {
  color: #999;
  font-weight: 300;
}
.modal__backToTop {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 1;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
  transition: color 0.15s ease-in-out;
}
.modal__backToTop:hover {
  color: #ea5330;
}