.datepicker {
  display: flex;
  justify-content: center;
  align-items: center;
}
.datepicker .form__row {
  max-width: 250px;
  margin-top: 0;
}
.datepicker .form__row > .form__field-holder {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
.datepicker .form__row > .form__field-holder:first-child {
  width: 30%;
}
.datepicker .form__row > .form__field-holder:first-child:after {
  content: "/";
  margin-left: -7px;
}
.datepicker .form__row > .form__field-holder:first-child .form__field {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: none;
}
.datepicker .form__row > .form__field-holder:nth-child(2) {
  width: 30%;
}
.datepicker .form__row > .form__field-holder:nth-child(2):after {
  content: "/";
  margin-left: -7px;
}
.datepicker .form__row > .form__field-holder:nth-child(2) .form__field {
  border-radius: 0;
  border-left: none;
  border-right: none;
}
.datepicker .form__row > .form__field-holder:nth-child(3) {
  width: 40%;
}
.datepicker .form__row > .form__field-holder:nth-child(3) .form__field {
  border-left: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.datepicker .form__row > .form__field-holder:nth-child(4) {
  width: 100px;
  margin-left: 20px;
}