.linePreloader {
  height: 100%;
  background: $lightGreyColor;
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    animation: line-loader 1s linear infinite;
    position: absolute;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, $lightGreyColor 0%,$mediumGreyColor 40%,$mediumGreyColor 60%,$lightGreyColor 100%);
    opacity: .25;
    width: 100%;
  }
}
@keyframes line-loader {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}