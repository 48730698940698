@import '../../assets/scss/utils/colors';
@import '../../assets/scss/utils/sizes';
@import '../../assets/scss/utils/fonts';

$navIndent: 20px;

.main--nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: $navWidth;
  background: $white;
  font-family: $RobotoCondensed;
  text-transform: uppercase;
  letter-spacing: .5px;
  &_list {
    padding: $navIndent;
  }
  &_logo-item {
    margin-top: -$navIndent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $headerHeight;
  }
  &_logo {
    max-width: 100%;
  }
  &__collapse {
    border-top: 1px solid $lightGreyColor;
    margin: 0 #{-$navIndent};
    padding: $navIndent;
    &-inner {
      cursor: pointer;
      color: $baseColor;
      transition: color .15s ease-in-out;
      &:hover {
        color: $linkHoverColor;
      }
    }
  }
  &_item {
    margin: $navIndent 0;
  }
  &_link {
    color: $linkColor;
    &.active {
      color: $linkHoverColor;
    }
  }
  &_icon {
    margin-right: 10px;
    width: 20px;
    text-align: center;
  }
  + .pageNotFound {
    position: static;
    opacity: 1;
    visibility: visible;
  }
}
